import { IPrintLabelResponse, IReportService } from 'typings/report';
import { IPlatformService } from 'typings/platform';
import { IStoreService } from 'typings/store';
import { ICourierService } from 'typings/courier';
import { IHelperService } from 'typings/helper';
import { IStatusMessageService } from 'typings/status';
import { IBatchService } from 'typings/batch';
import { ICountryService } from 'typings/auth/services/country';
import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';
import { ApiConfig } from '@client/core/config/api.constant';

import {
  IShipmentListPayload,
  IShipmentListManageTotalData,
  IShipmentListScope,
  IShipmentListActionPayload,
  IShipmentListActionSelectAllPayload,
  IShipmentValidKey,
  IShipmentSortByOptions,
  IShipmentResource,
  IShipmentListItem,
  CreateShippingDocumentsResponse,
} from 'typings/shipment';
import { IReturnModal, IReturnService } from 'typings/return';
import { IPickupRescheduleResponse, IPickupService } from 'typings/pickup';

import { isEmpty } from 'lodash';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { toastError, toastMessage, toastSuccess } from '@client/core/components/react/Toastify';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { PrintPreviewModalProps } from '@client/src/manage-shipments/PrintPreviewModal/PrintPreviewModal';
import { hasUserDirectPrintEnabled } from '@/utils/general';
import { directPrintCommercialInvoice, directPrintShippingDoc } from '@/utils/printing_actions';
import { IShipmentListPayloadFilter } from './shipment-list-normalize.service';
import { ShipmentListAbstractService } from './shipment-list-abstract.service';
import { ShipmentViewsService } from '../shipment-views/shipment-views.service';
import { ShipmentListResource } from './shipment-list.resource';
import { ModalService } from '../../modals/modals.service';
import { AdvancedSearchComponentApiNormalizeService } from '../advanced-search-component-api-normalize/advanced-search-component-api-normalize.service';
import { PrintingOptionsService } from '../printing-options/printing-options.service';

export class ShipmentListManageService extends ShipmentListAbstractService {
  private _totals: IShipmentListManageTotalData | null = null;
  private readonly manageSortingOptions: IShipmentSortByOptions[] = [
    ...this.commonSortingOptions,
    { key: 'label_paid_at' },
    { key: 'easyship_shipment_id' },
    { key: 'tracking_number' },
    // Only EFulfilment
    // { key: 'warehouse_state' },
  ];

  showFilter = false;

  static $inject = [
    '$translate',
    '$q',
    'ShipmentListResource',
    'Shipment',
    'UserSession',
    'CountryService',
    'BatchService',
    'StatusMessageService',
    'CourierService',
    'HelperService',
    'StoreService',
    'PlatformService',
    'PickupService',
    'ShipmentViewsService',
    'AdvancedSearchComponentApiNormalizeService',
    '$filter',
    '$state',
    'ReturnService',
    'ReportService',
    'CheckoutService',
    'PrintLabel',
    'ReschedulePickup',
    'MixpanelService',
    'AppCuesService',
    'PrintingOptionsService',
    'ModalService',
    'CompanyService',
    'API',
    '$timeout',
    'ReturnModal',
    'FixMonkeyService',
    'fixShipmentModal',
  ];
  constructor(
    $translate: ng.translate.ITranslateService,
    $q: ng.IQService,
    ShipmentListResource: ShipmentListResource,
    Shipment: IShipmentResource,
    UserSession: IUserSession,
    CountryService: ICountryService,
    BatchService: IBatchService,
    StatusMessageService: IStatusMessageService,
    CourierService: ICourierService,
    HelperService: IHelperService,
    StoreService: IStoreService,
    PlatformService: IPlatformService,
    PickupService: IPickupService,
    ShipmentViewsService: ShipmentViewsService,
    AdvancedSearchComponentApiNormalizeService: AdvancedSearchComponentApiNormalizeService,
    private $filter: ng.IFilterService,
    private $state: ng.ui.IStateService,
    private ReturnService: IReturnService,
    private ReportService: IReportService,
    private CheckoutService: any,
    private PrintLabel: any,
    private ReschedulePickup: any,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService,
    private PrintingOptionsService: PrintingOptionsService,
    private ModalService: ModalService,
    private CompanyService: ICompanyService,
    private API: ApiConfig,
    private $timeout: ng.ITimeoutService,
    private ReturnModal: IReturnModal,
    private FixMonkeyService: any,
    private fixShipmentModal: any
  ) {
    super(
      ShipmentListResource,
      Shipment,
      UserSession,
      BatchService,
      StatusMessageService,
      HelperService,
      CourierService,
      $q,
      $translate,
      StoreService,
      PlatformService,
      PickupService,
      ShipmentViewsService,
      CountryService,
      AdvancedSearchComponentApiNormalizeService
    );
  }

  get isReturnPage() {
    return this.$state.current.name === 'app.returns';
  }

  get totals(): IShipmentListManageTotalData | null {
    return this._totals;
  }

  cancelShipment(shipment: IShipmentListItem, requestSectionName: IShipmentListScope): void {
    this.ModalService.open('bulk-cancel', {
      totals: this._getTotals(shipment),
      shipmentId: shipment.id,
      easyshipShipmentId: shipment.easyship_shipment_id,
      refreshShipments: () => {
        this.CompanyService.updateStatus();
        this.fetchShipmentEntitiesAndTotalWithFilter(requestSectionName);
        if (this.$state.current.name) {
          this.$state.go(this.$state.current.name, { shipment_id: null });
        }
      },
    });
  }

  _getTotals(shipment: IShipmentListItem) {
    const hasPreparationLabelState = ['generated', 'printed'].includes(shipment.label_state);
    const isEfulfilmentShipmentWithImmediateCancellation =
      this.UserSession.isCompanyEfulfilment() && !hasPreparationLabelState;
    const isEfulfilmentShipmentWithCancellationRequest =
      this.UserSession.isCompanyEfulfilment() && hasPreparationLabelState;
    const cloudShipmentWithImmediateCancellation =
      !this.UserSession.isCompanyEfulfilment() &&
      !hasPreparationLabelState &&
      shipment.payment_recipient === 'Easyship';

    if (cloudShipmentWithImmediateCancellation || isEfulfilmentShipmentWithImmediateCancellation) {
      return {
        immediate_refund_shipments_count: 1,
      };
    }
    if (isEfulfilmentShipmentWithCancellationRequest) {
      return {
        cancellation_request_shipments_count: 1,
      };
    }
    if (!shipment.courier?.is_refundable) {
      return {
        non_refundable_shipments_count: 1,
      };
    }
    return {
      pending_refund_shipments_count: 1,
    };
  }

  downloadCommercialInvoice(shipment: IShipmentListItem, context: MixpanelEventContext): void {
    const downloadByBrowser = () => {
      const url = new URL(`${this.API.publicEndpoint}/get_label/${shipment.id}`);
      url.searchParams.append('document_type', 'commercial_invoice');
      url.searchParams.append('context', context);
      url.searchParams.append('user_id', this.UserSession.user.id);
      url.searchParams.append('easyship_company_id', this.UserSession.company.id);

      if (this.UserSession.company.commercial_invoice_format === '4x6') {
        url.searchParams.append('page_size', this.UserSession.company.commercial_invoice_format);
      }

      $(`<a href="${url}" target="_blank" download></a>`)[0].click();
    };

    if (hasUserDirectPrintEnabled())
      directPrintCommercialInvoice(shipment.id, context, downloadByBrowser.bind(this)).then();
    else downloadByBrowser();
  }

  async duplicateShipment(shipmentId: string): Promise<void> {
    const shipment = await this.getShipmentDetails(shipmentId);

    this.$state.go('manual-input', {
      baseShipment: shipment,
      mixpanelEvent: {
        context: MixpanelEventContext.DUPLICATED_SHIPMENT,
      },
    });
  }

  async openReturnModal(shipmentId: string, callback?: () => void): Promise<void> {
    const shipment = await this.getShipmentDetails(shipmentId);

    await this.ReturnService.initReturnFlow(shipment)
      .then(() => {
        callback?.();
        this.MixpanelService.track('Returns - Select Create Return Label', {
          page: 'modal',
          easyship_shipment_id: shipment.easyship_shipment_id,
        });

        this.ReturnModal.open(shipment);
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  async rescheduleHandover(shipmentId: string): Promise<void> {
    await this.CheckoutService.singleReschedulePickup(shipmentId);
  }

  async editShipment(shipmentId: string, callback?: () => void): Promise<void> {
    // Include http_response to fetch error message from failed shipment
    const shipmentDetails = {
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
      include: 'shipment_items,http_response',
    };

    await this.FixMonkeyService.prepareShipment(shipmentDetails)
      .then(() => {
        callback?.();
        this.FixMonkeyService.goToEditShipmentStep();
        this.fixShipmentModal.open();
      })
      .catch((err: unknown) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw err;
      });
  }

  async markedAsDelivered(
    shipmentId: string,
    requestSectionName: IShipmentListScope,
    callback?: () => void
  ): Promise<void> {
    await this.updateMarkAsDelivered(shipmentId)
      .then(() => {
        toastSuccess(this.$translate.instant('toast.mark-return-success'));
      })
      .catch((error) => {
        const code =
          error.data.error_code === 'mark_as_delivered_unexpected_error'
            ? 'toast.mark-as-delivered-unexpected-error'
            : 'toast.default-error';
        toastError(this.$translate.instant(code));
      })
      .finally(() => {
        callback?.();
        this.fetchShipmentEntitiesAndTotalWithFilter(requestSectionName);
      });
  }

  async startInsuranceClaimFlow(shipmentId: string): Promise<void> {
    const shipment = await this.getShipmentDetails(shipmentId);

    this.ModalService.open('insurance-claim', shipment);
    this.MixpanelService.track('Shipment Details - Insurance - Show Insurance Claim Flow');
  }

  async getShipmentDetails(shipmentId: string): Promise<IShipmentListItem> {
    const { shipment } = await this.Shipment.query({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
      include:
        'shipment_items,list_of_couriers,status_records,checkpoints,returns,transaction_records,batches,chargeable_weight',
    }).$promise;

    return shipment;
  }

  async updateShipmentDetails(shipmentId: string) {
    const shipment = await this.getShipmentDetails(shipmentId);
    this.updateShipment(shipment);
  }

  get selectedShipmentCount(): number {
    const { totals, excludedIds, selectedCount, isSelectedAll } = this;

    if (isSelectedAll && totals) {
      return totals.in_scope_count - excludedIds.length;
    }

    return selectedCount;
  }

  get shipmentInclude(): string {
    const include = 'shipment_items,shipments_totals,checkpoints,store';

    if (this.isReturnPage) {
      return `${include},outbound`;
    }

    return include;
  }

  fetchShipmentTotalWithFilter(scope: IShipmentListScope): void {
    if (!isEmpty(this.filterPayload)) {
      return this.fetchShipmentTotal(scope, this.filterPayload);
    }
    return this.fetchShipmentTotal(scope);
  }

  fetchShipmentTotal(scope: IShipmentListScope, filterData?: IShipmentListPayloadFilter): void {
    let payload: IShipmentListPayload = {
      offset: this.offset,
      limit: this.limit,
      scope,
      by_search: true,
      include: this.shipmentInclude,
    };

    // This is a short-term solution
    if (filterData?.store_state?.values?.includes('fulfilled')) {
      filterData.store_state.values.push('already_fulfilled', 'fulfillable_quantity_mismatch');
    }

    if (filterData) {
      payload = {
        ...payload,
        ...filterData,
      };
    }

    this.totalBusy = true;
    this.ShipmentListResource.getShipmentTotal(
      {
        company_id: this.UserSession.getCompanyId() || '',
      },
      payload
    )
      .then((data) => {
        this._totals = data.totals as IShipmentListManageTotalData;
      })
      .finally(() => {
        this.totalBusy = false;
      });
  }

  updateMarkAsDelivered(shipmentId: string): ng.IPromise<void> {
    const params = {
      id: shipmentId,
      company_id: this.UserSession.getCompanyId(),
      company_type: this.UserSession.getCompanyType(),
    };

    return this.ShipmentListResource.markAsDelivered(params);
  }

  fetchShipmentEntitiesAndTotalWithFilter(scope: IShipmentListScope): void {
    this.fetchShipmentTotalWithFilter(scope);
    this.fetchShipmentEntitiesWithFilter(scope, this.shipmentInclude);
  }

  getShipmentById(shipmentId: string) {
    return this.currentShipments?.find((shipment) => shipment.id === shipmentId);
  }

  async fetchShipmentByIds(shipmentIds: string[]) {
    return this.ShipmentListResource.getShipmentList(
      {
        company_id: this.UserSession.getCompanyId(),
      },
      {
        limit: shipmentIds.length,
        offset: 0,
        scope: 'shipments_all',
        by_search: true,
        include: 'shipment_items,shipments_totals,checkpoints,store',
        id: {
          type: 'multi_select',
          operator: 'is_any_of',
          values: shipmentIds,
        },
      }
    )
      .then((data) => data.shipments)
      .catch((reason) => console.error(reason));
  }

  printCourierLabels(scope: IShipmentListScope): void {
    const preferredTimeslots = <boolean[]>[];
    this.validToPrintSelectedIds.forEach((selectedId) => {
      const shipment = this.getShipmentById(selectedId);
      preferredTimeslots.push(shipment?.preferred_timeslot as boolean);
    });

    if (!this.isSelectedAll) {
      this.AppCuesService.track('Manage | Printed Shipping Documents');

      if (this.validToPrintSelectedIds.length === 0) {
        toastError(this.$translate.instant('shipments.printing-options.select-one'));
        return;
      }
    }

    this.getLabels(scope);
  }

  getLabel(shipmentId: string, context: MixpanelEventContext, callback?: () => void): void {
    const isEfulfillment = this.UserSession.isCompanyEfulfilment();

    // NOTE: eFulfillment users don't support auto generated shipping docs
    if (isEfulfillment) {
      const payload = {
        shipment_ids: [shipmentId],
        job_description: 'All documents',
        context,
      };

      this.printLabel(payload, callback);
    } else {
      const shipment = this.getShipmentById(shipmentId);
      if (!shipment) {
        console.error(`Could not find the shipment by shipmentId(${shipmentId})`);
        return;
      }

      const fnBrowserPrint = () => {
        this.openPrintPreviewModal({
          shipmentId,
          context,
        });
      };

      if (hasUserDirectPrintEnabled()) {
        directPrintShippingDoc(shipmentId, context, fnBrowserPrint.bind(this)).then();
      } else fnBrowserPrint();
    }
  }

  getLabels(scope: IShipmentListScope): void {
    const payload = this.getShipmentPayloadWithValidSelected(scope, 'is_valid_to_print');

    this.printLabel(payload);
  }

  prepareReschedulePickups(scope: IShipmentListScope): ng.IPromise<IPickupRescheduleResponse> {
    return this.ReschedulePickup.prepare(
      {
        company_id: this.UserSession.getCompanyId(),
      },
      this.getShipmentPayloadWithValidSelected(scope, 'is_valid_to_reschedule_pickup')
    ).$promise;
  }

  modifyShipmentTagList(shipmentIndex: number, orderTagList: string[]): void {
    if (this.currentShipments) {
      this.currentShipments[shipmentIndex].order_tag_list = orderTagList;
    }
  }

  getSortingOption(isEfulfillment: boolean): IShipmentSortByOptions[] {
    let options = this.manageSortingOptions;

    if (this.isReturnPage) {
      options = options.filter((option) => {
        return option.key !== 'destination_name';
      });
    }

    if (isEfulfillment) {
      options = [...options, { key: 'warehouse_state' }];
    }

    return options;
  }

  async updateViewAndShipments(
    viewId: string,
    scope: IShipmentListScope,
    stateParams?: ng.ui.IStateParamsService
  ): Promise<void> {
    if (viewId.includes('default_')) {
      this.applyDefaultView(viewId);
    } else {
      this.applyCustomView(viewId);
    }

    // Apply filter from url
    if (stateParams) {
      await this.applyFilterFromUrl(stateParams);
    }
    this.fetchShipmentEntitiesAndTotalWithFilter(scope);
  }

  setLimitFromUserSession(page: 'shipments' | 'returns' = 'shipments'): void {
    this.limit = this.UserSession.getItemsPerPageValue(page);
  }

  private printLabel(payload: any, notificationButtonCallback?: () => void) {
    this.ReportService.busy = true;

    this.PrintLabel.create(
      {
        company_id: this.UserSession.getCompanyId(),
      },
      payload
    )
      .$promise.then((response: IPrintLabelResponse) => {
        if (response.total === 0) {
          toastError(this.$translate.instant('shipments.printing-options.select-one'));
          return;
        }

        this.PrintingOptionsService.reports.push({
          id: response.report_id,
          state: 'pending',
          total: response.total,
          success: null,
          failure: null,
          message: {
            warnings: [],
          },
          zip_url: null,
          preview_documents: null,
          job_description: payload.job_description,
          printed_at: null,
          generated_at: null,
        });

        this.AppCuesService.track('Manage | Generated Shipping Documents', null, true);

        toastMessage(
          this.$translate.instant(
            'shipments.printing-options.generate-success',
            { count: response.total.toString() },
            'messageformat'
          )
        );

        this.showFilter = false;
        this.PrintingOptionsService.showDocumentQueue = true;
        notificationButtonCallback && notificationButtonCallback();
      })
      .catch(() => {
        toastError(this.$translate.instant('shipments.printing-options.generate-error'));
      })
      .finally(() => {
        this.ReportService.busy = false;
      });
  }

  openPrintPreviewModal(props: Omit<PrintPreviewModalProps, 'open'>) {
    this.PrintingOptionsService.printPreviewModalProps = {
      ...props,
      open: true,
      onClose: () => {
        this.closePrintPreviewModal();
        props.onClose?.();
      },
    };
  }

  closePrintPreviewModal() {
    this.PrintingOptionsService.printPreviewModalProps = null;
  }

  previewPrintDoc(shipmentId: string, context: string) {
    this.PrintingOptionsService.showPrintPreview = true;

    const shipment = this.getShipmentById(shipmentId);
    if (!shipment) {
      console.error(`Could not find the shipment by shipmentId(${shipmentId})`);
      return;
    }

    this.createShippingDocuments(shipment.id, context).then(
      ({ shipping_documents: shippingDocuments }) => {
        this.PrintingOptionsService.previewDocuments = shippingDocuments;
      }
    );
  }

  private createShippingDocuments(
    shipmentId: string,
    context: string
  ): ng.IPromise<CreateShippingDocumentsResponse> {
    return this.Shipment.createShippingDocuments(
      {
        company_id: this.UserSession.company.id,
        company_type: this.UserSession.company.type,
        id: shipmentId,
        context,
      },
      null
    ).$promise;
  }

  private getShipmentPayloadWithValidSelected(
    scope: IShipmentListScope,
    validKey: IShipmentValidKey
  ): IShipmentListActionPayload | IShipmentListActionSelectAllPayload {
    let validIds: string[] = [];

    switch (validKey) {
      case 'is_valid_to_print':
        validIds = this.validToPrintSelectedIds;
        break;
      case 'is_valid_to_reschedule_pickup':
        validIds = this.validToRescheduleSelectedIds;
        break;
      default:
        break;
    }

    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload = this
      .isSelectedAll
      ? {
          by_search: true,
          limit: this.totals ? this.totals.in_scope_count : 0,
          offset: 0,
          include: this.shipmentInclude,
          scope,
          exclude_shipment_ids: this.excludedIds,
        }
      : {
          by_search: true,
          shipment_ids: validIds,
        };

    if (this.isSelectedAll) {
      if (this.keyword && this.keyword !== '') {
        (payload as IShipmentListActionSelectAllPayload).keyword = this.keyword;
      }

      if (this.filter) {
        payload = {
          ...payload,
          ...this.normalizeFilterData(this.filter),
        };
      }
    }

    if (validKey === 'is_valid_to_print') {
      (payload as IShipmentListActionSelectAllPayload).job_description = 'All documents';
    }

    return payload;
  }

  async retryFulfillment(shipmentId: string): Promise<unknown> {
    return this.Shipment.retryFulfillment(
      {
        company_type: this.UserSession.company.type,
        company_id: this.UserSession.company.id,
        id: shipmentId,
      },
      null
    ).$promise;
  }
}
