export type ApiResponseError = Error & {
  response?: {
    data?: {
      errors?: string[];
    };
  };
};

export type ApiResponseErrorWithCodeAndMessage = Error & {
  response: {
    data: {
      error: {
        code: string;
        message: string;
      };
    };
  };
};

export enum ApiContext {
  pickAndPack = 'Pick and Pack',
}

export enum ErrorCode {
  CANCELED = 'ERR_CANCELED',
}
