import React from 'react';
import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';

import { FormProvider, useForm } from 'react-hook-form';
import { react2angular } from 'react2angular';
import { ConnectCourierComponent } from '@app/pages/couriers/components/ConnectCourierComponent';
import { GeolocationGatewayProvider } from '@client/src/global/context/GeolocationProvider';
import { ConnectCourierProps } from '@app/pages/couriers/components/ConnectCourierComponent/types';

function WrappedConnectCourierComponent(props: ConnectCourierProps) {
  const methods = useForm({ mode: 'all' });

  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <GeolocationGatewayProvider>
          <FormProvider {...methods}>
            <ConnectCourierComponent {...props} />
          </FormProvider>
        </GeolocationGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularConnectCourierComponent = react2angular(WrappedConnectCourierComponent, [
  'courier',
  'country',
  'onBack',
  'onSubmit',
  'onDeleteSuccess',
]);
