import { invalidateUserStatusCreditBalanceQuery } from '@client/core/adapters/helpers/invalidateQueries';

class UserStatusService {
  constructor() {
    this.actualBalance = null;
    this.availableBalance = null;
    this.shipmentCounters = null;
    this.showForceReload = false;
  }

  updateActualBalance(balance) {
    this.actualBalance = balance;
  }

  updateAvailableBalance(balance) {
    this.availableBalance = balance;
    invalidateUserStatusCreditBalanceQuery()
      .then()
      .catch(() => undefined);
  }

  updateShipmentCounters(counters) {
    this.shipmentCounters = counters;
  }
}

export { UserStatusService };
