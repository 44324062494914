import { IAuthService } from 'typings/core/services/auth';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './log-in-as.html?raw';
import style from './log-in-as.module.scss';

class LogInAsController implements IComponentController {
  style = style;

  static $inject = ['$window', '$stateParams', '$state', 'Auth', '$location'];
  constructor(
    private $window: ng.IWindowService,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private Auth: IAuthService,
    public $location: ng.ILocationService
  ) {}

  redirectToAuthLogin(): void {
    const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === 'production';
    if (import.meta.env.VITE_APP_ENVIRONMENT === 'development') {
      // Temporary Fix, TODO: auth.staging redirect login from localhost

      // window.location = `https://auth.staging.easyship.com/auth/login${
      //   accountClosure ? '?accountClosed=true' : ''
      // }`;
      this.$state.go('home.auth.login');
    } else {
      // redirect to qa servers or production of auth
      this.$window.location = `https://auth${
        !isProduction ? `.${import.meta.env.VITE_APP_ENVIRONMENT}` : ''
      }.easyship.com/login`;
    }
  }

  $onInit(): void {
    if (!this.$stateParams.jwt) this.$state.go('home.auth.login');
    // To be removed after the new enterprise login (redirect-login) is fully implemented
    if (this.$state.is('enterprise-login-depreciated') || this.$state.is('enterprise-login')) {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.enterpriseLogIn(jwt)
        .then((url) => {
          this.$location.search({}); // clear JWT search param in the url

          const { href } = new URL(url);

          this.$window.location = href;
        })
        .catch((error) => {
          toastError(error.message);
          // this.$state.go('home.auth.login');
          this.redirectToAuthLogin();
        });
    } else if (this.$state.is('redirect-login')) {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.redirectLogIn(jwt)
        .then((url) => {
          this.$location.search({}); // clear JWT search param in the url

          const { pathname, search } = new URL(url);
          const nextPath = pathname + search;

          this.$location.path(nextPath);
        })
        .catch((error) => {
          toastError(error.message);
          // this.$state.go('home.auth.login');
          this.redirectToAuthLogin();
        });
    } else {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.logInAs(jwt)
        .then(() => {
          this.$state.go('app.shipments');
        })
        .catch((error) => {
          toastError(error.message);
          // this.$state.go('home.auth.login');
          this.redirectToAuthLogin();
        });
    }
  }
}

const LogInAsComponent = {
  controller: LogInAsController,
  template,
};

export { LogInAsComponent };
