import React, { lazy } from 'react';
import { IconButton } from 'easyship-components';
import { Columns as ColumnsIcon } from 'easyship-components/icons';
import { useIntl } from 'react-intl';
import { useOpenModal } from '@/hooks/general/useOpenModal';

const ColumnSettingsModal = lazy(() => import('./ColumnSettingsModal'));

export function ShipmentsTableColumnSettings() {
  const { formatMessage } = useIntl();
  const translate = (id: string) => formatMessage({ id });
  const openModalHandler = useOpenModal();

  const toggleColumnSettingsModal = () => {
    openModalHandler(ColumnSettingsModal);
  };

  const title = translate('shipments.table.column-settings.title');

  return (
    <IconButton
      aria-label={title}
      color="default"
      className="w-[34px] h-[34px]"
      tooltipProps={{
        label: title,
      }}
      onClick={toggleColumnSettingsModal}
    >
      <ColumnsIcon />
    </IconButton>
  );
}
