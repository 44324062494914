import { Chip } from 'easyship-components';
import React from 'react';
import { react2angular } from 'react2angular';
import { API } from '@client/core/config/api.constant';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import ReactRootProviders from '../../context/ReactRootProviders';
import { UserSessionProvider, useUserSession } from '../../context/UserSessionProvider';
import './CourierLogoAndName.scss';

interface CourierLogoAndNameComponentProps {
  esLogoSize: 'medium' | 'large';
  esLogoUrl: string;
  esName: string;
  esCostRank?: number;
  esDeliveryTimeRank?: number;
  esValueRank?: number;
  esNameSize?: 'small' | 'smaller';
  esEllipsis?: boolean;
}

export function CourierLogoAndNameComponent({
  esLogoSize,
  esLogoUrl,
  esName,
  esCostRank,
  esDeliveryTimeRank,
  esValueRank,
  esNameSize,
  esEllipsis,
}: CourierLogoAndNameComponentProps) {
  const { formatMessage } = useIntl();
  const { user } = useUserSession();

  const isUPS = esName && esName.toLowerCase().includes('ups');
  const logoSrc = `${API.easyship_storage}//courier-logos/${esLogoUrl || 'nonbranded'}-mini.svg`;
  const fallbackSrc = `${API.easyship_storage}//courier-logos/nonbranded-mini.svg`;
  const termsSupportLink = `${API.homepage}/legal/insurance-terms-and-conditions`;

  const logoClasses = twMerge(
    'logo',
    esLogoSize === 'medium' && 'medium-logo',
    esLogoSize === 'large' && 'large-logo',
    esLogoUrl === 'insurance' && 'insurance-logo',
    isUPS && 'ups-logo'
  );
  const nameClasses = twMerge(
    'line-clamp-2 text-ink-900 name',
    esEllipsis ? 'truncate max-w-[350px]' : '',
    esNameSize === 'small' ? 'small-name' : '',
    esNameSize === 'smaller' ? 'smaller-name' : ''
  );
  const mbClass = twMerge(
    (esCostRank === 1 || esDeliveryTimeRank === 1 || esValueRank === 1) && '!mb-[5px]'
  );

  const handleLogoError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.currentTarget;
    target.src = fallbackSrc;
  };

  return (
    <div className="flex items-center max-h-full courier-logo-and-name-wrapper">
      <img
        className={logoClasses}
        alt={`${esLogoUrl} logo`}
        src={logoSrc}
        onError={handleLogoError}
      />
      <div>
        <div className={`flex items-center ${mbClass}`}>
          <p className={nameClasses}>
            <span>{esName}</span>
            {esLogoUrl === 'insurance' && (
              <a href={termsSupportLink} target="_blank" rel="noreferrer" className="!ml-[5px]">
                {formatMessage({ id: 'global.see-terms-and-conditions' })}
              </a>
            )}
          </p>
        </div>
        {!user.isLuxUser() && (
          <div className="chip-wrapper">
            {esCostRank === 1 && (
              <Chip color="teal">{formatMessage({ id: 'quote.ranked.cheapest' })}</Chip>
            )}
            {esDeliveryTimeRank === 1 && (
              <Chip color="teal">{formatMessage({ id: 'quote.ranked.fastest' })}</Chip>
            )}
            {esValueRank === 1 && (
              <Chip color="teal">{formatMessage({ id: 'quote.ranked.best-value' })}</Chip>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function WrappedCourierLogoAndNameComponent(props: CourierLogoAndNameComponentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <CourierLogoAndNameComponent {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularCourierLogoAndNameComponent = react2angular(
  WrappedCourierLogoAndNameComponent,
  [
    'esLogoSize',
    'esLogoUrl',
    'esName',
    'esCostRank',
    'esDeliveryTimeRank',
    'esValueRank',
    'esNameSize',
    'esEllipsis',
  ]
);
