import { ResendPinPayload } from '@app/pages/couriers/components/ConnectCourierComponent/fedex/forms/types';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useGeneratePinCodeMutation() {
  return useMutation({
    mutationFn: ({ companyId, id, account }: ResendPinPayload): Promise<void> =>
      axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT
        }/companies/${companyId}/courier_accounts/${id}/generate_pin_code`,
        { account }
      ),
  });
}
