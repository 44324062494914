import angular from 'angular';

import { PaymentBalanceComponent } from './payment-balance/payment-balance.component';
import { AddPaymentCardComponent } from './add-payment-card/add-payment-card.component';
import { PaymentListCardComponent } from './payment-list-card/payment-list-card.component';
import { CreditCardElementComponent } from './credit-card-element/credit-card-element.component';
import { AddPaymentFlowComponent } from './add-payment-flow/add-payment-flow.component';
import { AddPaymentModalCardComponent } from './add-payment-modal-card/add-payment-modal-card.component';
import { DeletePaymentFlowComponent } from './delete-payment-flow/delete-payment-flow.component';
import { ManagePaymentMethodModalComponent } from './manage-payment-method-modal/manage-payment-method-modal.component';
import { ConfirmDeletePaymentModalCardComponent } from './confirm-delete-payment-modal-card/confirm-delete-payment-modal-card.component';
import { AddCreditsFlowComponent } from './add-credits-flow/add-credits-flow.component';
import { ChoosePaymentMethodModalCardComponent } from './choose-payment-method-modal-card/choose-payment-method-modal-card.component';
import { PaymentMethodListCardComponent } from './payment-method-list-card/payment-method-list-card.component';
import { AddCreditsByCardModalCardComponent } from './add-credits-by-card-modal-card/add-credits-by-card-modal-card.component';
import { AddCreditsPaypalModalCardComponent } from './add-credits-paypal-modal-card/add-credits-paypal-modal-card.component';
import { AddCreditsBankTransferModalCardComponent } from './add-credits-bank-transfer-modal-card/add-credits-bank-transfer-modal-card.component';
import { RefundCreditsFlowComponent } from './refund-credits-flow/refund-credits-flow.component';
import { ConfirmRefundModalCardComponent } from './confirm-refund-modal-card/confirm-refund-modal-card.component';
import { RefundStatusModalCardComponent } from './refund-status-modal-card/refund-status-modal-card.component';
import { SupportTeamContactModalCardComponent } from './support-team-contact-modal-card/support-team-contact-modal-card.component';
import { AutoRechargeModalCardComponent } from './auto-recharge-modal-card/auto-recharge-modal-card.component';
import { AngularPromoCodeSection } from '../../../../core/components/wrappers/payment-method/AngularPromoCodeSection';

const MODULE_NAME = 'app.global.components.payment';

angular
  .module(MODULE_NAME, [])
  .component('esPaymentBalance', PaymentBalanceComponent)
  .component('esAddPaymentCard', AddPaymentCardComponent)
  .component('esPaymentListCard', PaymentListCardComponent)
  .component('esCreditCardElement', CreditCardElementComponent)
  .component('esAddPaymentFlow', AddPaymentFlowComponent)
  .component('esAddPaymentModalCard', AddPaymentModalCardComponent)
  .component('esManagePaymentMethodModal', ManagePaymentMethodModalComponent)
  .component('esDeletePaymentFlow', DeletePaymentFlowComponent)
  .component('esConfirmDeletePaymentModalCard', ConfirmDeletePaymentModalCardComponent)
  .component('esAddCreditsFlow', AddCreditsFlowComponent)
  .component('esChoosePaymentMethodModalCard', ChoosePaymentMethodModalCardComponent)
  .component('esPaymentMethodListCard', PaymentMethodListCardComponent)
  .component('esAddCreditsByCardModalCard', AddCreditsByCardModalCardComponent)
  .component('esAddCreditsPaypalModalCard', AddCreditsPaypalModalCardComponent)
  .component('esAddCreditsBankTransferModalCard', AddCreditsBankTransferModalCardComponent)
  .component('esRefundCreditsFlow', RefundCreditsFlowComponent)
  .component('esConfirmRefundModalCard', ConfirmRefundModalCardComponent)
  .component('esRefundStatusModalCard', RefundStatusModalCardComponent)
  .component('esSupportTeamContactModalCard', SupportTeamContactModalCardComponent)
  .component('esAutoRechargeModalCard', AutoRechargeModalCardComponent)
  .component('esPromoCodeSection', AngularPromoCodeSection);

export default MODULE_NAME;
