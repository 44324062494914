import { useQuery, UseQueryResult } from '@tanstack/react-query';
import angular from 'angular';
import { IUserSession } from '@typings/user-session';
import { snakeToCamelCase } from '@/utils/snakeCamelTransform';
import { GET_USER_SESSION_QUERY_KEY, UserSession } from './types';

export const useGetUserSessionQuery = (): UseQueryResult<UserSession, Error> => {
  return useQuery({
    queryKey: [GET_USER_SESSION_QUERY_KEY],
    queryFn: () => {
      const service: IUserSession = angular.element(document.body).injector().get('UserSession');
      return snakeToCamelCase({
        company: service?.company,
        user: service?.user,
      }) as UserSession;
    },
    cacheTime: Infinity,
  });
};
