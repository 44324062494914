import angular from 'angular';
import ICourierAccountGateway, {
  AddCourierParams,
  AddCourierResponse,
  CourierAccount,
  CourierAccountsByEntity,
} from '@client/core/corelogic/ports/courierAccount.interface';
import {
  ICourierAccountObject,
  ICourierAccountsService,
  IUpdateCourierInfoCanadaPostResponse,
  IUpdateCourierInfoParams,
} from 'typings/courier';
import { CourierAccountState, CourierAuthState, CourierLogo } from '@client/data/courier';

export default class NgCourierAccountGateway implements ICourierAccountGateway {
  private courierAccountsService: ICourierAccountsService;

  constructor() {
    this.courierAccountsService = angular
      .element(document.body)
      .injector()
      .get<ICourierAccountsService>('CourierAccounts');
  }

  getAllActive(): Promise<CourierAccountsByEntity> {
    return new Promise((resolve, reject) => {
      this.courierAccountsService
        .getActiveCourierAccounts()
        .then((response) =>
          resolve({
            companyCourierAccounts: response.company_courier_accounts
              .filter(
                (account) =>
                  account.auth_state === CourierAuthState.Verified &&
                  account.account_state === CourierAccountState.Active
              )
              .map(this.mapCourierAccountFromAPI),
            easyshipCourierAccounts: response.easyship_courier_accounts
              .filter(
                (account) =>
                  account.auth_state === CourierAuthState.Verified &&
                  account.account_state === CourierAccountState.Active
              )
              .map(this.mapCourierAccountFromAPI),
          })
        )
        .catch(reject);
    });
  }

  addCourierAccount({ umbrella_name, account }: AddCourierParams): Promise<AddCourierResponse> {
    return new Promise((resolve, reject) => {
      this.courierAccountsService
        .addCourier({ umbrella_name, account })
        .then(resolve)
        .catch(reject);
    });
  }

  updateCourierInfo({
    id,
    account,
  }: IUpdateCourierInfoParams): Promise<IUpdateCourierInfoCanadaPostResponse> {
    return new Promise((resolve, reject) => {
      this.courierAccountsService.updateCourierInfo({ id, account }).then(resolve).catch(reject);
    });
  }

  deleteCourierAccount(id: string): Promise<void> {
    return this.courierAccountsService.deleteCourier({ id });
  }

  private mapCourierAccountFromAPI(account: ICourierAccountObject): CourierAccount {
    const fedexUmbrellas = [CourierLogo.Fedex, CourierLogo.FedexCrossBorder];
    const upsUmbrellas = [CourierLogo.Ups];

    return {
      id: account.id,
      nickname: account.nickname || '',
      countryId: account.country_id,
      numberOfCourierServices: account.number_of_couriers,
      isFedex: account.logo_url ? fedexUmbrellas.includes(account.logo_url as CourierLogo) : false,
      isUPS: account.logo_url ? upsUmbrellas.includes(account.logo_url as CourierLogo) : false,
    };
  }
}
