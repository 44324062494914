import { ICheckoutService } from '@typings/checkout';
import angular, { IRootScopeService } from 'angular';
import { IUserStatusService } from '@typings/user-status';
import { IChangePlanModalProps } from '@typings/subscription';
import { RouteToDestinations } from '@/types/angular';
import { IStateOptions, IStateService } from 'angular-ui-router';

export const angularCheckoutServiceNext = (data?: { schedule_pickup_later?: boolean }) => {
  const service: ICheckoutService = angular
    .element(document.body)
    .injector()
    .get('CheckoutService');

  return service?.next?.(data);
};

export const angularUserStatusUpdateBalance = ({
  credit_balance: creditBalance,
  available_credit_balance: availableCreditBalance,
}: {
  credit_balance: number;
  available_credit_balance: number;
}) => {
  const service: IUserStatusService = angular
    .element(document.body)
    .injector()
    .get('UserStatusService');
  service?.updateAvailableBalance?.(availableCreditBalance);
  service?.updateActualBalance?.(creditBalance);
};

export const angularRefreshPickups = () => {
  const service: {
    getPickups: () => void;
    updateTotals: () => void;
  } = angular.element(document.body).injector().get('PickupsPageService');
  Promise.all([service?.getPickups(), service?.updateTotals()])
    .then()
    .catch(() => undefined);
};

export const angularRouteTo = (
  destination: RouteToDestinations,
  params?: object,
  options?: IStateOptions
) => {
  const service: {
    routeTo: IStateService['go'];
  } = angular.element(document.body).injector().get('HelperService');
  service?.routeTo(destination, params, options);
};

export const angularShowChangePlanModal = (data: IChangePlanModalProps) => {
  const service: {
    showChangePlanModal: (data: IChangePlanModalProps) => void;
  } = angular.element(document.body).injector().get('ChangePlanService');
  service?.showChangePlanModal(data);
};

export const angularRefreshShipmentAllById = async (shipmentId: string) => {
  const service: {
    updateShipmentDetails: (shipmentId: string) => Promise<void>;
  } = angular.element(document.body).injector().get('ShipmentListManageService');
  return service?.updateShipmentDetails(shipmentId);
};

export const angularGetRoute = () => {
  const service: {
    $rootScope: IRootScopeService;
  } = angular.element(document.body).injector().get('HelperService');
  return service?.$rootScope;
};

export const angularNormalizeFilterData = <T>(filter: T) => {
  const service: {
    normalizeFilterData: (data: T) => unknown;
  } = angular.element(document.body).injector().get('ShipmentListManageService');
  return service?.normalizeFilterData(filter);
};

export const angularRefreshCheckout = async (shipmentId: string) => {
  const service: {
    refreshSingleData: (id: string) => void;
  } = angular.element(document.body).injector().get('CheckoutService');
  return service?.refreshSingleData(shipmentId);
};

export const angularShowEditShipmentModal = async ({
  companyId,
  companyType,
  shipmentId,
}: {
  companyId: string;
  companyType: string;
  shipmentId: string;
}) => {
  const fixShipmentModal: {
    open: () => void;
  } = angular.element(document.body).injector().get('fixShipmentModal');

  const fixMonkeyService: {
    preventRedirectToRejected: boolean;
    prepareShipment: (param: {
      company_id: string;
      company_type: string;
      id: string;
      include: string;
    }) => Promise<unknown>;
    goToEditShipmentStep: () => void;
  } = angular.element(document.body).injector().get('FixMonkeyService');

  await fixMonkeyService.prepareShipment({
    company_id: companyId,
    company_type: companyType,
    id: shipmentId,
    include: 'shipment_items,http_response',
  });

  // This is used to prevent the Edit Shipment flow from redirecting to
  // the Manage Shipments / Rejected page.
  fixMonkeyService.preventRedirectToRejected = true;

  fixMonkeyService.goToEditShipmentStep();
  fixShipmentModal.open();
};
