import { useGetUserSessionQuery } from '@/hooks/queries/useGetUserSessionQuery';
import { UserSession } from '@/hooks/queries/useGetUserSessionQuery/types';
import { useIsFeatureFlag } from '@/hooks/general/useIsFeatureFlag';
import { COMPANY_FEATURE_FLAGS } from '@/constants/company';
import { UserDirectPrinterSettings } from './type';

export * from './type';

export const useUserDirectPrintSettings = () => {
  const { data } = useGetUserSessionQuery();
  const { user } = data || ({} as UserSession);
  return user?.directPrintSettings || ({} as UserDirectPrinterSettings);
};

export const useHasDirectPrintEnabled = () => {
  const printSettings = useUserDirectPrintSettings();
  const hasDirectPrintingEnabled = printSettings.directPrintEnabled;
  const hasFlagEnabled = useIsFeatureFlag(COMPANY_FEATURE_FLAGS.DIRECT_PRINT_NODE_FEATURE);
  return hasFlagEnabled && hasDirectPrintingEnabled;
};
