import React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { FedexStep, FedexStepEnum } from '../types';

interface StepContent {
  header: string;
  description: string;
  descriptionValues?: Record<string, string | number>;
  className?: string;
}

const FEDEX_STEP_CONTENT: Record<string, StepContent> = {
  [FedexStepEnum.Form]: {
    header: 'courier.connect.form.fedex.header',
    description: 'courier.connect.form.fedex.description',
    className: 'border-b border-b-sky-300',
  },
  [FedexStepEnum.Invoice]: {
    header: 'courier.connect.form.fedex.invoice.header',
    description: 'courier.connect.form.fedex.invoice.description',
    descriptionValues: { invoiceDays: '180' },
  },
  [FedexStepEnum.PinChoice]: {
    header: 'courier.connect.form.fedex.pin-choice.header',
    description: 'courier.connect.form.fedex.pin-choice.description',
    className: 'mb-0 !pb-0',
  },
  [FedexStepEnum.PinSMS]: {
    header: 'courier.connect.form.fedex.pin-choice.header',
    description: 'courier.connect.form.fedex.pin-choice.sms-description',
    className: 'border-0 pb-0 mb-2',
  },
  [FedexStepEnum.PinEmail]: {
    header: 'courier.connect.form.fedex.pin-choice.header',
    description: 'courier.connect.form.fedex.pin-choice.email-description',
    className: 'border-0 pb-0 mb-2',
  },
};

export function FedexStepHeaderContent({ step }: { step: FedexStep }) {
  const content = FEDEX_STEP_CONTENT[step];

  if (!content.header && !content.description) return null;

  return (
    <div className={twMerge('py-5 mx-12', content.className)}>
      <span className="text-ink-900 mb-0">
        <FormattedMessage id={content.header} />
      </span>
      <p className="mt-6 mb-0 text-base text-ink-900">
        <FormattedMessage id={content.description} values={content.descriptionValues} />
      </p>
    </div>
  );
}
