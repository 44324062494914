import axios from 'axios';
import { camelToSnakeCase } from '@/utils/snakeCamelTransform';
import { IShipmentListCustomViewsResponse } from '@typings/shipment';
import { normalizeFilterData } from '@/utils/shipment';
import { ShipmentsFilterDataCustomView } from '@/types/shipment';
import {
  PickAndPackProps,
  PickAndPackShipmentApi,
  PurchaseLabelProps,
  PurchaseLabelResponseApi,
  ShipmentItem,
  ShipmentListAdvancedTotalDataApi,
  ShipmentListItemApi,
  ShipmentListScopes,
  UpdateItemPackedCountProps,
  UpdateShipmentProps,
  UpdateShipmentResponseApi,
} from './types';

export const getPickAndPack = async ({
  companyId,
  payload,
  signal,
}: PickAndPackProps): Promise<PickAndPackShipmentApi> =>
  (
    await axios.get(
      `${import.meta.env.VITE_APP_ENDPOINT}/cloud/companies/${companyId}/shipments/pick_and_pack`,
      {
        params: payload,
        signal,
      }
    )
  )?.data?.shipment || {};

export const updateShipment = async ({
  companyId,
  shipmentId,
  option = {} as UpdateShipmentProps['option'],
  shipment,
  signal,
}: UpdateShipmentProps): Promise<UpdateShipmentResponseApi> => {
  const data = camelToSnakeCase({
    option,
    shipment,
  });
  return (
    (
      await axios.patch(
        `${import.meta.env.VITE_APP_ENDPOINT}/cloud/companies/${companyId}/shipments/${shipmentId}`,
        {
          ...data,
        },
        {
          signal,
        }
      )
    )?.data || {}
  );
};

export const updateItemPackedCount = async ({
  companyId,
  shipmentId,
  items,
  signal,
}: UpdateItemPackedCountProps): Promise<void> => {
  return axios.post(
    `${
      import.meta.env.VITE_APP_ENDPOINT
    }/cloud/companies/${companyId}/shipments/${shipmentId}/update_item_packed_count`,
    {
      packed_items: camelToSnakeCase(items.map(({ id, packedCount }) => ({ id, packedCount }))),
    },
    {
      signal,
    }
  );
};

export const purchaseLabel = async ({ companyId, shipmentId }: PurchaseLabelProps) =>
  (
    await axios.post<PurchaseLabelResponseApi>(
      `${import.meta.env.VITE_APP_ENDPOINT}/companies/${companyId}/checkout/print_label`,
      {
        shipment_id: shipmentId,
      }
    )
  )?.data || {};

export const getCustomViews = async ({
  companyId,
}: {
  companyId: string;
}): Promise<IShipmentListCustomViewsResponse['custom_views']> =>
  (
    await axios.get(`${import.meta.env.VITE_APP_ENDPOINT}/companies/${companyId}/custom_views`, {
      params: {
        page_context: 'orders_all',
      },
    })
  )?.data?.custom_views || [];

export const getShipmentsTotals = async ({
  companyId,
  filters: filterProps,
  ...rest
}: {
  companyId: string;
  offset?: number;
  limit?: number;
  filters?: ShipmentsFilterDataCustomView;
}): Promise<ShipmentListAdvancedTotalDataApi> => {
  const filter = normalizeFilterData(filterProps) || {};
  return (
    (
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT.replace(
          'v1',
          'v2'
        )}/companies/${companyId}/shipments/totals`,
        {
          by_search: true,
          include: 'collect_state',
          limit: 10,
          offset: 0,
          scope: 'orders_all',
          ...rest,
          ...filter,
        }
      )
    )?.data?.totals || {}
  );
};

export const getShipments = async ({
  companyId,
  filters: filterProps,
  scope = 'orders_all',
  ...rest
}: {
  companyId: string;
  offset?: number;
  limit?: number;
  scope?: ShipmentListScopes;
  filters?: ShipmentsFilterDataCustomView;
}): Promise<ShipmentListItemApi[]> => {
  const filter = normalizeFilterData(filterProps) || {};
  return (
    (
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT.replace('v1', 'v2')}/companies/${companyId}/shipments`,
        {
          by_search: true,
          limit: 300,
          offset: 0,
          scope,
          ...rest,
          ...filter,
        }
      )
    )?.data?.shipments || []
  );
};

export const getShipment = async ({
  companyId,
  shipmentId,
}: {
  companyId: string;
  shipmentId: string;
}): Promise<ShipmentItem> => {
  return (
    await axios.get(
      `${import.meta.env.VITE_APP_ENDPOINT}/cloud/companies/${companyId}/shipments/${shipmentId}`,
      {
        params: {
          include: 'shipment_items,shipments_totals,checkpoints,store',
        },
      }
    )
  )?.data?.shipment;
};

export const getPackingSlips = async ({
  companyId,
  context,
  shipmentIds,
}: {
  companyId: string;
  context: string;
  shipmentIds: string[];
}): Promise<string | null | undefined> =>
  (
    await axios.post(
      `${
        import.meta.env.VITE_APP_ENDPOINT
      }/cloud/companies/${companyId}/shipments/get_packing_slips`,
      {
        context,
        shipment_ids: shipmentIds,
      }
    )
  )?.data?.file;
