import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { react2angular } from 'react2angular';
import React from 'react';
import { ShipmentsTableColumnSettings } from '@/features/ShipmentsTable/components/ShipmentsTableColumnSettings/ShipmentsTableColumnSettings';

function WrappedShipmentsTableColumnSettings() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentsTableColumnSettings />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularShipmentsTableColumnSettings = react2angular(
  WrappedShipmentsTableColumnSettings
);
