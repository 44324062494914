import { PromoCodeFormData } from '@app/pages/payment-method/components/forms/PromoCodeForm/types';
import { useMutation } from '@tanstack/react-query';
import { ICompanyService } from '@typings/company';
import angular from 'angular';

interface RedeemVoucherMutationProps {
  payload: PromoCodeFormData;
}

export function useRedeemVoucherMutation() {
  return useMutation({
    mutationFn: async ({ payload }: RedeemVoucherMutationProps) => {
      const service: ICompanyService = angular
        .element(document.body)
        .injector()
        .get('CompanyService');
      return service.redeemVoucher(payload.code);
    },
  });
}
