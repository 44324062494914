import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '@/providers/UserSessionProvider';
import { updateShipment } from '@/api/shipments';
import { UpdateShipmentProps } from '@/api/shipments/types';
import { useCallback, useRef } from 'react';

export const useShipmentUpdateMutation = () => {
  const { company } = useUserSession();
  const refAbortSignal = useRef<AbortController | null>(null);
  const abortMutation = useCallback(() => {
    if (refAbortSignal.current) {
      refAbortSignal.current.abort();
      refAbortSignal.current = null;
    }
  }, []);
  const useMutationRes = useMutation({
    mutationFn: async ({
      shipmentId,
      option,
      shipment,
    }: {
      shipmentId: string;
      option?: UpdateShipmentProps['option'];
      shipment: UpdateShipmentProps['shipment'];
    }) => {
      abortMutation();
      refAbortSignal.current = new AbortController();
      const res = await updateShipment({
        companyId: company?.id,
        shipmentId,
        option,
        shipment,
        signal: refAbortSignal.current?.signal,
      });
      refAbortSignal.current = null;
      return res;
    },
  });
  return {
    ...useMutationRes,
    abortMutation,
  };
};
