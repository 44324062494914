export enum ShipmentStatus {
  CANCELED_BY_COMPANY = 2,
  CANCELED_BY_EASY_SHIP = 3,

  DELETED = 99,
  DRAFT = 100,
  CREATED = 101,
  CANCELED_PENDING_REFUNDED = 102,
  CANCELED_REFUNDED = 103,
  LABEL_PENDING_AWAITING_COURIER = 104,

  CANCELED_PENDING_REFUNDED_NO_STOCK = 109,

  LABEL_REJECTED_REQUIRED_EDIT = 120,
  LABEL_REJECTED_REPORTED = 121,
  LABEL_REJECTED_AWAITING_COURIER = 122,

  CANCELED = 160,

  CANCELLATION_REQUESTED = 197,
}
