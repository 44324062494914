export enum MixpanelEventContext {
  BASIC_FLOW = 'Basic Flow',
  ADVANCED_FLOW = 'Advanced Flow',
  DUPLICATED_SHIPMENT = 'Duplicated Shipment',
  MANAGE_SHIPMENTS_MODAL = 'Manage Shipments Modal',
  MANAGE_SHIPMENT = 'Manage Shipment',
  MANAGE_SHIPMENT_ROW = 'Manage Shipment / Row',
  MANAGE_SHIPMENT_STATUS = 'Manage Shipment / Status',
  PICK_AND_PACK = 'Pick and Pack',
  ADVANCED_SHIPMENT = 'Advanced Shipment',
}
