import angular from 'angular';
import React, { useContext, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import {
  FeatureFlags,
  IAdjustmentWaiverStatus,
  ICompanyDashboardSettings,
  IRole,
  IShippingCountry,
  IUserRole,
  IUserRoleActions,
  IUserRoleAndPermission,
  IUserSession,
  IWalkthrough,
} from 'typings/user-session';
import {
  DimensionsUnitAbbreviation,
  DimensionsUnitEcma,
  WeightUnitAbbreviation,
  WeightUnitEcma,
} from 'typings/units';
import { PersonaOptions } from '@client/src/onboarding/components/Personas/types';
import { UserRoleId } from '@client/data/user';

// eslint-disable-next-line import/no-cycle
import { UserRight } from '@client/core/services/user-rights/type';
import {
  companyStub,
  featuresStub,
  preferencesStub,
  userStub,
} from './fakeData/fakeUserSessionContextData';

const weightUnitMap: Record<WeightUnitAbbreviation, WeightUnitEcma> = {
  kg: 'kilogram',
  lb: 'pound',
};

const dimensionsUnitMap: Record<DimensionsUnitAbbreviation, DimensionsUnitEcma> = {
  cm: 'centimeter',
  in: 'inch',
};

export interface IShippingCountryStandards {
  salesTaxName: string;
  provincialSalesTaxName: string;
  name: string;
  id: number | null;
  features: IUserSession['user']['shipping_country']['features'];
  finance: {
    stripeKey: string;
    stripeAlpha2: string;
  };
}

interface IUserFlowRecords {
  adjustment: boolean;
}

export interface IUser {
  id: string;
  email: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  persona: PersonaOptions | null;
  flowRecords: IUserFlowRecords;
  shippingCountry: IShippingCountryStandards;
  userRights: IUserRoleActions;
  role: IRole;
  isOwner: boolean;
  userRoles: IUserRole[];
  emailVerificationRequired: boolean;
  emailVerified: boolean;
  mfaNewUser: boolean;
  isNewFedexLyocEnabled: () => boolean;
  isDuda: () => boolean;
  showSimplifiedDomesticFlow: () => boolean;
  isLuxUser: () => boolean;
  hasUserRole: (slug: IUserRoleAndPermission) => boolean;
  hasSeenWalkthrough(flowName: string): boolean;
  isConnectStoreUserActionEnabled: boolean;
  isConnectLYOCUserActionEnabled: () => boolean;
}

interface IAdjustmentsWaiver {
  status: IAdjustmentWaiverStatus;
  amount: number;
}
export interface IFeatures {
  canUseGlobalAccount: boolean;
  canDeclareZeroValue: boolean;
  requiresOrderTag: boolean;
  showRetailDiscountPrice: boolean;
  showReceiverAddressSuggestion: boolean;
  isPlatformRequired: boolean;
  canDeleteProduct: boolean;
  canUseTeamRightsAndPermission: boolean;
}

export interface ICompany {
  id: string;
  identityVerificationState: string | null;
  type: string;
  easyshipCompanyId: string;
  service: 'cloud' | 'efulfilment';
  isEfulfilment: boolean;
  weightUnit: WeightUnitAbbreviation;
  weightUnitEcma: WeightUnitEcma;
  dimensionsUnit: DimensionsUnitAbbreviation;
  dimensionsUnitEcma: DimensionsUnitEcma;
  currency: string;
  shippingCountries: Record<string, IShippingCountryStandards>;
  adjustmentsWaiver: IAdjustmentsWaiver;
  platforms: string[];
  createdAt: string;
  featureFlags: FeatureFlags;
  companySpecialTypeId: number | null;
  dashboardSettings: ICompanyDashboardSettings;
  name: string;
  phoneNumber: string;
  companyCurrency: string;
  isUnderFraudReview: boolean;
  companyUrl: string;
}

export interface IPreferences {
  showLyocBanner?: boolean;
  showBarcodeBanner?: boolean;
}

export interface IUserSessionContext {
  user: IUser;
  company: ICompany;
  features: IFeatures;
  preferences: IPreferences;
  updatePreferences: (preferences: IPreferences) => void;
}
const initShippingCountries = (shippingCountries: IShippingCountry[]) =>
  shippingCountries.reduce(
    (agg, cur) => ({
      ...agg,
      [cur.alpha2]: {
        salesTaxName: cur.finance.sales_tax_name || '',
        provincialSalesTaxName: cur.finance.provincial_sales_tax_name || '',
        name: cur.name,
        id: cur.id,
        features: cur.features,
      },
    }),
    {}
  );

class Features implements IFeatures {
  constructor(private ngUserSession: IUserSession) {}

  get canUseGlobalAccount() {
    return (
      !!this.ngUserSession.company.dashboard_settings.beta_feature_global_account &&
      !this.ngUserSession.isCompanyEfulfilment()
    );
  }

  get canDeclareZeroValue() {
    return !!this.ngUserSession.company.dashboard_settings.beta_feature_zero_declared_value;
  }

  get requiresOrderTag() {
    return !!this.ngUserSession.company.dashboard_settings.beta_feature_require_order_tag;
  }

  get showRetailDiscountPrice() {
    return !!this.ngUserSession.company.dashboard_settings.beta_feature_display_discount_price;
  }

  get showReceiverAddressSuggestion() {
    return !!this.ngUserSession.company.dashboard_settings.show_receiver_address_suggestions;
  }

  get isPlatformRequired() {
    return this.ngUserSession.isPlatformRequired();
  }

  get canDeleteProduct() {
    return this.ngUserSession.isAllowedByUserRight('products.delete');
  }

  get canUseTeamRightsAndPermission() {
    return !!this.ngUserSession.company.dashboard_settings.beta_feature_team_rights_and_permissions;
  }
}

const UserSessionContext = React.createContext<IUserSessionContext | undefined>(undefined);

function initUser(ngUserSession: IUserSession): IUser {
  const hasUserRole = (slug: IUserRoleAndPermission) => {
    if (!ngUserSession.hasTeamRightsAndPermissionsBetaKey()) {
      return true;
    }
    return ngUserSession.user.dashboard_permissions.user_roles.some((role: IUserRole) => {
      return role.slug === slug && role.is_active;
    });
  };

  const hasSeenWalkthrough = (flowName: keyof IWalkthrough) => {
    return Boolean(ngUserSession.user.flow_records.walkthrough[flowName]);
  };

  const isNewFedexLyocEnabled = () => {
    return Boolean(ngUserSession.isNewFedexLyocEnabled());
  };

  const isDuda = () => {
    return Boolean(ngUserSession.isDuda());
  };

  const isLuxUser = () => {
    return Boolean(ngUserSession.isLuxUser());
  };

  const showSimplifiedDomesticFlow = () => {
    return (
      !ngUserSession.isLuxUser() &&
      ngUserSession.getFeatureFlagsByFlagName('smb_simplified_domestic_shipping_flow')
    );
  };

  return {
    id: ngUserSession.user.id,
    email: ngUserSession.user.email,
    mobileNumber: ngUserSession.user.mobile_phone,
    firstName: ngUserSession.user.first_name,
    lastName: ngUserSession.user.last_name,
    persona: ngUserSession.user.persona,
    flowRecords: {
      adjustment: !!ngUserSession.user.flow_records.walkthrough.adjustment,
    },
    shippingCountry: {
      salesTaxName: ngUserSession.user.shipping_country.finance.sales_tax_name || '',
      provincialSalesTaxName:
        ngUserSession.user.shipping_country.finance.provincial_sales_tax_name || '',
      name: ngUserSession.user.shipping_country.name,
      id: ngUserSession.user.shipping_country.id,
      features: ngUserSession.user.shipping_country.features,
      finance: {
        stripeKey: ngUserSession.user.shipping_country.finance.stripe_account_public_key || '',
        stripeAlpha2: ngUserSession.user.shipping_country.finance.stripe_account_alpha2 || '',
      },
    },
    userRights: ngUserSession.user.role.actions,
    role: ngUserSession.user.role,
    isOwner: ngUserSession.user.role.id === UserRoleId.Owner,
    userRoles: ngUserSession.user?.dashboard_permissions?.user_roles || [],
    emailVerificationRequired: !!ngUserSession.user.email_verification_required,
    emailVerified: ngUserSession.user.email_verified,
    mfaNewUser: ngUserSession.user.mfa_new_user,
    isNewFedexLyocEnabled,
    isDuda,
    isLuxUser,
    showSimplifiedDomesticFlow,
    hasUserRole,
    hasSeenWalkthrough,
    isConnectStoreUserActionEnabled: !!ngUserSession.user.role?.actions['store.connectStore'],
    isConnectLYOCUserActionEnabled: () =>
      !!ngUserSession.user.role?.actions['couriers.connectLyoc'],
  };
}

function initCompany(ngUserSession: IUserSession): ICompany {
  return {
    id: ngUserSession.company.id,
    type: ngUserSession.company.type,
    easyshipCompanyId: ngUserSession.company.easyship_company_id,
    service: ngUserSession.isCompanyEfulfilment() ? 'efulfilment' : 'cloud',
    isEfulfilment: ngUserSession.isCompanyEfulfilment(),
    weightUnit: ngUserSession.company.weight_unit || 'kg',
    weightUnitEcma: weightUnitMap[ngUserSession.company.weight_unit || 'kg'],
    dimensionsUnit: ngUserSession.company.dimensions_unit || 'cm',
    dimensionsUnitEcma: dimensionsUnitMap[ngUserSession.company.dimensions_unit || 'cm'],
    currency: ngUserSession.company.currency,
    companySpecialTypeId: ngUserSession.company.company_special_type_id,
    shippingCountries: initShippingCountries(ngUserSession.company.shipping_countries),
    adjustmentsWaiver: ngUserSession.company.dashboard_settings
      .beta_feature_fr_adjustment_waiver ?? {
      status: 'pending',
      amount: 0,
    },
    platforms: ngUserSession.company.marketing_settings.platforms || [],
    createdAt: ngUserSession.company.created_at,
    featureFlags: ngUserSession.company.feature_flags,
    dashboardSettings: ngUserSession.getCompanyDashboardSettings(),
    name: ngUserSession.company.name,
    phoneNumber: ngUserSession.company.phone_number,
    companyCurrency: ngUserSession.getCompanyCurrency(),
    identityVerificationState: ngUserSession.company.identity_verification_state,
    isUnderFraudReview: ngUserSession.company.under_review_banner_visible,
    companyUrl: ngUserSession.company.company_url,
  };
}

function initFeatures(ngUserSession: IUserSession): IFeatures {
  return new Features(ngUserSession);
}

function initPreferences(ngUserSession: IUserSession): IPreferences {
  return {
    showLyocBanner: ngUserSession.hasNotSeenWalkthrough('lyoc-banner'),
    showBarcodeBanner: ngUserSession.hasNotSeenWalkthrough('barcode-banner'),
  };
}

interface UserSessionProviderProps {
  children: React.ReactNode;
}

function UserSessionProvider({ children }: UserSessionProviderProps) {
  const UserSession = angular.element(document.body).injector().get<IUserSession>('UserSession');

  const [preferences, setPreferences] = useState(initPreferences(UserSession));
  const [company] = useState(initCompany(UserSession));
  const [user] = useState(initUser(UserSession));
  const [features] = useState(initFeatures(UserSession));

  const value = useMemo<IUserSessionContext>(
    () => ({
      user,
      company,
      features,
      updatePreferences: setPreferences,
      preferences,
    }),
    [company, features, preferences, user]
  );

  return <UserSessionContext.Provider value={value}>{children}</UserSessionContext.Provider>;
}

interface FakeUserSessionContextData {
  children: React.ReactNode;
  value?: Partial<IUserSessionContext>;
}

function FakeUserSessionProvider({ children, value }: FakeUserSessionContextData) {
  const data = useMemo(
    () => ({
      user: userStub(),
      company: companyStub(),
      preferences: preferencesStub(),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      updatePreferences: () => {},
      features: featuresStub(),
      ...value,
    }),
    [value]
  );

  return <UserSessionContext.Provider value={data}>{children}</UserSessionContext.Provider>;
}

function useUserSession() {
  const context = useContext(UserSessionContext);

  if (!context) {
    throw new Error('useUserSession must be used within a UserSessionProvider');
  }
  return context;
}

export { UserSessionProvider, useUserSession, FakeUserSessionProvider };
