import { angularNormalizeFilterData } from '@/utils/angular';
import {
  RateItem,
  RateTypeCost,
  ShipmentFeesGroup,
  ShipmentsFilterDataCustomView,
} from '@/types/shipment';
import { ShipmentStatus } from '@/constants/shipments';

export const normalizeFilterData = (filters?: ShipmentsFilterDataCustomView) => {
  if (!filters) return undefined;
  return angularNormalizeFilterData(filters);
};

export const hasShipmentStatusCanceled = (status: ShipmentStatus) =>
  [
    ShipmentStatus.CANCELED_BY_COMPANY,
    ShipmentStatus.CANCELED_BY_EASY_SHIP,
    ShipmentStatus.CANCELED,
    ShipmentStatus.CANCELED_PENDING_REFUNDED,
    ShipmentStatus.CANCELED_PENDING_REFUNDED_NO_STOCK,
    ShipmentStatus.CANCELED_REFUNDED,
  ].includes(status);

export const hasShipmentStatusCanBuyLabel = (status: ShipmentStatus) =>
  [ShipmentStatus.CREATED].includes(status);

export const hasShipmentStatusDeleted = (status: ShipmentStatus) =>
  [ShipmentStatus.DELETED].includes(status);

export const hasShipmentStatusRequestedCancellation = (status: ShipmentStatus) =>
  [ShipmentStatus.CANCELLATION_REQUESTED].includes(status);

export const hasShipmentStatusDraft = (status: ShipmentStatus) =>
  [ShipmentStatus.DRAFT].includes(status);

export const hasShipmentStatusLabelRejected = (status: ShipmentStatus) =>
  [
    ShipmentStatus.LABEL_REJECTED_AWAITING_COURIER,
    ShipmentStatus.LABEL_REJECTED_REPORTED,
    ShipmentStatus.LABEL_REJECTED_REQUIRED_EDIT,
  ].includes(status);

export const hasShipmentStatusLabelPending = (status: ShipmentStatus) =>
  [ShipmentStatus.LABEL_PENDING_AWAITING_COURIER].includes(status);

export const hasShipmentStatusLabelNotPrintedNeedEdit = (status: ShipmentStatus) =>
  [ShipmentStatus.LABEL_REJECTED_REQUIRED_EDIT].includes(status);

export const hasShipmentStatusLabeled = (status: ShipmentStatus) =>
  !!status &&
  !hasShipmentStatusCanBuyLabel(status) &&
  !hasShipmentStatusDeleted(status) &&
  !hasShipmentStatusCanceled(status) &&
  !hasShipmentStatusRequestedCancellation(status) &&
  !hasShipmentStatusDraft(status) &&
  !hasShipmentStatusLabelRejected(status) &&
  !hasShipmentStatusLabelPending(status);

export const shipmentChargesSummary = (shipment: RateItem | null): ShipmentFeesGroup[] => {
  if (!shipment) return [] as ShipmentFeesGroup[];
  return [
    {
      name: 'Shipping cost',
      type: RateTypeCost.SHIPPING_COST,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.shipping',
          },
          value: shipment.shipmentCharge || shipment.shipmentChargeMarkup,
        },
        {
          name: {
            id: 'shipments.list.cost.discount',
            values: { code: shipment.discount?.code ?? 'NA' },
          },
          value: -(shipment.discount?.amount ?? 0),
        },
      ],
    },
    {
      name: 'Shipping cost charges',
      type: RateTypeCost.SHIPPING_COST_CHARGES,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.fuel',
          },
          value: shipment.fuelSurcharge,
        },
        {
          name: {
            id: 'shipments.list.cost.pickup',
          },
          value: shipment.minimumPickupFee,
        },
      ],
    },
    {
      name: 'Courier surcharges',
      type: RateTypeCost.COURIER_SURCHARGES,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.remote',
          },
          value: shipment.remoteAreaSurcharge,
        },
        {
          name: {
            id: 'shipments.list.cost.residential',
          },
          value: shipment.residentialDiscountedFee || shipment.residentialFeeApplied,
        },
        {
          name: {
            id: 'shipments.list.cost.oversized',
          },
          value: shipment.oversizedSurcharge,
        },
        {
          name: {
            id: 'shipments.list.cost.additional',
          },
          value: shipment.additionalServicesSurcharge,
        },
        ...(shipment.otherSurcharges?.details ?? []).map(({ name, fee }) => ({
          name: {
            id: '',
            defaultMessage: name,
          },
          value: fee,
        })),
      ],
    },
    {
      name: 'Insurance',
      type: RateTypeCost.INSURANCE,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.insurance',
          },
          value: shipment.insuranceFee,
        },
      ],
    },
    {
      name: 'Warehouse charges',
      type: RateTypeCost.WAREHOUSE_CHARGES,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.warehouse',
          },
          value: shipment.warehouseHandlingFee,
        },
      ],
    },
    {
      name: 'Taxes and duties',
      type: RateTypeCost.TAXES_AND_DUTIES,
      fees: [
        {
          name: {
            id: 'shipments.list.cost.tax',
          },
          value: shipment.importTaxCharge,
        },
        {
          name: {
            id: 'shipments.list.cost.duty',
          },
          value: shipment.importDutyCharge,
        },
        {
          name: {
            id: 'shipments.list.cost.ddp-handling',
          },
          value: shipment.ddpHandlingFee,
        },
      ],
    },
  ];
};
