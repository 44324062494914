import { getLocationPathName } from '@app/utils/routing';
import { useGetShipmentByIdQuery } from '@app/hooks/queries/useGetShipmentByIdQuery';
import { COMPANY_FEATURE_FLAGS } from '@app/constants/company';
import { useIsFeatureFlag } from '@app/hooks/general/useIsFeatureFlag';
import { useMemo } from 'react';
import { useUserSession } from '@app/contexts/providers/UserSessionProvider';

export const useSimplifiedDomestic = (shipmentId?: string) => {
  const pathName = getLocationPathName();
  const isBasicFlow = pathName.includes('/basic');
  const { data: shipment } = useGetShipmentByIdQuery({ shipmentId });
  const isDomesticFlag = useIsFeatureFlag(
    COMPANY_FEATURE_FLAGS.SMB_SIMPLIFIED_DOMESTIC_SHIPPING_FLOW
  );

  const { user } = useUserSession();
  const isLuxUser = user.isLuxUser();
  const missingUnitSellingPrice = useMemo(() => {
    return shipment?.parcelsAttributes.some((parcel) =>
      parcel.shipmentItemsAttributes.some((item) => !item.declaredCustomsValue)
    );
  }, [shipment]);

  const isDomesticShipment = shipment?.originCountryId === shipment?.destinationCountryId;

  const showSimplifiedDomesticFlow =
    !isLuxUser && isDomesticFlag && isBasicFlow && isDomesticShipment;

  return {
    showSimplifiedDomesticFlow,
    shipment,
    missingUnitSellingPrice,
  };
};
