import { CourierLogo } from '@app/constants/courier';
import { useUserSession } from '@app/contexts/providers/UserSessionProvider';
import CourierLogoAndNameComponent from '@app/components/CourierLogoAndNameComponent';
import { Alert, Button, Input } from 'easyship-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ConnectCourierFormsApg,
  CourierDetailsApgForm,
} from '@app/pages/couriers/components/ConnectCourierComponent/apg/forms';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { toastError } from '@app/components/Toastify';
import {
  DeleteCourierError,
  IUpdateCourierInfoCanadaPostResponse,
  IUpdateCourierInfoParams,
} from '@typings/courier';
import useDeleteCourierMutation from '@app/hooks/mutations/useDeleteCourierMutation';

import useUpdateCourierInfoMutation from '@app/hooks/mutations/useUpdateCourierInfoMutation';
import useCreateCourierAccountMutation from '@app/hooks/mutations/useCreateCourierAccountMutation';

import { twMerge } from 'tailwind-merge';
import useGeneratePinCodeMutation from '@app/hooks/mutations/useGeneratePinCodeMutation';

import { AddCourierParams, AddCourierResponse } from '@app/types/courierAccount';
import MixpanelService from '@app/utils/mixpanel.service';
import { camelToSnakeCase } from '@app/utils/snakeCamelTransform';
import { useMessageTransformer } from '@app/utils/angularReactMessageTransform';
import {
  AccountType,
  CourierDetails,
  DefaultPayloadType,
  FedexFormValues,
  FedexStep,
  FedexStepEnum,
  FedexVerificationMethodFormValues,
  InvoiceFormValues,
  ResendPinPayload,
} from './fedex/forms/types';
import { ConnectCourierFormCanadaPost } from './canada-post/forms';
import { ConnectCourierFormFedex } from './fedex/forms';
import { ConnectCourierProps, CourierFormValues } from './types';
import {
  AU_MYPOST_BUSINESS_AUTHORIZE_WEBSITE,
  AU_MYPOST_BUSINESS_STATE,
  DASHBOARD_URL,
  EASYSHIP_CLIENT_ID,
  getFirstNonEmptyObject,
  getSupportPageUrl,
  isSingleCountryCourier,
  showConnectCourierWarning,
} from './utils';
import { CourierFormFieldsComponent } from './CourierFormFieldsComponent';
import { FedexStepHeaderContent } from './fedex/forms/FedexStepHeaderContent';

// Rules
const courierNicknameRules = {
  required: true,
  minLength: 3,
  maxLength: 15,
};

const NEW_FEDEX_ACCOUNT_ID = 'fedex-v1';

export function ConnectCourierComponent({
  courier,
  country,
  onBack,
  onSubmit,
  onDeleteSuccess,
}: ConnectCourierProps) {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<
    CourierFormValues | FedexFormValues | InvoiceFormValues | FedexVerificationMethodFormValues
  >();
  const formValues = useWatch({ control });
  const { formatMessage } = useIntl();
  const { angularToReactMessage } = useMessageTransformer();
  const {
    user: { isNewFedexLyocEnabled },
    company,
  } = useUserSession();

  const { mutateAsync: deleteCourierMutateAsync, isLoading: isDeleting } =
    useDeleteCourierMutation();
  const { mutateAsync: updateCourierInfoMutateAsync, isLoading: isUpdatingCourierInfo } =
    useUpdateCourierInfoMutation();
  const { mutateAsync: createCourierAccount, isLoading: isCreatingAccount } =
    useCreateCourierAccountMutation();
  const { mutateAsync: generatePinCode } = useGeneratePinCodeMutation();

  const [fedexCurrentStep, setFedexCurrentStep] = useState<FedexStep>(FedexStepEnum.Eula);
  const [courierDetails, setCourierDetails] = useState<CourierDetails | CourierDetailsApgForm>({});
  const [countryCode, setCountryCode] = useState<string>('');
  const [fedexData, setFedexData] = useState<{ courierId: string; courierNickname: string }>({
    courierId: '',
    courierNickname: '',
  });
  const [courierNameAndCountry, setCourierNameAndCountry] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { logoUrl, name: courierName, account, id: courierId } = courier;
  const { name: countryName } = country;
  const showNewFedexLyoc = logoUrl === CourierLogo.Fedex && isNewFedexLyocEnabled();
  const currentStepCount = useMemo(() => {
    if (showNewFedexLyoc) {
      switch (fedexCurrentStep) {
        case FedexStepEnum.Eula:
          return '1';
        case FedexStepEnum.Form:
          return '2';
        default:
          return '3';
      }
    }
    return '1';
  }, [showNewFedexLyoc, fedexCurrentStep]);
  const maxStepCount = useMemo(() => (showNewFedexLyoc ? 3 : 1), [showNewFedexLyoc]);
  const isFedEx = useMemo(
    () => [CourierLogo.Fedex, CourierLogo.FedexCrossBorder].includes(logoUrl),
    [logoUrl]
  );
  const hideDefaultModalDescription = showNewFedexLyoc && fedexCurrentStep !== FedexStepEnum.Eula;
  const showDeleteButton = useMemo(() => {
    return account && (!showNewFedexLyoc || fedexCurrentStep === FedexStepEnum.Eula);
  }, [account, showNewFedexLyoc, fedexCurrentStep]);
  const isLoading = useMemo(() => {
    return isDeleting || isUpdatingCourierInfo || isCreatingAccount;
  }, [isDeleting, isUpdatingCourierInfo, isCreatingAccount]);
  const isEditMode = useMemo(() => {
    if (account && courierId && showNewFedexLyoc) {
      return true;
    }

    return account && courierId && ![CourierLogo.Ups, CourierLogo.Fedex].includes(logoUrl);
  }, [account, courierId, logoUrl, showNewFedexLyoc]);

  const submitLabel = useMemo(() => {
    if (showNewFedexLyoc) {
      switch (fedexCurrentStep) {
        case FedexStepEnum.Eula:
          return 'global.i-accept';
        case FedexStepEnum.Form:
          return 'global.next';
        case FedexStepEnum.PinChoice:
          return 'courier.connect.form.fedex.pin-choice.send-code';
        default:
          return 'global.connect';
      }
    }

    if (isFedEx) {
      return fedexCurrentStep === FedexStepEnum.Eula ? 'global.i-accept' : 'global.submit';
    }

    return 'global.connect';
  }, [showNewFedexLyoc, fedexCurrentStep, isFedEx]);

  const handleBackClick = () => {
    setError('');
    if (isFedEx && fedexCurrentStep !== FedexStepEnum.Eula) {
      switch (fedexCurrentStep) {
        case FedexStepEnum.Invoice:
          setFedexCurrentStep(FedexStepEnum.Form);
          break;
        case FedexStepEnum.PinChoice:
          setFedexCurrentStep(FedexStepEnum.Invoice);
          break;
        case FedexStepEnum.PinEmail:
        case FedexStepEnum.PinSMS:
          setFedexCurrentStep(FedexStepEnum.PinChoice);
          break;
        default:
          setFedexCurrentStep(FedexStepEnum.Eula);
          break;
      }
      return;
    }
    setCourierDetails({});
    onBack();
  };

  const getFormPayload = (account: AccountType) => {
    if (showNewFedexLyoc && fedexCurrentStep === FedexStepEnum.Form) {
      return {
        umbrella_name: NEW_FEDEX_ACCOUNT_ID,
        account: {
          nickname: account.accountNickname,
          account_number: account.accountNumber,
          origin_country_id: account.origin_country_id,
          country_code: countryCode,
          postal_code: account.billingAddress.postalCode,
          city: account.billingAddress.city,
          state: account.billingAddress.state,
          line_1: account.line1,
          line_2: account.line2,
          username: account.username,
          password: account.password,
          api_key: account.apiKey,
        },
      };
    }
    if (fedexCurrentStep === FedexStepEnum.Invoice) {
      return {
        id: fedexData.courierId,
        account: {
          nickname: fedexData.courierNickname,
          validation_option: 'invoice',
          pin_code: null,
          invoice_details: {
            number: account.number,
            currency: account.currency,
            date: account.date,
            amount: Number(account.amount),
          },
        },
      };
    }
    if (showNewFedexLyoc && fedexCurrentStep === FedexStepEnum.PinChoice) {
      return {
        companyId: company.id,
        id: fedexData.courierId,
        account: {
          validation_option: account.validationOption,
        },
      };
    }

    const isPinVerificationStep = [FedexStepEnum.PinSMS, FedexStepEnum.PinEmail].includes(
      fedexCurrentStep
    );
    if (showNewFedexLyoc && isPinVerificationStep) {
      return {
        id: fedexData.courierId,
        account: {
          nickname: fedexData.courierNickname,
          validation_option: fedexCurrentStep === FedexStepEnum.PinSMS ? 'sms' : 'email',
          pin_code: account.pinCode,
          invoice_details: {},
        },
      };
    }
    if (logoUrl === CourierLogo.FedexCrossBorder && fedexCurrentStep === FedexStepEnum.Form) {
      return {
        umbrella_name: courier.logoUrl,
        account: {
          nickname: account.accountNickname,
          api_key: account.apiKey,
          username: account.username,
          password: account.password,
          origin_country_id: courier.country_id,
        },
      };
    }
    return {
      umbrella_name: courier.logoUrl,
      ...camelToSnakeCase({ account } as { account: AccountType }),
    };
  };

  const handleResendPin = ({ payload }: { payload: ResendPinPayload }): Promise<void> => {
    const updatedPayload = { ...payload };
    if (!updatedPayload.account.validation_option) {
      updatedPayload.account.validation_option = 'email';
    }
    setCourierDetails((prev) => ({ ...prev, pin_code: null }));
    return generatePinCode(updatedPayload);
  };

  const getCourierAction = async (
    payload: DefaultPayloadType | ResendPinPayload | IUpdateCourierInfoParams | AddCourierParams
  ): Promise<void | IUpdateCourierInfoCanadaPostResponse | AddCourierResponse> => {
    try {
      if (fedexCurrentStep === FedexStepEnum.PinChoice) {
        return await handleResendPin({ payload } as { payload: ResendPinPayload });
      }

      let shouldUpdateCourier;
      if (isNewFedexLyocEnabled() && logoUrl === CourierLogo.Fedex) {
        shouldUpdateCourier = fedexCurrentStep !== FedexStepEnum.Form;
      } else {
        shouldUpdateCourier = isEditMode && logoUrl !== CourierLogo.Dhl;
      }

      const response = shouldUpdateCourier
        ? await updateCourierInfoMutateAsync(payload as IUpdateCourierInfoParams)
        : await createCourierAccount(payload as AddCourierParams);

      return response;
    } catch (error) {
      const typedError = error as DeleteCourierError;
      const errorText =
        typedError.data && typedError.data.status
          ? typedError.data.status
          : formatMessage({ id: 'toast.default-error' });
      setError(errorText);
      return undefined;
    }
  };

  const connectToAuPostMyPostBusiness = () => {
    const AustraliaPostMyPostBusinessUrl = `${AU_MYPOST_BUSINESS_AUTHORIZE_WEBSITE}&client_id=${EASYSHIP_CLIENT_ID}&redirect_uri=${DASHBOARD_URL}/couriers/${logoUrl}/redirect?umbrella_name=${logoUrl}&state=${AU_MYPOST_BUSINESS_STATE}`;
    window.open(AustraliaPostMyPostBusinessUrl, '_self');
    MixpanelService.track('Courier - Add Service - Connect', { courier_name: logoUrl });
  };

  const handleSubmitForm = async (data?: CourierDetails | CourierDetailsApgForm): Promise<void> => {
    const details = getFirstNonEmptyObject(formValues, courierDetails, data);
    if (!details) {
      return;
    }
    const payload = getFormPayload(details as AccountType);
    if (logoUrl === CourierLogo.MyPost) {
      const values = formValues as CourierFormValues;
      window.localStorage.setItem('myPostBusinessUserAccountName', values.nickname);
      connectToAuPostMyPostBusiness();
      return;
    }

    if (isEditMode) {
      payload.id = courierId;
    }
    if (
      ![FedexStepEnum.PinChoice, FedexStepEnum.PinSMS, FedexStepEnum.PinEmail].includes(
        fedexCurrentStep
      )
    ) {
      payload.account.origin_country_id = courier.country_id;
    }

    const response = await getCourierAction(
      payload as DefaultPayloadType | ResendPinPayload | IUpdateCourierInfoParams | AddCourierParams
    );
    if (fedexCurrentStep === FedexStepEnum.PinChoice) {
      setFedexCurrentStep(
        (payload as ResendPinPayload).account.validation_option === 'sms'
          ? FedexStepEnum.PinSMS
          : FedexStepEnum.PinEmail
      );
      return;
    }
    if (response) {
      if (showNewFedexLyoc && fedexCurrentStep === FedexStepEnum.Form) {
        setFedexData({
          courierId: (response as AddCourierResponse).id,
          courierNickname: (payload.account as { nickname: string }).nickname,
        });
        setFedexCurrentStep(FedexStepEnum.Invoice);
        return;
      }
      onSubmit({ linkedCourier: response });
    }
  };

  const validateCourierStep = () => {
    if (isValid) {
      setError('');
    } else {
      setError(formatMessage({ id: 'toast.incomplete-form' }));
    }
    if (isFedEx && fedexCurrentStep === FedexStepEnum.Eula) {
      setFedexCurrentStep(FedexStepEnum.Form);
      return;
    }
    handleSubmitForm();
  };

  const handleStepChange = (step: FedexStep): void => {
    setError('');
    setFedexCurrentStep(step);
  };

  const handleChangeCourierDetails = useCallback((details: CourierDetails): void => {
    if (details.country_code) {
      setCountryCode((prevCode) =>
        details.country_code !== prevCode ? (details.country_code as string) : prevCode
      );
    }
    setCourierDetails((prev) => ({ ...prev, ...details }));
  }, []);

  const handleDeleteCourier = async () => {
    try {
      await deleteCourierMutateAsync(courierId);
      onDeleteSuccess();
    } catch (error: unknown) {
      const typedError = error as DeleteCourierError;
      const errorText =
        typedError.data && typedError.data.status
          ? typedError.data.status
          : formatMessage({ id: 'toast.default-error' });
      toastError(errorText);
    }
  };

  useEffect(() => {
    setCourierNameAndCountry(
      `${courierName} ${isSingleCountryCourier(logoUrl) ? '' : countryName}`
    );
  }, [courierName, countryName, logoUrl]);

  return (
    <>
      <div className="py-5 px-12 border-b border-b-sky-300 flex items-center">
        <CourierLogoAndNameComponent
          esLogoSize="large"
          esLogoUrl={logoUrl}
          esName={`Connect ${courierName}`}
          esNameSize="small"
        />
        {showNewFedexLyoc && (
          <div className="text-base ml-1">
            (
            <span>
              <FormattedMessage
                id="global.step-counter"
                defaultMessage={angularToReactMessage('global.step-counter')}
                values={{
                  total: maxStepCount,
                  current: currentStepCount,
                }}
              />
            </span>
            )
          </div>
        )}
      </div>

      {!hideDefaultModalDescription && (
        <div className="py-5 mx-12 border-b border-b-sky-300">
          <p className="m-0 text-base text-ink-900">
            <FormattedMessage
              id="courier.connect.form.description"
              values={{
                courierNameAndCountry,
                // eslint-disable-next-line react/no-unstable-nested-components
                b: (chunks) => <b>{chunks}</b>,
                // eslint-disable-next-line react/no-unstable-nested-components
                a: (chunks) => (
                  <a
                    href={getSupportPageUrl(courier.logoUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </p>
          {showConnectCourierWarning(logoUrl) && (
            <p className="text-base text-ink-900 mt-2">
              <FormattedMessage
                id="courier.connect.form.warning"
                values={{
                  courierName,
                }}
              />
            </p>
          )}
          {logoUrl === CourierLogo.Dhl && (
            <Alert className="w-[407px]">
              <FormattedMessage
                id="courier.connect.form.dhl.mydhl-warning"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  a: (chunks) => (
                    <a href={getSupportPageUrl(logoUrl)} target="_blank" rel="noopener noreferrer">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Alert>
          )}
          {logoUrl === CourierLogo.RoyalMail && (
            <p className="text-base text-ink-900 mt-2">
              <FormattedMessage
                id="courier.connect.form.royal-mail-note"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  i: (chunks) => <i>{chunks}</i>,
                }}
              />
            </p>
          )}
        </div>
      )}
      {showNewFedexLyoc && fedexCurrentStep !== FedexStepEnum.Eula && (
        <FedexStepHeaderContent step={fedexCurrentStep} />
      )}
      {![CourierLogo.CanadaPost, CourierLogo.Apg].includes(logoUrl) && (
        <form
          className={twMerge(
            isLoading &&
              'relative after:absolute after:inset-0 after:z-1 after:block after:bg-white/90'
          )}
          onSubmit={handleSubmit(validateCourierStep)}
        >
          {isLoading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-72 text-center">
              <div className="spinner spinner-sm" />
              <p className="m-0 text-base text-ink-900 mt-[30px]">
                <FormattedMessage id="courier.connect.form.please-wait" />
              </p>
            </div>
          )}
          {isFedEx && (
            <div>
              <ConnectCourierFormFedex
                courierId={fedexData.courierId}
                companyId={company.id}
                currentStep={fedexCurrentStep}
                umbrellaName={logoUrl}
                error={error}
                onChangeCourierDetails={handleChangeCourierDetails}
                onChangeStep={handleStepChange}
                onResendPin={handleResendPin}
              />
            </div>
          )}
          {!isFedEx && (
            <div className="flex flex-col py-5 px-12">
              <div>
                <Controller
                  name="nickname"
                  rules={courierNicknameRules}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      label={<FormattedMessage id="courier.connect.field.account-name.label" />}
                      placeholder={formatMessage({
                        id: 'courier.connect.field.account-name.placeholder',
                      })}
                      statusText={formatMessage({ id: 'courier.connect.field.account-name.help' })}
                      status={(!!fieldState.error && 'error') || 'default'}
                      maxLength={courierNicknameRules.maxLength}
                      minLength={courierNicknameRules.minLength}
                    />
                  )}
                />
              </div>
              {logoUrl && <CourierFormFieldsComponent logoUrl={logoUrl} />}
              {error && (
                <Alert severity="error" className="mt-4">
                  {error}
                </Alert>
              )}
            </div>
          )}
          <div className="flex justify-between items-center px-12 py-5">
            {showDeleteButton && (
              <Button
                color="danger"
                type="button"
                loading={isDeleting}
                onClick={handleDeleteCourier}
              >
                <FormattedMessage id="global.delete" />
              </Button>
            )}
            {!showDeleteButton && (
              <Button color="default" flat type="button" onClick={handleBackClick}>
                <FormattedMessage id="global.back" />
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              className="min-w-[100px]"
              disabled={!isValid || isLoading}
              loading={isLoading}
            >
              <FormattedMessage id={submitLabel} />
            </Button>
          </div>
        </form>
      )}
      {logoUrl === CourierLogo.CanadaPost && (
        <ConnectCourierFormCanadaPost
          editMode={!!account}
          onDelete={handleDeleteCourier}
          onBack={handleBackClick}
        />
      )}
      {logoUrl === CourierLogo.Apg && (
        <ConnectCourierFormsApg
          courierDetails={courierDetails as CourierDetailsApgForm}
          editMode={!!account}
          error={error}
          onSubmit={handleSubmitForm}
          onDelete={handleDeleteCourier}
          onBack={handleBackClick}
        />
      )}
    </>
  );
}
