export const SizeIn = ['Letter', 'Letter_half'];
export const SizeCm = ['A4', 'A5', 'A4_half'];

export const COMMERCIAL_INVOICE_SIZE_4_6 = '4x6';

export const DIRECT_PRINT_ERROR_CODES = {
  PRINTER_OFFLINE: 'printer_offline',
  PRINTER_NOT_SET: 'printer_not_set',
  SUBSCRIPTION_ACCESS: 'subscription_access_denied',
} as const;
