import { MixpanelEventContext } from '@/constants/mixpanel';

export enum DirectPrintEntityType {
  Shipment = 'shipment',
  Report = 'report',
}

export enum DirectPrintDocumentType {
  Label = 'label',
  ShippingDocuments = 'shipping_documents',
  PackingSlip = 'packing_slip',
  CommercialInvoice = 'commercial_invoice',
}

export type DirectPrintProps = {
  companyId: string;
  entityType: DirectPrintEntityType;
  entityIds: string[];
  documentType: DirectPrintDocumentType;
  analyticContext: MixpanelEventContext;
};
