export type Country = {
  alpha2: string;
  name: string;
  contactNumber: string;
};

export const COUNTRIES: Country[] = [
  {
    alpha2: 'au',
    name: 'Australia',
    contactNumber: '+61 3 4052 5321',
  },
  {
    alpha2: 'us',
    name: 'United States',
    contactNumber: '+1 (816) 371 9963',
  },
  {
    alpha2: 'gb',
    name: 'United Kingdom',
    contactNumber: '+44 1895 544441',
  },
  {
    alpha2: 'hk',
    name: 'Hong Kong',
    contactNumber: '+852 3001 1469',
  },
  {
    alpha2: 'sg',
    name: 'Singapore',
    contactNumber: '+65 3129 3921',
  },
];
