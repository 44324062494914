import React from 'react';
import { Card } from 'easyship-components';
import { IVoucher } from '@typings/user-status';
import voucherImageUrl from '@assets/images/dashboard/add-credit/voucher.svg';
import { FormattedMessage } from 'react-intl';

interface PromoCodeCardProps {
  voucher: IVoucher;
}

export function PromoCodeCard({ voucher }: PromoCodeCardProps) {
  return (
    <Card className="flex items-center w-full py-5 px-7">
      <img src={voucherImageUrl} alt="voucher" className="mr-4" />
      <div>
        <p className="m-0 text-base font-semibold">
          {voucher?.code}
          {voucher.percentage && (
            <FormattedMessage
              id="account.payment.promo-code.discount"
              values={{
                percent: (voucher.percentage * 100).toFixed(2),
              }}
            />
          )}
        </p>
        <p className="m-0 text-base">{voucher?.description}</p>
        <p className="text-base text-sky-700">
          {voucher.expires_at && (
            <FormattedMessage
              id="account.payment.promo-code.expiry"
              values={{
                date: new Date(voucher.expires_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }),
              }}
            />
          )}
        </p>
      </div>
    </Card>
  );
}
