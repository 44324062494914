import { MixpanelEventContext } from '@/constants/mixpanel';
import { directPrintRequest } from '@/api/printing_documents';
import { DirectPrintDocumentType, DirectPrintEntityType } from '@/api/printing_documents/types';
import { openModal } from '@/utils/modals';
import { lazy } from 'react';
import { AxiosError } from 'axios';
import { ApiResponseErrorWithCodeAndMessage } from '@/types/api';
import { getUserSession } from '@/utils/general';
import { UserSession } from '@/hooks/queries/useGetUserSessionQuery/types';
import { DIRECT_PRINT_ERROR_CODES } from '@/constants/printing';
import { SubscriptionService } from '@app/types/subscription';
import angular from 'angular';

const PrintingAnimationModal = lazy(() => import('@/components/PrintingAnimationModal'));
const DirectPrintErrorModal = lazy(() => import('@/components/DirectPrintErrorModal'));

const directPrintError = async (error: AxiosError, callbackBrowserPrint: () => void) => {
  const code = (error as ApiResponseErrorWithCodeAndMessage)?.response?.data?.error?.code;
  switch (code) {
    case DIRECT_PRINT_ERROR_CODES.SUBSCRIPTION_ACCESS: {
      const service: SubscriptionService = angular
        .element(document.body)
        .injector()
        .get('SubscriptionService');
      return service?.getSubscriptionShowTrialEndOrPaymentFailedModal().then(() => {
        const isVisibleModal = !!service?.isEndOrPaymentFailedModalVisible();
        if (isVisibleModal) {
          const element = document.querySelector('.ng-scope');
          if (element) {
            const scope = angular.element(element).scope();
            scope.$apply();
          }
        } else {
          openModal(DirectPrintErrorModal, {
            hasPrinterConnected: false,
            callbackBrowserPrint,
          });
        }
      });
    }
    default:
      return openModal(DirectPrintErrorModal, {
        hasPrinterConnected:
          code !== DIRECT_PRINT_ERROR_CODES.PRINTER_OFFLINE &&
          code !== DIRECT_PRINT_ERROR_CODES.PRINTER_NOT_SET,
        callbackBrowserPrint,
      });
  }
};

const directPrint = async ({
  ids,
  context,
  documentType,
  entityType,
  callBackBrowserPrint,
}: {
  ids: string | string[];
  context: MixpanelEventContext;
  documentType: DirectPrintDocumentType;
  entityType: DirectPrintEntityType;
  callBackBrowserPrint: () => void;
}): Promise<void> => {
  const { company } = getUserSession() as Required<UserSession>;
  const openModalData = openModal(PrintingAnimationModal);
  const { close: closeModalPrinting } = openModalData || ({} as { close: () => void });
  const entityIds = Array.isArray(ids) ? ids : [ids];
  try {
    await directPrintRequest({
      companyId: company?.id,
      entityType,
      entityIds,
      documentType,
      analyticContext: context,
    });
  } catch (error) {
    await directPrintError(error, callBackBrowserPrint);
  } finally {
    closeModalPrinting?.();
  }
};

export const directPrintCommercialInvoice = async (
  shipmentId: string | string[],
  context: MixpanelEventContext,
  callBackBrowserPrint: () => void
): Promise<void> =>
  directPrint({
    ids: shipmentId,
    context,
    documentType: DirectPrintDocumentType.CommercialInvoice,
    entityType: DirectPrintEntityType.Shipment,
    callBackBrowserPrint,
  });

export const directPrintShippingDoc = async (
  shipmentId: string | string[],
  context: MixpanelEventContext,
  callBackBrowserPrint: () => void
): Promise<void> =>
  directPrint({
    ids: shipmentId,
    context,
    documentType: DirectPrintDocumentType.ShippingDocuments,
    entityType: DirectPrintEntityType.Shipment,
    callBackBrowserPrint,
  });

export const directPrintReport = async (
  reportId: string | string[],
  context: MixpanelEventContext,
  callBackBrowserPrint: () => void
): Promise<void> =>
  directPrint({
    ids: reportId,
    context,
    documentType: DirectPrintDocumentType.ShippingDocuments,
    entityType: DirectPrintEntityType.Report,
    callBackBrowserPrint,
  });

export const directPrintLabel = async (
  shipmentId: string | string[],
  context: MixpanelEventContext,
  callBackBrowserPrint: () => void
): Promise<void> =>
  directPrint({
    ids: shipmentId,
    context,
    documentType: DirectPrintDocumentType.Label,
    entityType: DirectPrintEntityType.Shipment,
    callBackBrowserPrint,
  });

export const directPrintPackingSlips = async (
  reportId: string | string[],
  context: MixpanelEventContext,
  callBackBrowserPrint: () => void
): Promise<void> =>
  directPrint({
    ids: reportId,
    context,
    documentType: DirectPrintDocumentType.PackingSlip,
    entityType: DirectPrintEntityType.Shipment,
    callBackBrowserPrint,
  });
