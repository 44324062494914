import { CourierLogo } from '@app/constants/courier';

const passportApiKeyRegex = /^[a-zA-Z0-9]+$/;
export interface FormFieldRule {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: {
    value: RegExp;
    message: string;
  };
}

export interface FormField {
  name: string;
  id: string;
  type?: 'text' | 'password';
  autocomplete?: string;
  placeholder?: string;
  helperText?: string;
  rules: FormFieldRule;
  values?: Record<string, string>;
}

export interface CourierFields {
  courier: CourierLogo;
  fields: FormField[];
}

export const courierFieldsConfig: CourierFields[] = [
  {
    courier: CourierLogo.Dhl,
    fields: [
      {
        name: 'accountNumber',
        id: 'courier.connect.field.account-number.label',
        rules: {
          required: true,
          maxLength: 9,
        },
        values: { courierName: 'DHL' },
        helperText: 'courier.connect.field.account-number.dhl-help',
        placeholder: 'courier.connect.field.account-number.placeholder-dhl',
      },
      {
        name: 'apiKey',
        id: 'courier.connect.field.account-api-key.label',
        rules: {
          required: true,
          maxLength: 14,
        },
        values: { courierName: 'MyDHL' },
        helperText: 'courier.connect.field.account-api-key.dhl-help',
      },
      {
        name: 'apiSecret',
        id: 'courier.connect.field.account-api-secret.label',
        rules: {
          required: true,
          maxLength: 16,
        },
        values: { courierName: 'MyDHL' },
        helperText: 'courier.connect.field.account-api-secret.dhl-help',
        type: 'password',
        autocomplete: 'new-password',
      },
    ],
  },
  {
    courier: CourierLogo.Sendle,
    fields: [
      {
        name: 'id',
        id: 'courier.connect.field.id.label',
        rules: { required: true },
        values: { courierName: 'Sendle' },
      },
      {
        name: 'apiKey',
        id: 'courier.connect.field.api-key.label',
        rules: { required: true },
        values: { courierName: 'Sendle' },
      },
    ],
  },
  {
    courier: CourierLogo.RoyalMail,
    fields: [
      {
        name: 'accountNumber',
        id: 'courier.connect.field.account-number.label',
        rules: {
          required: true,
          minLength: 9,
        },
        values: { courierName: 'Royal Mail' },
        placeholder: 'courier.connect.field.account-number.placeholder-royal-mail',
      },
      {
        name: 'customerName',
        id: 'courier.connect.field.royal-mail.customer-name.label',
        rules: { required: true },
      },
      {
        name: 'obaEmailAddress',
        id: 'courier.connect.field.royal-mail.oba-email.label',
        rules: { required: true },
      },
      {
        name: 'intersoftPostingLocation',
        id: 'courier.connect.field.royal-mail.pln.label',
        rules: {
          required: true,
          minLength: 10,
        },
        placeholder: 'courier.connect.field.royal-mail.pln.placeholder',
      },
    ],
  },
  {
    courier: CourierLogo.Passport,
    fields: [
      {
        name: 'companyName',
        id: 'global.company-name',
        rules: {
          required: true,
          maxLength: 45,
        },
      },
      {
        name: 'apiKey',
        id: 'courier.connect.field.api-key.label',
        rules: {
          required: true,
          pattern: {
            value: passportApiKeyRegex,
            message: 'Only alphanumeric characters are allowed',
          },
          maxLength: 45,
        },
        values: { courierName: 'Passport' },
      },
    ],
  },
  {
    courier: CourierLogo.Skypostal,
    fields: [
      {
        name: 'copaId',
        id: 'courier.connect.field.merchant-number-copa-id',
        rules: { required: true },
        values: { courierName: 'Skypostal' },
      },
      {
        name: 'userCode',
        id: 'courier.connect.field.user-code',
        rules: { required: true },
        values: { courierName: 'Skypostal' },
      },
      {
        name: 'userKey',
        id: 'courier.connect.field.user-key',
        rules: { required: true },
        values: { courierName: 'Skypostal' },
      },
      {
        name: 'appKey',
        id: 'courier.connect.field.app-key',
        rules: { required: true },
        values: { courierName: 'Skypostal' },
      },
    ],
  },
  {
    courier: CourierLogo.AustraliaPost,
    fields: [
      {
        name: 'accountNumber',
        id: 'courier.connect.field.account-number.label',
        rules: { required: true },
        values: { courierName: 'Australia Post' },
      },
      {
        name: 'password',
        id: 'courier.connect.field.account-password.label',
        type: 'password',
        rules: { required: true },
        values: { courierName: 'Australia Post' },
      },
      {
        name: 'userId',
        id: 'courier.connect.field.api-key.label',
        rules: { required: true },
        values: { courierName: 'Australia Post' },
      },
    ],
  },
  {
    courier: CourierLogo.AustraliaPostOnDemand,
    fields: [
      {
        name: 'accountNumber',
        id: 'courier.connect.field.account-number.label',
        rules: { required: true },
        values: { courierName: 'Australia Post On Demand' },
      },
      {
        name: 'password',
        id: 'courier.connect.field.account-password.label',
        type: 'password',
        rules: { required: true },
        values: { courierName: 'Australia Post On Demand' },
      },
      {
        name: 'userId',
        id: 'courier.connect.field.api-key.label',
        rules: { required: true },
        values: { courierName: 'Australia Post On Demand' },
      },
    ],
  },
  {
    courier: CourierLogo.StarTrack,
    fields: [
      {
        name: 'accountNumber',
        id: 'courier.connect.field.account-number.label',
        rules: { required: true },
        values: { courierName: 'StarTrack' },
      },
      {
        name: 'password',
        id: 'courier.connect.field.account-password.label',
        type: 'password',
        rules: { required: true },
        values: { courierName: 'StarTrack' },
      },
      {
        name: 'userId',
        id: 'courier.connect.field.api-key.label',
        rules: { required: true },
        values: { courierName: 'StarTrack' },
      },
    ],
  },
];
