/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from '@headlessui/react';
import { Alert, Button, IconButton, Modal } from 'easyship-components';
import { CrossLarge, Download, Printer } from 'easyship-components/icons';
import { saveAs } from 'file-saver';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import { twMerge } from 'tailwind-merge';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { UserRoleGatewayProvider } from '@client/src/account/team/providers/UserRoleGatewayProvider';
import { IShipmentListItem, ShippingDocuments } from 'typings/shipment';
import { toastError } from '@client/core/components/react/Toastify';
// eslint-disable-next-line import/no-named-as-default
import HotjarService, { TriggerSurveyEvent } from '@client/core/services/hotjar/hotjar.service';
import { useHasDirectPrintEnabled } from '@/hooks/general/useUserDirectPrintSettings';
import { ModalContainerProps } from '@/components/ModalContainer/types';
import { directPrintLabel } from '@/utils/printing_actions';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { ShipmentGatewayProvider } from '../providers/ShipmentGatewayProvider';
import useCreateShippingDocumentsMutation from '../hooks/mutations/useCreateShippingDocumentsMutation';
import PrinterAnimation from './PrinterAnimation';
import useMarkShipmentAsPrintedMutation from '../hooks/mutations/useMarkShipmentAsPrintedMutation';

export interface PrintPreviewModalProps {
  open: boolean;
  shipmentId: string;
  context: string;
  isGenerating?: boolean;
  onClose?: () => void;
  onError?: (error?: unknown) => void;
  onShippingDocumentsCreated?: () => void;
  onLabelPrinted?: (shipment: IShipmentListItem) => void;
  headerClassName?: string;
  contentClassName?: string;
  useDirectPrintNode?: boolean;
}

const PRINT_PREVIEW_MODAL_KEY = 'shipments.printing-options.print-preview-modal';

const fetchPDF = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export function PrintPreviewModalInner({
  open,
  isGenerating = false,
  shipmentId,
  context,
  onClose,
  onError,
  onShippingDocumentsCreated,
  onLabelPrinted,
  headerClassName,
  contentClassName,
  useDirectPrintNode,
}: PrintPreviewModalProps) {
  const { formatMessage } = useIntl();
  const translate = useCallback((id: string) => formatMessage({ id }), []);

  const hasDirectPrintingEnabled = useHasDirectPrintEnabled();

  const {
    data: documents = [],
    isLoading: isAssembling = true,
    isSuccess: createShippingDocumentsIsSuccess,
    error: createShippingDocumentsError,
    mutateAsync: createShippingDocuments,
  } = useCreateShippingDocumentsMutation();

  const { data: printedShipmentData, mutateAsync: markShipmentAsPrinted } =
    useMarkShipmentAsPrintedMutation();

  const [isOpen, setIsOpen] = useState(open);
  const [selectedDocument, setSelectedDocument] = useState<ShippingDocuments | null>(null);
  const [iframeSrc, setIframeSrc] = useState('');
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [indicatorProps, setIndicatorProps] = useState<{
    left: number;
    width: number;
  } | null>(null);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const tabRef = useRef<HTMLButtonElement | null>(null);

  const createIframeSrc = async (url: string) => {
    setIsIframeLoaded(false);
    const blobUrl = await fetchPDF(url);
    setIframeSrc(`${blobUrl}#toolbar=0&view=fit`);
    setTimeout(() => setIsIframeLoaded(true), 800);
  };

  const onTabChange = (index: number) => {
    const shippingDoc = documents[index];
    setSelectedDocument(shippingDoc);
    createIframeSrc(shippingDoc.url);

    // update indicator props based on the selected tab width
    const tab = tabRef.current;

    if (tab) {
      const width = tab.clientWidth;
      const left = width * index;
      setIndicatorProps({
        width,
        left,
      });
    }
  };

  const handleDownload = () => {
    if (!iframeSrc || !selectedDocument) {
      return;
    }

    saveAs(iframeSrc, selectedDocument.name);

    markShipmentAsPrinted({
      id: shipmentId,
      context,
      print_method: 'Download',
      shipping_document: selectedDocument,
    });

    HotjarService.triggerSurveyEvent(TriggerSurveyEvent.PrintLabelAfterPurchase);
  };

  const handlePrint = () => {
    const fnBrowserPrint = () => {
      if (!iframeSrc || !selectedDocument) {
        return;
      }

      iframeRef.current?.contentWindow?.print();
      markShipmentAsPrinted({
        id: shipmentId,
        context,
        print_method: 'Print',
        shipping_document: selectedDocument,
      });
    };

    if (useDirectPrintNode && hasDirectPrintingEnabled) {
      directPrintLabel(shipmentId, context as MixpanelEventContext, fnBrowserPrint).then();
    } else fnBrowserPrint();

    HotjarService.triggerSurveyEvent(TriggerSurveyEvent.PrintLabelAfterPurchase);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleError = () => {
    onError?.();
    toastError(translate(`${PRINT_PREVIEW_MODAL_KEY}.alert-message-error`));
    handleClose();
  };

  useEffect(() => {
    if (!createShippingDocumentsIsSuccess) {
      return;
    }

    if (documents.length === 0) {
      handleError();
    } else {
      const shippingDoc = documents[0];
      setSelectedDocument(shippingDoc);
      createIframeSrc(shippingDoc.url);
      onShippingDocumentsCreated?.();
    }
  }, [documents, createShippingDocumentsIsSuccess, translate]);

  useEffect(() => {
    if (!isGenerating && shipmentId) {
      createShippingDocuments({ id: shipmentId, context });
    }
  }, [isGenerating, shipmentId, createShippingDocuments, context]);

  useEffect(() => {
    if (createShippingDocumentsError) {
      handleError();
    }
  }, [createShippingDocumentsError, onError]);

  useEffect(() => {
    if (printedShipmentData) {
      onLabelPrinted?.(printedShipmentData);
    }
  }, [printedShipmentData, onLabelPrinted]);

  const isLoading = isAssembling || isGenerating;

  return (
    <>
      {isOpen && (
        <Modal id="print-preview-modal" open onClose={handleClose}>
          {isLoading && (
            <>
              <Modal.Header
                className={twMerge('border-b-0', headerClassName)}
                closeButtonAriaLabel="Close"
              />
              <Modal.Content
                className={twMerge(
                  'flex flex-col items-center justify-center w-[800px] !h-[556px]',
                  contentClassName
                )}
              >
                <PrinterAnimation />
                <h2 className="mb-5">
                  {translate(
                    `${PRINT_PREVIEW_MODAL_KEY}.${
                      isAssembling ? 'assembling' : 'generating'
                    }-heading`
                  )}
                </h2>
                <p className="text-center text-lg">
                  {translate(
                    `${PRINT_PREVIEW_MODAL_KEY}.${
                      isAssembling ? 'assembling' : 'generating'
                    }-message`
                  )}
                </p>
              </Modal.Content>
            </>
          )}
          {!isLoading && createShippingDocumentsIsSuccess && (
            <Tab.Group onChange={onTabChange}>
              <Tab.List className="flex justify-between relative pr-[70px]">
                {documents.map(({ name, format }) => (
                  <Tab key={name} as={Fragment}>
                    {({ selected }) => (
                      <button
                        role="tab"
                        aria-selected={selected}
                        ref={(el) => {
                          if (el && documents.length > 1 && !indicatorProps) {
                            setIndicatorProps({ width: el.clientWidth, left: 0 });
                          }

                          tabRef.current = el;
                        }}
                        type="button"
                        className={twMerge(
                          'w-full h-[56px] flex-grow bg-white text-lg focus:outline-none',
                          documents.length <= 1 && 'cursor-auto pl-7 text-left',
                          documents.length > 1 && 'hover:bg-sky-300',
                          selected && 'font-semibold'
                        )}
                      >
                        {name} ({format})
                      </button>
                    )}
                  </Tab>
                ))}
                {indicatorProps && (
                  <span
                    role="presentation"
                    className="h-[3px] pt-[1px] absolute -bottom-[1px] bg-[#4ccace] transition-all duration-300 ease-in-out"
                    style={{ width: indicatorProps.width, left: indicatorProps.left }}
                  />
                )}
                <IconButton
                  className="absolute top-[8px] right-[16px] cursor-pointer z-10 p-0"
                  flat
                  tooltipProps={{
                    label: '',
                    disabled: true,
                  }}
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CrossLarge />
                </IconButton>
              </Tab.List>
              <Modal.Content className="w-[800px] p-0">
                <Tab.Panels>
                  {documents.map(({ name }) => (
                    <Tab.Panel
                      key={name}
                      className="flex justify-center items-stretch bg-[#525659] pt-2 min-h-[500px]"
                    >
                      {iframeSrc && (
                        <iframe
                          ref={iframeRef}
                          src={iframeSrc}
                          title={name}
                          style={{ visibility: isIframeLoaded ? 'visible' : 'hidden' }}
                          width="100%"
                        />
                      )}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Modal.Content>
              <Modal.Footer>
                {documents.length > 1 && (
                  <Alert className="flex-grow">
                    {translate(`${PRINT_PREVIEW_MODAL_KEY}.alert-message-new`)}
                  </Alert>
                )}
                <Button
                  disabled={isLoading || !isIframeLoaded}
                  leftIcon={<Download />}
                  onClick={handleDownload}
                  flat={documents.length <= 1}
                >
                  {translate('global.download')}
                </Button>
                <Button
                  disabled={isLoading || !isIframeLoaded}
                  color="primary"
                  leftIcon={<Printer />}
                  onClick={handlePrint}
                >
                  {translate('global.print')}
                </Button>
              </Modal.Footer>
            </Tab.Group>
          )}
        </Modal>
      )}
    </>
  );
}

export function PrintPreviewModal(props: PrintPreviewModalProps & Partial<ModalContainerProps>) {
  const { isOpen, handleClose, open, ...rest } = props;
  return (
    <PrintPreviewModalInner
      open={typeof isOpen === 'boolean' ? isOpen : open}
      onClose={handleClose}
      {...rest}
    />
  );
}

function WrappedPrintPreviewModal(props: PrintPreviewModalProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UserRoleGatewayProvider>
          <ShipmentGatewayProvider>
            <PrintPreviewModal {...props} />
          </ShipmentGatewayProvider>
        </UserRoleGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularPrintPreviewModal = react2angular(
  WrappedPrintPreviewModal,
  ['open', 'isGenerating', 'shipmentId', 'context', 'onClose', 'onError', 'onLabelPrinted'],
  []
);
