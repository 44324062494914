import { IHelperService } from 'typings/helper';
import { IUserSession } from 'typings/user-session';
import { IReturnService } from 'typings/return';
import { ShipmentCourierPaymentRecipient } from 'typings/shipment';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { PrintingOptionsService } from '@client/src/global/services/printing-options/printing-options.service';
import { getDateDifferenceByDays } from '@client/src/utils/date';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { hasUserDirectPrintEnabled } from '@/utils/general';
import { directPrintLabel } from '@/utils/printing_actions';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import style from './return-label.module.scss';
import template from './return-label.html?raw';

class ReturnLabel implements IComponentController {
  esShipment: any;

  style = style;
  busy = {
    sendingEmail: false,
  };
  returnEmailPayload = {
    email_address: '',
    bcc_sender: false,
  };
  type: 'outbound' | 'return' = 'outbound';
  showCopied = false;
  hasReturnsPageBetaAccess = false;

  static $inject = [
    '$state',
    '$timeout',
    '$translate',
    'HelperService',
    'MixpanelService',
    'UserSession',
    'ReturnService',
    'showShipmentModal',
    'PrintingOptionsService',
  ];

  constructor(
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private HelperService: IHelperService,
    private MixpanelService: MixpanelService,
    private UserSession: IUserSession,
    private ReturnService: IReturnService,
    private showShipmentModal: any,
    private PrintingOptionsService: PrintingOptionsService
  ) {}

  $onInit() {
    this.hasReturnsPageBetaAccess =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page;

    this.type = this.esShipment.return_shipments.length ? 'outbound' : 'return';

    this.returnEmailPayload.email_address = this.esShipment.email_address;
  }

  getReturnBadgeColor(paymentRecipient: ShipmentCourierPaymentRecipient): string {
    return paymentRecipient === 'EasyshipPayOnScan' ? 'dark' : '';
  }

  getReturnBadgeLabel(paymentRecipient: ShipmentCourierPaymentRecipient): string {
    return this.$translate.instant(
      `global.${paymentRecipient === 'EasyshipPayOnScan' ? 'pay-on-scan' : 'pre-paid'}`
    );
  }

  isLabelExpired(labelExpiryDate: string) {
    return getDateDifferenceByDays(new Date(), new Date(labelExpiryDate)) >= 0;
  }

  showAssociatedShipment(shipmentId: string) {
    /**
     * Avoid changing the URL when return page is enabled,
     * TODO: Remove the entire when decided to show the return page to all of the users
     */
    if (!this.hasReturnsPageBetaAccess) {
      this.$state.go('app.shipments', { shipment_id: shipmentId });
    }

    this.showShipmentModal.close();
    this.showShipmentModal.open(shipmentId, null);
  }

  onReturnLabelEmailInputChange(value: string | boolean) {
    if (typeof value === 'string') {
      this.returnEmailPayload.email_address = value;
    } else {
      this.returnEmailPayload.bcc_sender = value;
    }
  }

  sendReturnEmail() {
    this.busy.sendingEmail = true;

    this.MixpanelService.track('Returns - Return Shipment Modal - Send Email', {
      easyship_shipment_id: this.esShipment && this.esShipment.easyship_shipment_id,
    });

    this.ReturnService.sendEmail(this.esShipment.id, this.returnEmailPayload)
      .then((data) => {
        this.esShipment.consignee_notified_at = data.consignee_notified_at;
        toastSuccess(this.$translate.instant('return-modal.notifications.success'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.sendingEmail = false;
      });
  }

  copyLabelLink() {
    this.showCopied = true;

    this.$timeout(() => {
      this.showCopied = false;
    }, 1000);

    this.HelperService.copyToClipboard(this.esShipment.return_label_url);
    this.MixpanelService.track('Returns - Return Shipment Modal - Copy Link', {
      easyship_shipment_id: this.esShipment && this.esShipment.easyship_shipment_id,
    });
  }

  printLabel() {
    this.showShipmentModal.close();
    const fnBrowserPrint = () => {
      this.PrintingOptionsService.showPrintPreview = true;
      this.PrintingOptionsService.previewDocuments = [
        {
          format: this.HelperService.convertQueryParamsToJson(this.esShipment.return_label_url)
            .page_size,
          name: 'Label',
          url: this.esShipment.return_label_url,
        },
      ];
    };

    if (hasUserDirectPrintEnabled()) {
      directPrintLabel(
        this.esShipment.id,
        MixpanelEventContext.MANAGE_SHIPMENT,
        fnBrowserPrint.bind(this)
      ).then();
    } else fnBrowserPrint();
  }
}

const ReturnLabelComponent: ng.IComponentOptions = {
  controller: ReturnLabel,
  template,
  bindings: {
    esShipment: '<',
  },
};

export { ReturnLabelComponent };
