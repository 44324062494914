import { ShipmentItem } from '@/api/shipments/types';
import { useCallback, useEffect, useState } from 'react';
import { useShipmentUpdateMutation } from '@/hooks/mutations/useShipmentUpdateMutation';
import { snakeToCamelCase } from '@/utils/snakeCamelTransform';
import { ErrorCode } from '@/types/api';
import { toastError } from '@/components/Toastify';
import { angularRefreshCheckout } from '@/utils/angular';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { KEY_CURRENT_SHIPMENT_SIMPLE_DOMESTIC_FLOW } from '@/constants/tanstack';

const useProcessedShipment = () => {
  return useQuery<ShipmentItem | null>([KEY_CURRENT_SHIPMENT_SIMPLE_DOMESTIC_FLOW], {
    initialData: null,
    enabled: false,
    cacheTime: 0,
  });
};

export const useUpdateShipment = (shipment?: ShipmentItem) => {
  const [currentShipment, setShipment] = useState(shipment);
  const [isUpdating, setIsUpdating] = useState(false);
  const shipmentId = currentShipment?.id;
  const { mutateAsync, abortMutation } = useShipmentUpdateMutation();

  const queryClient = useQueryClient();

  useProcessedShipment();

  useEffect(() => {
    return () => {
      queryClient.removeQueries([KEY_CURRENT_SHIPMENT_SIMPLE_DOMESTIC_FLOW]);
    };
  }, [queryClient]);

  useEffect(() => {
    queryClient.setQueriesData(
      [KEY_CURRENT_SHIPMENT_SIMPLE_DOMESTIC_FLOW],
      currentShipment || null
    );
  }, [currentShipment, queryClient]);

  useEffect(() => {
    if (shipment) setShipment(shipment);
  }, [shipment]);

  const updateShipment = useCallback(
    async (shipmentValue: number | null) => {
      if (shipmentId) {
        try {
          abortMutation();
          setIsUpdating(true);
          const data = await mutateAsync({
            shipmentId,
            option: {
              calculateRates: true,
              preserveCourier: true,
              validateAddress: false,
              userInputsOverAutomations: true,
              exceptIncotermsAndInsurance: true,
            },
            shipment: {
              totalCustomsValueUserInput: shipmentValue,
            },
          });
          setShipment(snakeToCamelCase(data.shipment));
          await Promise.all([angularRefreshCheckout(shipmentId)]);
        } catch (e) {
          if (e.code !== ErrorCode.CANCELED)
            toastError(e.response?.data?.errors?.join(',') || e.message);
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [abortMutation, mutateAsync, shipmentId, setIsUpdating]
  );

  return {
    updateShipment,
    shipment: currentShipment,
    isUpdating,
  };
};
