import React, { lazy, Suspense } from 'react';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';

const NotificationSettingsPage = lazy(() => import('@/pages/settings/notifications'));

function WrappedPage() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <NotificationSettingsPage />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularNotificationSettingsPage = react2angular(WrappedPage);
