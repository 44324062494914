import { useGateways } from '@app/contexts/providers/GatewayProvider';
import { useMutation } from '@tanstack/react-query';
import { IUpdateCourierInfoParams } from '@typings/courier';

export default function useUpdateCourierInfoMutation() {
  const { courierGateway } = useGateways();

  return useMutation({
    mutationFn: (payload: IUpdateCourierInfoParams) => courierGateway.updateCourierInfo(payload),
  });
}
