import { toastError, toastSuccess } from '@app/components/Toastify';
import { useRedeemVoucherMutation } from '@app/hooks/mutations/company/useRedeemVoucherMutation';
import { useGetUpdateStatusQuery } from '@app/hooks/queries/company/useGetUpdateStatusQuery';
import {
  PromoCodeFormData,
  PromoCodeFormProps,
} from '@app/pages/payment-method/components/forms/PromoCodeForm/types';
import { Button, Input } from 'easyship-components';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type FormField = keyof PromoCodeFormData;
type FieldErrors = Partial<Record<FormField, string>>;

export function PromoCodeForm({ defaultData, onSubmitSuccess }: PromoCodeFormProps) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLFormElement>(null);

  const { mutateAsync: redeemVoucherMutateAsync, isLoading } = useRedeemVoucherMutation();

  const { isFetching: isUpdatingStatus } = useGetUpdateStatusQuery({
    enabled: false,
  });

  function validateCode(code: string): string | null {
    return !code?.length ? 'The voucher code is empty' : null;
  }

  function isFormValid(formData: FormData): boolean {
    const codeError = validateCode(formData.get('code')?.toString() ?? '');
    const newErrors: FieldErrors = {
      code: codeError ?? undefined,
    };
    return Object.values(newErrors).every((value) => value === undefined);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!ref.current) return;
    const formData = new FormData(ref.current);

    if (isFormValid(formData)) {
      const payload: PromoCodeFormData = {
        code: formData.get('code')?.toString().trim() ?? '',
      };
      redeemVoucherMutateAsync({
        payload,
      })
        .then((res) => {
          onSubmitSuccess?.();

          if (res.unit === 'amount') {
            toastSuccess(
              formatMessage(
                { id: 'account.payment.notifications.voucher-redeemed-amount' },
                { figure: res.figure.toString() }
              )
            );
          } else if (res.unit === 'percentage') {
            toastSuccess(
              formatMessage(
                { id: 'account.payment.notifications.voucher-redeemed-percentage' },
                { code: res.voucher.code }
              )
            );
          } else {
            toastSuccess(formatMessage({ id: 'account.payment.notifications.voucher-redeemed' }));
          }
        })
        .catch((err) => {
          if (err && typeof err === 'object') {
            toastError(err.data.message);
          } else {
            toastError(formatMessage({ id: 'toast.default-error' }));
          }
        });
    } else {
      toastError('The voucher code is empty');
    }
  }

  return (
    <form ref={ref} onSubmit={handleSubmit} className="flex items-center justify-between w-full">
      <div className="w-2/5">
        <Input
          name="code"
          defaultValue={defaultData?.code ?? ''}
          label={<FormattedMessage id="account.payment.promo-code.heading" />}
        />
      </div>
      <Button
        loading={isLoading || isUpdatingStatus}
        disabled={isLoading || isUpdatingStatus}
        type="submit"
      >
        <FormattedMessage id="account.payment.promo-code.redeem" />
      </Button>
    </form>
  );
}
