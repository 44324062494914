import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { FormattedMessage, useIntl } from 'react-intl';

import { Input, Select } from 'easyship-components';
import { useUserSession } from '@/contexts/providers/UserSessionProvider';
import useCurrencies from '@/hooks/queries/useCurrencies';
import DatePicker from '@/components/DatePicker';

function InvoiceForm() {
  const { formatMessage } = useIntl();
  const { data: currencies = [] } = useCurrencies();
  const {
    company: { currency },
  } = useUserSession();
  const oldestInvoiceDateAllowed = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 180);
    return date;
  }, []);

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <Controller
          name="number"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label={<FormattedMessage id="courier.connect.field.invoice-number.label" />}
              status={fieldState.error ? 'error' : 'default'}
              placeholder="123456789"
            />
          )}
        />
        <Controller
          name="date"
          defaultValue={new Date()}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <div>
              <span className="text-base">
                <FormattedMessage id="courier.connect.field.invoice-date.label" />
              </span>
              <DatePicker
                {...field}
                disableToolbar
                keyboardIcon={null}
                minDate={oldestInvoiceDateAllowed}
                maxDate={new Date()}
                error={!!fieldState.error}
                placeholder={formatMessage({
                  id: 'datepicker-placeholder.select-date',
                })}
              />
            </div>
          )}
        />
      </div>
      <div className="grid grid-cols-4 mt-4">
        <Controller
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              label={<FormattedMessage id="global.currency" />}
              onChange={(value) => field.onChange(value)}
              options={currencies.map((currency) => ({
                label: currency.code,
                value: currency.code,
              }))}
              status={fieldState.error ? 'error' : 'default'}
              placeholder="Choose one"
              position="None"
            />
          )}
          defaultValue={currency || 'USD'}
          name="currency"
        />
        <Controller
          rules={{ required: true, min: 0.01 }}
          render={({ field, fieldState }) => (
            <Input
              type="number"
              position="Trail"
              step={0.01}
              label={<FormattedMessage id="courier.connect.field.invoice-amount.label" />}
              {...field}
              onChange={(e) => field.onChange(e.target.value)}
              status={fieldState.error ? 'error' : 'default'}
            />
          )}
          name="amount"
        />
      </div>
    </div>
  );
}

export default InvoiceForm;
