import axios from 'axios';
import { API } from '@/constants/api';
import { camelToSnakeCase } from '@/utils/snakeCamelTransform';
import { DirectPrintProps } from './types';

export const directPrintRequest = async ({ companyId, ...props }: DirectPrintProps) =>
  (
    await axios.post(`${API.endpoint}/companies/${companyId}/direct_prints`, {
      direct_print: {
        ...camelToSnakeCase(props),
      },
    })
  )?.data;
