import { toastError } from '@client/core/components/react/Toastify';
import { FEATURE_KEY } from '@client/data/subscription';
import dayjs from '@client/core/plugins/dayjs';
import { isUpsDapAllowedCountry } from '@client/src/create-shipments/utils/additionalServices';
import { CourierLogo } from '@client/data/courier';
import template from './shipment-information.html?raw';
import style from './shipment-information.module.scss';

const EXTRA_COVER = 'australia_post_extra_cover';
const SIGNATURE_ON_DELIVERY = 'australia_post_signature_on_delivery';
const couriersSupportAdditionalService = [CourierLogo.Fedex, CourierLogo.Ups, CourierLogo.MyPost];
const MANDATORY = 'mandatory';

const courierDetailsMap = {
  [CourierLogo.Ups]: {
    name: 'UPS®️ Optional Services',
    value: 'ups-services',
  },
  [CourierLogo.Fedex]: {
    name: 'FedEx Optional Services',
    value: 'fedex-services',
  },
  [CourierLogo.MyPost]: {
    name: 'Australia Post MyPost Business',
    value: 'mypost-business-services',
  },
};

class ShipmentInformationController {
  static $inject = [
    '$q',
    '$state',
    'API',
    'UserSession',
    'PlatformService',
    'CourierAccounts',
    'BrokersService',
    'SubscriptionService',
    'AddressService',
    'RestrictionsService',
    'DestinationAddressesService',
  ];

  constructor(
    $q,
    $state,
    API,
    UserSession,
    PlatformService,
    CourierAccounts,
    BrokersService,
    SubscriptionService,
    AddressService,
    RestrictionsService,
    DestinationAddressesService
  ) {
    this.style = style;
    this.$q = $q;
    this.$state = $state;
    this.API = API;
    this.UserSession = UserSession;
    this.PlatformService = PlatformService;
    this.CourierAccounts = CourierAccounts;
    this.BrokersService = BrokersService;
    this.SubscriptionService = SubscriptionService;
    this.AddressService = AddressService;
    this.RestrictionsService = RestrictionsService;
    this.hasAuMyPostBusinessExtraCover = false;
    this.DestinationAddressesService = DestinationAddressesService;
    this.handleSimplifiedDomesticFieldChange = this.handleSimplifiedDomesticFieldChange.bind(this);
    this.busy = {
      gettingCourierDetails: false,
    };
    this.showModal = {
      upgrade: false,
      enterpriseCall: false,
    };
    this.platformNames = [];
    this.incoterms = false;
    this.brokers = [];
    this.additionalServicesOptions = [
      {
        name: 'None',
        value: 'none',
      },
    ];
    [this.additionalServiceChosen] = this.additionalServicesOptions;
    this.showAdditionalServices = false;
    this.hasCompanyUps = false;
    this.hasCompanyFedex = false;
    this.hasCompanyAuPostMyPostBusiness = false;
    this.defaultAccountNumber = '';
    this.isIncludeReturnLabelVisible = false;
    this.showCheckBoxes = null;
    this.isIncludeReturnLabelUsable = false;
    this.fixedPriceAmountInputSettings = {
      maxDecimals: 2,
      allowNegative: false,
    };
    this.currency = 'AUD';
  }

  $onInit() {
    this.shipment = this.shipment || {};

    if (!this.shipment.additional_services) {
      this.shipment.additional_services = [];
    }

    if (!this.shipment.order_data) {
      this.shipment.order_data = {};
    }

    if (this.shipment.incoterms) {
      this.incoterms = this.shipment.incoterms !== 'DDP';
    }

    if (!this.shipment.metadata) {
      this.shipment.metadata = {};
    }

    if (this.UserSession.isLuxUser()) {
      this.DestinationAddressesService.resetLuxotticaShipmentMetadata(this.shipment);

      this.DestinationAddressesService.isOtherFlow() ||
      this.DestinationAddressesService.isStoreToVendorFlow()
        ? this.setSelectedLuxotticaCommodity('other')
        : this.setSelectedLuxotticaCommodity(this.DestinationAddressesService.defaultCommodity);

      this.shipment.metadata.reason = this.DestinationAddressesService.selectedLuxotticaFlow;

      if (this.DestinationAddressesService.isFtcFlow()) {
        const todayOnISO8601 = dayjs().format();
        this.shipment.metadata.rx_date = todayOnISO8601;
      }
    }

    // TEMPORAL

    this.platformNames = this.PlatformService.getPlatformNames();

    this.shipment.platform_name =
      this.shipment.platform_name || this.PlatformService.getDefaultPlatform();

    const amazonPlatformIds = [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212];
    this.isProtectedOrder =
      this.shipment.store &&
      this.shipment.store.platform_id &&
      amazonPlatformIds.includes(this.shipment.store.platform_id);
    this.hasCompanyFedex = this.CourierAccounts.hasCompanyFedex();

    this._getCouriersServicesDetails();
    this._getAddressDetails();
    this._checkPayOnScanSupport();

    this.hasAuMyPostBusinessExtraCover = this.shipment.additional_services.includes(EXTRA_COVER);
    this.hasAuMyPostBusinessSignatureOnDelivery =
      this.shipment.additional_services.includes(SIGNATURE_ON_DELIVERY);
  }

  onChangeMyPostAdditionalServices(value, selectedService) {
    if (
      this.shipment.additional_services.length > 0 &&
      this.shipment.additional_services.includes(selectedService)
    ) {
      this.shipment.additional_services = this.shipment.additional_services.filter(
        (additionalService) => additionalService !== selectedService
      );
    } else {
      this.shipment.additional_services.push(selectedService);
    }

    if (selectedService === EXTRA_COVER) {
      this.hasAuMyPostBusinessExtraCover = value;
      !value && this.resetExtraCoverValue();
    }

    if (selectedService === SIGNATURE_ON_DELIVERY) {
      this.hasAuMyPostBusinessSignatureOnDelivery = value;
    }
  }

  handleSimplifiedDomesticFieldChange(key, value) {
    if (key === 'hasLiquid') {
      this.shipment.contains_liquids_user_input = value;
    }
    if (key === 'hasBattery') {
      this.shipment.contains_battery_pi967_user_input = value;
    }
  }

  resetExtraCoverValue() {
    this.shipment.order_data.australia_post_extra_cover = null;
  }

  currentSubscription() {
    return this.SubscriptionService.currentSubscription;
  }

  onInputChange(value, field) {
    switch (field) {
      case 'incoterms': {
        this.incoterms = value;

        this.shipment.order_data.fedex_duties_payment_type = value ? 'RECIPIENT' : 'SENDER';
        this.shipment[field] = value ? 'DDU' : 'DDP';
        break;
      }
      case 'order_tag_list': {
        this.shipment[field] = value;

        break;
      }
      case 'broker_id': {
        this.shipment[field] = value === 'none' ? null : value;

        break;
      }
      case 'rx_code':
      case 'rx_date':
      case 'amount_customer_charged':
      case 'comments':
      case 'authorization_number':
      case 'commodity':
      case 'inventory_movement_reference':
      case 'rx_jobs_type':
      case 'po_number':
      case 'other': {
        this.shipment.metadata[field] = value;

        break;
      }
      case EXTRA_COVER: {
        this.shipment.order_data.australia_post_extra_cover = value;
        break;
      }
      default: {
        this.shipment[field] = value;

        break;
      }
    }
    this.onChange({ value: this.shipment });
  }

  onBillingOptionsChange(value) {
    this.shipment.order_data = value;

    if (['RECIPIENT', 'THIRD_PARTY'].includes(value.fedex_duties_payment_type)) {
      this.incoterms = true;
      this.shipment.incoterms = 'DDU';
    } else {
      this.incoterms = false;
      this.shipment.incoterms = 'DDP';
    }
  }

  onAdditionalServicesChange(option) {
    this.additionalServiceChosen = option;
    if (this.additionalServiceChosen.value !== 'none') {
      // Do not remove none service values
      const {
        fedex_shipping_charges_payment_type,
        fedex_shipping_charges_account_number,
        fedex_duties_payment_type,
        fedex_duties_payment_account_number,
      } = this.shipment.order_data;

      this.shipment.additional_services = [];
      this.shipment.order_data = {
        fedex_shipping_charges_payment_type,
        fedex_shipping_charges_account_number,
        fedex_duties_payment_type,
        fedex_duties_payment_account_number,
      };
      this.shipment.broker_id = null;
      this.shipment.eei_reference = null;
    }
  }

  onBatteryServicesChange(value) {
    this.shipment.additional_services = value;
  }

  onB13AFilingChange(value) {
    this.shipment.order_data = value;
  }

  isDomesticShipment() {
    const { origin_country_id, origin_address, destination_country_id, destination_country } =
      this.shipment;
    const originCountryId = origin_country_id || (origin_address && origin_address.country_id);
    const destinationCountryId =
      destination_country_id || (destination_country && destination_country.id);

    return destinationCountryId === originCountryId;
  }

  suggestedPlanId() {
    if (
      this.SubscriptionService.isCurrentSubscriptionLoaded &&
      this.SubscriptionService.isPlansDetailLoaded
    ) {
      return this.SubscriptionService.getSuggestedPlanIdByFeatureKey(FEATURE_KEY.AutomatedReturn);
    }

    return 0;
  }

  isPlanBadgeVisible(featureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  checkSubscriptionStatus() {
    if (this.isIncludeReturnLabelUsable) return;

    if (this.UserSession.isCompanyEfulfilment()) {
      toastError(this.$translate.instant('shipment-info.pay-on-scan.eful-error'));
      return;
    }

    if (this.currentSubscription().plan.id === this.SubscriptionService.planIds.Enterprise) {
      this.showModal.enterpriseCall = true;
    } else {
      this.showModal.upgrade = true;
    }
  }

  isShippingFromCA() {
    return this.shipment.origin_address && this.shipment.origin_address.country_id === 39;
  }

  isUpsServicesSelected() {
    return this.additionalServiceChosen.value === courierDetailsMap[CourierLogo.Ups].value;
  }

  isFedexServicesSelected() {
    return this.additionalServiceChosen.value === courierDetailsMap[CourierLogo.Fedex].value;
  }

  getBackendError(field) {
    if (!this.errorLogs) return '';

    return this.errorLogs[field];
  }

  showAuPostMyPostAdditionServices() {
    return (
      this.hasCompanyAuPostMyPostBusiness &&
      this.additionalServiceChosen.value === courierDetailsMap[CourierLogo.MyPost].value
    );
  }

  showLuxotticaCommentsField() {
    return (
      this.DestinationAddressesService.isReturnFlow() ||
      this.DestinationAddressesService.isDamageFlow() ||
      this.DestinationAddressesService.isFtcFlow() ||
      this.DestinationAddressesService.isStoreToStoreFlow() ||
      this.DestinationAddressesService.isStoreToCustomerFlow() ||
      this.DestinationAddressesService.isStoreToVendorFlow() ||
      this.DestinationAddressesService.isOtherFlow()
    );
  }

  showLuxotticaAuthorizationNumberField() {
    return (
      this.DestinationAddressesService.isReturnFlow() ||
      this.DestinationAddressesService.isDamageFlow()
    );
  }

  showLuxotticaRxCode() {
    return (
      this.DestinationAddressesService.isFtcFlow() ||
      (this.DestinationAddressesService.isStoreToStoreFlow() &&
        this.DestinationAddressesService.selectedLuxotticaCommodity === 'rxJobs') ||
      (this.DestinationAddressesService.isStoreToCustomerFlow() &&
        this.DestinationAddressesService.selectedLuxotticaCommodity === 'rxOrder')
    );
  }

  showLuxotticaRxDate() {
    return this.DestinationAddressesService.isFtcFlow();
  }

  showLuxotticaCommoditiesDropdown() {
    return (
      this.DestinationAddressesService.isStoreToStoreFlow() ||
      this.DestinationAddressesService.isStoreToCustomerFlow()
    );
  }

  showLuxotticaCommoditiesField() {
    return this.DestinationAddressesService.isStoreToVendorFlow();
  }

  showLuxotticaPoNumber() {
    return (
      this.DestinationAddressesService.isStoreToCustomerFlow() &&
      (this.DestinationAddressesService.selectedLuxotticaCommodity === 'frames' ||
        this.DestinationAddressesService.selectedLuxotticaCommodity === 'contactLenses')
    );
  }

  showLuxotticaInventoryMovementReference() {
    return (
      this.DestinationAddressesService.isStoreToStoreFlow() &&
      this.DestinationAddressesService.selectedLuxotticaCommodity === 'frames'
    );
  }

  showLuxotticaRxJobsType() {
    const shouldShow =
      this.DestinationAddressesService.isStoreToStoreFlow() &&
      this.DestinationAddressesService.selectedLuxotticaCommodity === 'rxJobs';

    if (shouldShow && !this.shipment.metadata.rx_jobs_type) {
      this.shipment.metadata.rx_jobs_type = 'hub-spoke';
    }

    return shouldShow;
  }

  showLuxotticaOther() {
    return (
      ((this.DestinationAddressesService.isStoreToStoreFlow() ||
        this.DestinationAddressesService.isStoreToCustomerFlow() ||
        this.DestinationAddressesService.isStoreToVendorFlow()) &&
        this.DestinationAddressesService.selectedLuxotticaCommodity === 'other') ||
      this.DestinationAddressesService.isOtherFlow()
    );
  }

  showLuxotticaAmountCustomerCharged() {
    return this.DestinationAddressesService.isStoreToCustomerFlow();
  }

  setSelectedLuxotticaCommodity(string) {
    this.DestinationAddressesService.setSelectedLuxotticaCommodity(string);
    this.setSelectedLuxotticaFlowCommodityTag();
    this.onInputChange(string, 'commodity');
  }

  setSelectedLuxotticaFlowCommodityTag() {
    this.shipment.order_tag_list = [
      this.DestinationAddressesService.getSelectedLuxotticaFlowCommodityTag(),
    ];
  }

  _getDestinationId() {
    const { destination_country_id, destination_country } = this.shipment;

    return destination_country_id || (destination_country && destination_country.id);
  }

  _getCouriersServicesDetails() {
    if (!this.shipment.courier) {
      return;
    }

    /** Only show courier services details if:
     *  - Basic Shipments
     *  - Advanced Shipments - Manual Input
     *  - Advanced Shipments - Edit Shipment & specific courier is selected eg: FedEx or UPS
     */
    const isBasicOrManualShipment = ['add-shipment', 'manual-input'].includes(
      this.$state.current.name
    );
    const hasAdditionalService = couriersSupportAdditionalService.includes(
      this.shipment.courier.logo_url
    );

    if (!isBasicOrManualShipment && !hasAdditionalService) {
      return;
    }

    this.busy.gettingCourierDetails = true;

    this.CourierAccounts.getActiveCourierAccounts()
      .then(() => {
        const isUpsDapCountry = isUpsDapAllowedCountry(this.shipment.origin_country.alpha2);

        this.hasCompanyUps = this.CourierAccounts.hasCompanyUps() || isUpsDapCountry;
        this.hasCompanyFedex = this.CourierAccounts.hasCompanyFedex();
        this.hasCompanyAuPostMyPostBusiness = this.CourierAccounts.hasAuPostMyPostBusiness();

        this.showAdditionalServices =
          this.hasCompanyUps || this.hasCompanyFedex || this.hasCompanyAuPostMyPostBusiness;

        if (this.hasCompanyUps) {
          this._setCourierDetails(CourierLogo.Ups);
        }
        if (this.hasCompanyFedex) {
          this._setCourierDetails(CourierLogo.Fedex);
          this._setFedexCourierDetails();
        }
        if (this.hasCompanyAuPostMyPostBusiness) {
          this._setCourierDetails(CourierLogo.MyPost);
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.gettingCourierDetails = false;
      });
  }

  _setCourierDetails(courier) {
    this.additionalServicesOptions.push(courierDetailsMap[courier]);
    const selectedOption = this.additionalServicesOptions.find((i) =>
      i.value.toLowerCase().includes(this.shipment.courier.logo_url)
    );

    const isSetSelectedOption =
      [CourierLogo.Fedex, CourierLogo.Ups].includes(this.shipment?.courier.logo_url) ||
      this.hasAuMyPostBusinessExtraCover ||
      this.hasAuMyPostBusinessSignatureOnDelivery;

    if (selectedOption && isSetSelectedOption) {
      this.additionalServiceChosen = selectedOption;
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.additionalServiceChosen = this.additionalServicesOptions[0];
    }
  }

  _setFedexCourierDetails() {
    this.shipment.order_data.fedex_duties_payment_type = this.incoterms ? 'RECIPIENT' : 'SENDER';

    const companyFedexAccount = this.CourierAccounts.getFedExActiveAccount();

    this.CourierAccounts.getCourierInfo({ id: companyFedexAccount.id })
      .then((data) => {
        this.defaultAccountNumber = data.account.account_number;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });

    this.BrokersService.getBrokers({ courier_account_id: companyFedexAccount.id })
      .then((data) => {
        this.brokers = data.brokers.map((broker) => {
          return {
            ...broker,
            display_text: `${broker.contact_name}, ${broker.company_name}, ${broker.address_line_1}, ${broker.city}, ${broker.state}`,
          };
        });

        this.brokers = [
          {
            id: 'none',
            display_text: 'None',
          },
          ...this.brokers,
        ];
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  _getAddressDetails() {
    if (this.shipment.origin_address_id && !this.shipment.origin_address) {
      this.AddressService.queryById(this.shipment.origin_address_id).then((address) => {
        this.shipment.origin_address = address;
      });
    }
  }

  checkShowHScodeBaseOnRestrictionApi = (shipment) => {
    const { input_fields } = shipment;
    return (
      input_fields.hs_code.require === MANDATORY ||
      input_fields.item_category_id.require === MANDATORY
    );
  };

  _checkPayOnScanSupport() {
    this.RestrictionsService.getShipmentRestrictions({
      origin_country_id: this.shipment.origin_country_id,
      destination_country_id: this.shipment.destination_country_id,
      origin_postal_code: this.shipment.origin_address.postal_code,
      destination_postal_code: this.shipment.postal_code,
    })
      .then(({ shipment }) => {
        this.isIncludeReturnLabelVisible = shipment.features.automated_return.is_visible;
        this.isIncludeReturnLabelUsable = shipment.features.automated_return.is_usable;
        this.showCheckBoxes = !this.checkShowHScodeBaseOnRestrictionApi(shipment);
      })
      .catch(() => {
        this.isIncludeReturnLabelVisible = false;
        this.isIncludeReturnLabelUsable = false;
      });
  }

  getRequireSignatureError() {
    return (
      this.shipment.order_data.australia_post_extra_cover > 500 &&
      this.hasAuMyPostBusinessExtraCover &&
      !this.hasAuMyPostBusinessSignatureOnDelivery
    );
  }

  get isLuxUser() {
    return this.UserSession.isLuxUser();
  }
}

const ShipmentInformationComponent = {
  template,
  controller: ShipmentInformationController,
  bindings: {
    shipment: '<',
    onChange: '&',
    errorLogs: '<',
  },
};

export { ShipmentInformationComponent };
