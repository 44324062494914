import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IApiPrepareCheckoutResponse, ICheckoutService } from '@typings/checkout';
import angular from 'angular';
import { snakeToCamelCase } from '@/utils/snakeCamelTransform';
import { ApiPrepareCheckoutResponse, GET_CHECKOUT_PREPARE_QUERY_KEY } from './types';

const GET_CHECKOUT_PREPARE_QUERY_KEY_SNAKE = 'GET_CHECKOUT_PREPARE_QUERY_KEY_SNAKE';
const GET_CHECKOUT_PREPARE_QUERY_KEY_NO_SNAKE = 'GET_CHECKOUT_PREPARE_QUERY_KEY_NO_SNAKE';

export function useGetCheckoutPrepareQuery(): UseQueryResult<ApiPrepareCheckoutResponse, Error> {
  return useQuery({
    queryKey: [GET_CHECKOUT_PREPARE_QUERY_KEY_SNAKE, GET_CHECKOUT_PREPARE_QUERY_KEY],
    queryFn: () => {
      const service: ICheckoutService = angular
        .element(document.body)
        .injector()
        .get('CheckoutService');
      const data = service?.purePreparedCheckoutData || {};
      return snakeToCamelCase(data);
    },
  });
}

export function useGetCheckoutPrepareQuerySnakeCase(): UseQueryResult<
  IApiPrepareCheckoutResponse,
  Error
> {
  return useQuery({
    queryKey: [GET_CHECKOUT_PREPARE_QUERY_KEY_NO_SNAKE, GET_CHECKOUT_PREPARE_QUERY_KEY],
    queryFn: () => {
      const service: ICheckoutService = angular
        .element(document.body)
        .injector()
        .get('CheckoutService');
      return service?.purePreparedCheckoutData || {};
    },
  });
}
