import { IFeature, IPlan, PlanPricing, IUserPlan, PlanList } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import {
  BillingInterval,
  DefinedSubscriptionPlanId,
  FeatureKey,
  FEATURE_KEY,
} from '@client/data/subscription';

import { isPresent } from '@client/src/utils/type-guards';
import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './plan-list.html?raw';
import style from './plan-list.module.scss';

class PlanListController implements IComponentController {
  style = style;
  translations: angular.translate.ITranslationTable = {};
  esSelectedId = DefinedSubscriptionPlanId.Plus;
  esBillingPeriod: BillingInterval = BillingInterval.Month;
  selectedPlan?: IPlan;
  pricing?: PlanPricing;
  currency?: string;
  intervalTranslationId?: string;
  currentSubscribedPlan?: IUserPlan | PlanList;
  countryId?: number;
  highlightedKeys: FeatureKey[] = [
    FEATURE_KEY.ShipmentsPerMonth,
    FEATURE_KEY.Lyoc,
    FEATURE_KEY.LyocLabellingFee,
    FEATURE_KEY.TeamMembers,
    FEATURE_KEY.AutoSync,
    FEATURE_KEY.BrandedTrackingExperience,
    FEATURE_KEY.PhoneSupport,
    FEATURE_KEY.CustomizeShipmentTable,
    FEATURE_KEY.DirectPrint,
    FEATURE_KEY.UserAccessManagement,
    FEATURE_KEY.PickAndPack,
    FEATURE_KEY.EcommerceIntegrations,
    FEATURE_KEY.ShippingSolutions,
    FEATURE_KEY.Analytics,
    FEATURE_KEY.TaxAndDuty,
    FEATURE_KEY.ChatSupport,
    FEATURE_KEY.EmailSupport,
    FEATURE_KEY.MultiBoxShipments,
    FEATURE_KEY.PickList,
    FEATURE_KEY.ExportShipments,
    FEATURE_KEY.PrintingFormat4x6,
  ];
  featuresGained: IFeature[] = [];
  featuresRetained: IFeature[] = [];
  intro = '';

  static $inject = ['$filter', 'UserSession', 'SubscriptionService', '$translate'];
  constructor(
    private $filter: ng.IFilterFunction,
    private UserSession: IUserSession,
    private SubscriptionService: SubscriptionService,
    private $translate: angular.translate.ITranslateService
  ) {}

  async $onInit() {
    this.currency = this.UserSession.getCompanyCurrency();

    const { country_id: countryId } = this.UserSession.company;
    const { plans, features } = await this.SubscriptionService.fetchPlansDetail({
      country_id: countryId,
    });

    const isInTrialShouldUseFreePlanAsCurrentPlan =
      this.SubscriptionService.currentSubscriptionPeriod === 'TRIAL';
    const currentPlan = this.SubscriptionService.currentSubscription?.plan;
    const FreePlan = plans?.find(({ id }) => id === DefinedSubscriptionPlanId.Free);

    this.currentSubscribedPlan = isInTrialShouldUseFreePlanAsCurrentPlan ? FreePlan : currentPlan;

    this.selectedPlan = plans?.find(({ id }) => id === this.esSelectedId);

    this.intro = this.$translate.instant(
      `subscription.upgrade-modal.gained-intro${
        this.SubscriptionService.currentSubscriptionPeriod === 'TRIAL' ? '-trial' : ''
      }`,
      {
        plan: this.selectedPlan?.name,
      }
    );

    this.featuresRetained = this._getRetainedFeatures(features);
    this.featuresGained = this._getGainedFeatures(features);

    this.pricing = this.SubscriptionService.getPrice(this.esSelectedId, this.esBillingPeriod);
  }

  $onChanges(): void {
    this.pricing = this.SubscriptionService.getPrice(this.esSelectedId, this.esBillingPeriod);
    this.intervalTranslationId =
      this.esBillingPeriod === BillingInterval.Year ? 'global.year' : 'global.month-abbr';
  }

  formatCurrency(value: number, options?: Intl.NumberFormatOptions): string {
    return this.$filter('intlCurrency')(value, this.currency, options);
  }

  private _getHighlightedFeatures(features: IFeature[]): IFeature[] {
    const isCustomizeTableDisabled =
      !this.UserSession.getFeatureFlagsByFlagName('new_shipments_table');
    const isPickAndPackDisabled = !this.UserSession.getFeatureFlagsByFlagName('smb_pick_and_pack');
    const isDirectPrintDisabled = !this.UserSession.getFeatureFlagsByFlagName(
      'direct_print_node_feature'
    );
    return this.highlightedKeys
      .filter((includedKey: string) => {
        if (isCustomizeTableDisabled && includedKey === FEATURE_KEY.CustomizeShipmentTable) {
          return false;
        }
        if (isPickAndPackDisabled && includedKey === FEATURE_KEY.PickAndPack) {
          return false;
        }
        if (isDirectPrintDisabled && includedKey === FEATURE_KEY.DirectPrint) {
          return false;
        }
        return true;
      })
      .map((includedKey: string) => features.find(({ key }) => key === includedKey))
      .filter(isPresent)
      .filter((feature: IFeature) => {
        if (feature.key === FEATURE_KEY.LyocLabellingFee) {
          return !!this.selectedPlan && !!feature.data[this.selectedPlan.ranking];
        }
        return true;
      });
  }

  private _getRetainedFeatures(features: IFeature[]): IFeature[] {
    const currentSubscribedPlanRanking = this.currentSubscribedPlan?.ranking;
    const selectedPlanRanking = this.selectedPlan?.ranking;

    if (!currentSubscribedPlanRanking || !selectedPlanRanking) {
      return [];
    }

    return this._getHighlightedFeatures(features).filter((feature: IFeature): boolean => {
      const isAccessibleInCurrentPlan = !!feature.data[currentSubscribedPlanRanking];
      const isAccessibleInSelectedPlan = !!feature.data[selectedPlanRanking];
      const hasSameLimit =
        feature.data[currentSubscribedPlanRanking] === feature.data[selectedPlanRanking];

      return isAccessibleInCurrentPlan && isAccessibleInSelectedPlan && hasSameLimit;
    });
  }

  private _getGainedFeatures(features: IFeature[]): IFeature[] {
    const currentSubscribedPlanRanking = this.currentSubscribedPlan?.ranking;
    const selectedPlanRanking = this.selectedPlan?.ranking;

    if (!currentSubscribedPlanRanking || !selectedPlanRanking) {
      return [];
    }

    return this._getHighlightedFeatures(features).filter(
      (feature: IFeature): boolean =>
        feature.data[currentSubscribedPlanRanking] !==
        feature.data[selectedPlanRanking] /* Not in current plan */
    );
  }
}

const PlanListComponent: ng.IComponentOptions = {
  controller: PlanListController,
  template,
  bindings: {
    esSelectedId: '<',
    esSamePlan: '<',
    esBillingPeriod: '<',
    esEndOfTrial: '<',
  },
};

export { PlanListComponent };
