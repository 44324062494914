import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, WithLabel } from 'easyship-components';
import { twMerge } from 'tailwind-merge';
import { useUserSession } from '@/providers/UserSessionProvider';
import { useUpdateShipment } from '@/components/ShipmentTotalValueAndInsuranceFee/useUpdateShipment';
import { angularSetValueNotInsured } from '@/components/ShipmentTotalValueAndInsuranceFee/helpers';
import { useSimplifiedDomestic } from '@app/hooks/general/useSimplifiedDomestic';
import { ShipmentTotalValueAndInsuranceFeeProps } from './types';

type Props = {
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | null;
  insuranceFee?: number | string;
  isUpdating?: boolean;
  currency?: string;
  isValidationError?: boolean;
};

export const ShipmentTotalValueAndInsuranceFeeComponent = memo(
  ({
    className,
    onChange,
    value,
    insuranceFee,
    isUpdating,
    currency,
    isValidationError,
  }: Props) => {
    const { formatMessage } = useIntl();

    const LeadingIcon = useMemo(() => {
      // eslint-disable-next-line
      return function() {
        return <>{currency}&nbsp;</>;
      };
    }, [currency]);

    return (
      <div className={twMerge('flex flex-row items-center gap-3 no-arrows', className)}>
        <WithLabel
          value={formatMessage({ id: 'shipments.modal.shipment-details.total-shipment-value' })}
          className="w-[150px]"
        >
          <Input
            type="number"
            value={value || ''}
            min={0.01}
            placeholder="0.00"
            onChange={onChange}
            LeadingIcon={LeadingIcon}
            className="text-right"
            status={(isValidationError && 'error') || 'default'}
          />
        </WithLabel>
        <WithLabel
          value={formatMessage({ id: 'shipments.list.cost.insurance' })}
          className="w-[180px]"
        >
          <Input
            type="number"
            readOnly
            className="text-right"
            LeadingIcon={LeadingIcon}
            loading={isUpdating}
            value={(insuranceFee as string) || ''}
          />
        </WithLabel>
      </div>
    );
  }
);

const TIME_OUT_TRIGGER_UPDATE = 1000;

function ShipmentTotalValueAndInsuranceFeeInner({
  shipment: entryShipment,
  isAllInsured,
  className,
  isValidationError,
}: ShipmentTotalValueAndInsuranceFeeProps) {
  const { company: { currency } = { currency: '' } } = useUserSession();

  const { updateShipment, shipment, isUpdating } = useUpdateShipment(entryShipment);

  const [state, setState] = useState<string | null>(
    entryShipment?.totalCustomsValueUserInput as string
  );

  const thToSend = useRef<ReturnType<typeof setTimeout>>(
    0 as unknown as ReturnType<typeof setTimeout>
  );

  useEffect(() => {
    return () => {
      if (thToSend.current) clearTimeout(thToSend.current);
    };
  }, []);

  useEffect(() => {
    setState(shipment?.totalCustomsValueUserInput as string);
  }, [shipment?.totalCustomsValueUserInput]);

  const updateValue = useCallback(
    (value: string | null) => {
      angularSetValueNotInsured(value);
      setState((v) => {
        clearTimeout(thToSend.current);
        if (v === value) return v;
        thToSend.current = setTimeout(
          () => {
            updateShipment(value === null ? null : +value || 0).then();
          },
          value !== null ? TIME_OUT_TRIGGER_UPDATE : 0
        );
        return value;
      });
    },
    [updateShipment]
  );

  const onChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      updateValue(e?.target?.value || '');
    },
    [updateValue]
  );

  useEffect(() => {
    if (!isAllInsured) {
      updateValue(null);
    }
  }, [isAllInsured, updateValue]);

  const insuranceFee = shipment?.isInsured
    ? shipment?.insuranceFee
    : shipment?.insuranceFeeNotApplied;

  return isAllInsured ? (
    <ShipmentTotalValueAndInsuranceFeeComponent
      value={state}
      onChange={onChange}
      insuranceFee={insuranceFee}
      isUpdating={isUpdating}
      currency={shipment?.currency || currency}
      isValidationError={isValidationError}
      className={className}
    />
  ) : null;
}

export function ShipmentTotalValueAndInsuranceFee({
  shipmentId,
  ...rest
}: {
  shipmentId: string;
} & Omit<ShipmentTotalValueAndInsuranceFeeProps, 'shipment'>) {
  const { showSimplifiedDomesticFlow, shipment, missingUnitSellingPrice } =
    useSimplifiedDomestic(shipmentId);

  if (!showSimplifiedDomesticFlow || !missingUnitSellingPrice || !shipment) return null;

  return <ShipmentTotalValueAndInsuranceFeeInner shipment={shipment} {...rest} />;
}

export default ShipmentTotalValueAndInsuranceFee;
