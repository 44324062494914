import { useGetUserSessionQuery } from '@/hooks/queries/useGetUserSessionQuery';
import { UserSession } from '@/hooks/queries/useGetUserSessionQuery/types';
import { COMPANY_FEATURE_FLAGS } from '@/constants/company';
import { ValueOf } from '@/types/general';

export const useIsFeatureFlag = (flag: ValueOf<typeof COMPANY_FEATURE_FLAGS>) => {
  const { data } = useGetUserSessionQuery();
  const { company } = data || ({} as UserSession);

  if (!company?.featureFlags) return false;

  return !!company?.featureFlags?.[flag as keyof typeof company.featureFlags] as boolean;
};
