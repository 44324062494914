import { API } from '@app/constants/api';
import { CourierLogo } from '@app/constants/courier';
import {
  AccountType,
  CourierDetails,
  FedexFormValues,
  FedexVerificationMethodFormValues,
  InvoiceFormValues,
} from './fedex/forms/types';
import { CourierDetailsApgForm } from './apg/forms';

export const isSingleCountryCourier = (logoUrl: CourierLogo) => {
  return [
    CourierLogo.AustraliaPost,
    CourierLogo.AustraliaPostOnDemand,
    CourierLogo.StarTrack,
    CourierLogo.Sendle,
    CourierLogo.MyPost,
    CourierLogo.CanadaPost,
    CourierLogo.RoyalMail,
    CourierLogo.Passport,
  ].includes(logoUrl);
};

export const COURIER_SUPPORT_URLS: Partial<Record<CourierLogo, string>> = {
  [CourierLogo.Fedex]: '/hc/en-us/articles/360056947771-Link-Your-FedEx-Account',
  [CourierLogo.Dhl]: '/hc/en-us/articles/360039958872-Link-Your-DHL-Express-Account',
  [CourierLogo.AustraliaPost]: '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post',
  [CourierLogo.Ups]: '/hc/en-us/articles/360039959232-Link-Your-UPS-Account',
  [CourierLogo.StarTrack]: '/hc/en-us/',
  [CourierLogo.AustraliaPostOnDemand]:
    '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post',
  [CourierLogo.RoyalMail]: '/hc/en-us/articles/360046578311-Link-Your-Royal-Mail-Account',
  [CourierLogo.Sendle]: '/hc/en-us/articles/360035365152/',
  [CourierLogo.CanadaPost]: '/hc/en-us/articles/4407016235533-Link-Your-Canada-Post-Account',
  [CourierLogo.MyPost]:
    '/hc/en-us/articles/12379903974809-Link-Your-Australia-Post-Mypost-Business-Account',
  [CourierLogo.Passport]: '/hc/en-us/articles/23885130521625-Link-Your-Passport-Account',
} as const;

export const DEFAULT_SUPPORT_URL = '/hc/en-us';

export const getSupportPageUrl = (logoUrl: CourierLogo) => {
  const supportPath = COURIER_SUPPORT_URLS[logoUrl] || DEFAULT_SUPPORT_URL;
  return `${API.help}${supportPath}`;
};

export const showConnectCourierWarning = (logoUrl: CourierLogo) => {
  return [
    CourierLogo.Dhl,
    CourierLogo.DhlEcommerce,
    CourierLogo.Fedex,
    CourierLogo.FedexCrossBorder,
  ].includes(logoUrl);
};

export const AU_MYPOST_BUSINESS_AUTHORIZE_WEBSITE =
  'https://auspost.com.au/mypost-business/authorisation/authorize?response_type=code';
export const EASYSHIP_CLIENT_ID = 'EASYSHIP-7402';
export const AU_MYPOST_BUSINESS_STATE = 'test1234';
export const DASHBOARD_URL = `${API.dashboard}`;

export const getFirstNonEmptyObject = (
  ...objects: (
    | AccountType
    | FedexFormValues
    | InvoiceFormValues
    | FedexVerificationMethodFormValues
    | CourierDetails
    | CourierDetailsApgForm
    | undefined
  )[]
) => {
  return objects.find((obj) => obj && Object.keys(obj).length > 0) || objects[objects.length - 1];
};
