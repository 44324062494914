import { ICompanyService } from 'typings/company';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './ups-dap-agreement.html?raw';
import style from './ups-dap-agreement.module.scss';
import upsRateImageUS from './ups-rate-US.svg?svgo';
import upsRateImageGB from './ups-rate-GB.svg?svgo';
import upsRateImageDefault from './ups-rate-default.svg?svgo';
import upsRateImageNoPercentage from './ups-rate-no-percentage.svg?svgo';

const guardLinkTag = (link: string, chunk: string) => {
  return `<a href="${link}" target="_blank" rel="noopener">${chunk}</a>`;
};

// tac: Terms & Conditions
// ptac: Paperless Terms and Conditions
// ta: Technology Agreement
// pi: Prohibited Items
// hod: Hazardous materials or Dangerous goods
const linkMap = {
  DE: {
    tac: 'https://www.ups.com/de/en/support/shipping-support/legal-terms-conditions.page',
    hod: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/hazardous-materials-guide.page',
    pi: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
  GB: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_GB/terms_carriage_eur.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_GB/UTA.pdf',
    pi: 'https://www.ups.com/gb/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    ptac: 'https://www.ups.com/assets/resources/webcontent/en_GB/DAP-paperless-invoice-terms-conditions.pdf',
  },
  US: {
    tac: 'https://assets.easyship.com/app/courier-docs/ups-access-license-agreement.pdf',
    ta: 'https://www.ups.com/assets/resources/media/en_US/D15_US.pdf',
    pi: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
  CA: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_CA/terms_service_ca.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_CA/UTA.pdf',
    pi: 'https://www.ups.com/ca/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
  NL: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_GB/terms_carriage_nl.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_GB/UTA.pdf',
    pi: 'https://www.ups.com/nl/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    ptac: 'https://www.ups.com/assets/resources/webcontent/en_GB/DAP-paperless-invoice-terms-conditions.pdf',
  },
};

class UpsDapAgreement implements IComponentController {
  esOnRegistrationSuccess() {
    //
  }
  esOnAgree() {
    //
  }
  esIsLast = false;
  esCountry = '';
  esAddressId = '';

  style = style;
  busy = {
    registeringUpsSubAccounts: false,
  };
  hasAgreedToTerms = false;
  showErrorMessage = false;

  static $inject = ['CompanyService', 'UserSession', '$translate'];
  constructor(
    private CompanyService: ICompanyService,
    private UserSession: IUserSession,
    private $translate: angular.translate.ITranslateService
  ) {}

  toggleAgreementCheckbox() {
    this.hasAgreedToTerms = !this.hasAgreedToTerms;
  }

  next() {
    if (this.esIsLast) {
      this.accept();
    } else {
      this.esOnAgree();
    }
  }

  accept() {
    this.busy.registeringUpsSubAccounts = true;

    this.CompanyService.registerCourierSubAccounts('UPS', this.esAddressId)
      .then(() => {
        this.esOnRegistrationSuccess();
      })
      .catch(() => {
        // Do not show the actual error message as some of the error message is asking the user to
        // contact the UPS support and we do not want it
        toastError(this.$translate.instant('toast.default-error'));
        this.showErrorMessage = true;
      })
      .finally(() => {
        this.busy.registeringUpsSubAccounts = false;
      });
  }

  get canEditAddress() {
    return this.UserSession.hasUserRole('account_and_settings');
  }

  get locale(): string {
    return this.UserSession.user.dashboard_settings?.language?.code || 'en';
  }

  get titleTranslateId(): string {
    if (this.esCountry) {
      return `ups-dap-modal.title-${this.esCountry}`;
    }
    return 'ups-dap-modal.title';
  }

  get upsRateImage(): string {
    switch (this.esCountry) {
      case 'GB':
        return upsRateImageGB;
      case 'US':
        return upsRateImageUS;
      case 'NL':
        return upsRateImageNoPercentage;
      default:
        return upsRateImageDefault;
    }
  }

  get descTranslateId(): string {
    if (this.esCountry) {
      return `ups-dap-modal.description-${this.esCountry}`;
    }
    return 'ups-dap-modal.description';
  }

  get agreementTranslateId(): string {
    if (this.esCountry && this.esCountry !== 'US') {
      return `ups-dap-modal.agreements-${this.esCountry}`;
    }
    return 'ups-dap-modal.agreements';
  }

  get translateChunks() {
    const defaultLinks = {
      'tac-link': (chunk: string) => guardLinkTag(linkMap.US.tac, chunk),
      'ta-link': (chunk: string) => guardLinkTag(linkMap.US.ta, chunk),
      'pi-link': (chunk: string) => guardLinkTag(linkMap.US.pi, chunk),
    };

    switch (this.esCountry) {
      case 'GB':
        return {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.GB.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.GB.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.GB.pi, chunk),
          'ptac-link': (chunk: string) => guardLinkTag(linkMap.GB.ptac, chunk),
        };
      case 'DE':
        return {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.DE.tac, chunk),
          'hod-link': (chunk: string) => guardLinkTag(linkMap.DE.hod, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.DE.pi, chunk),
        };
      case 'CA':
        return {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.CA.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.CA.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.CA.pi, chunk),
        };
      case 'NL':
        return {
          'tac-link': (chunk: string) => guardLinkTag(linkMap.NL.tac, chunk),
          'ta-link': (chunk: string) => guardLinkTag(linkMap.NL.ta, chunk),
          'pi-link': (chunk: string) => guardLinkTag(linkMap.NL.pi, chunk),
          'ptac-link': (chunk: string) => guardLinkTag(linkMap.NL.ptac, chunk),
        };
      case 'US':
      default:
        return defaultLinks;
    }
  }
}

const UpsDapAgreementComponent: ng.IComponentOptions = {
  controller: UpsDapAgreement,
  template,
  bindings: {
    esShow: '<',
    esClose: '&',
    esOnRegistrationSuccess: '&',
    esOnAgree: '&',
    esIsLast: '<',
    esCountry: '<',
    esAddressId: '<',
  },
};

export { UpsDapAgreementComponent };
