import React, { memo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useUserSession } from '@/providers//UserSessionProvider';
import { twMerge } from 'tailwind-merge';

interface Props {
  fee: number | null;
  className?: string;
}

export const FormattedFee = memo(({ fee, className }: Props) => {
  const { company: { currency } = { currency: '' } } = useUserSession();
  return (
    <div className={twMerge('font-md-regular-13 min-w-[60px] text-right', className)}>
      {fee ? (
        <FormattedNumber
          value={fee}
          style="currency"
          currency={currency}
          minimumFractionDigits={2}
        />
      ) : (
        <FormattedMessage id="global.free" />
      )}
    </div>
  );
});

export default FormattedFee;
