import angular from 'angular';
import { ChangePlanComponent } from '@client/src/global/components/subscription/change-plan-integration/change-plan.component';
import { AngularGlobalService } from '@client/core/components/wrappers/features/AngularGlobalService';
import insuranceClaim from './insurance-claim';
import contactAdminModal from './contact-admin-modal';
import bulkCancelModal from './bulk-cancel-modal';
import { ModalsComponent } from './modals.component';
import { ModalService } from './modals.service';

const MODULE_NAME = 'app.global.modals';

angular
  .module(MODULE_NAME, [contactAdminModal, bulkCancelModal, insuranceClaim])
  .component('esModals', ModalsComponent)
  .component('esGlobalServiceReact', AngularGlobalService)
  .component('esChangePlan', ChangePlanComponent)
  .service('ModalService', ModalService);

export default MODULE_NAME;
