import React from 'react';
import { Button, Card, CardContent } from 'easyship-components';
import CourierLogo from '@app/components/CourierLogo';
import CountryFlag from '@app/components/CountryFlag';
import { FormattedMessage } from 'react-intl';
import { UpsDapCardProps } from './types';

/**  everything in this component need to be adjusted to Angular's styling because it is still used side by side with
Angular card component (need to look the exact same).
Will have a lot of arbitrary values for tailwind classes and overriding */

export function UpsDapCard({ totalServices, countryCode, onActivateClicked }: UpsDapCardProps) {
  return (
    <Card variant="normal" className="w-full bg-sky-300">
      <CardContent className="flex items-center w-full py-[10px] px-5">
        <div className="flex items-center gap-[10px]">
          <CourierLogo
            className="!h-[62px] !w-[54px]"
            classImage="!h-[62px] !w-[54px]"
            slug="ups"
          />
          <p className="m-0 text-base">UPS</p>
        </div>
        <div className="flex items-center ml-auto gap-[10px]">
          <Button onClick={onActivateClicked} color="primary" type="button">
            <FormattedMessage id="store.checkout.activate" />
          </Button>
          <div className="flex items-center min-w-24 gap-[10px] w-full justify-end">
            <CountryFlag alpha2={countryCode} />
            <FormattedMessage
              id="global.pluralize.with-count.service"
              values={{ count: totalServices }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
