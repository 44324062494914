import { useQuery } from '@tanstack/react-query';
import { snakeToCamelCase } from '@/utils/snakeCamelTransform';
import { getShipment } from '@/api/shipments';
import { useUserSession } from '@/providers//UserSessionProvider';
import { GET_SHIPMENT_BY_ID_QUERY, UseShipmentByIdQueryProps } from './type';

export const useGetShipmentByIdQuery = ({ shipmentId }: UseShipmentByIdQueryProps) => {
  const { company } = useUserSession();
  return useQuery({
    queryKey: [GET_SHIPMENT_BY_ID_QUERY, 'shipment', shipmentId],
    queryFn: async () => {
      return snakeToCamelCase(
        await getShipment({
          companyId: company.id,
          shipmentId: shipmentId as string,
        })
      );
    },
    enabled: !!shipmentId,
  });
};
