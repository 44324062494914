class CourierAccountsResource {
  static $inject = ['$resource', 'API'];

  constructor($resource, API) {
    this.endpoint = `${API.endpoint}/companies/:company_id/courier_accounts/:umbrella_name/:id/:controller`;
    this.resource = $resource(
      this.endpoint,
      {},
      {
        update: { method: 'PATCH' },
        sendNextStepsEmail: {
          method: 'POST',
          params: {
            controller: 'send_next_steps_email',
          },
        },
        getAccessLicenseAgreement: {
          method: 'GET',
          params: {
            controller: 'ups_ready_access_license_agreement',
          },
        },
        printAccessLicenseAgreement: {
          method: 'GET',
          params: {
            controller: 'print_ups_ready_access_license_agreement',
          },
          headers: {
            Accept: 'application/pdf',
          },
          responseType: 'arraybuffer',
          transformResponse(response) {
            return { data: response };
          },
        },
        // COUR-1553 to be removed after all old UPS accounts reconnected
        getReconnectUrl: {
          method: 'GET',
          params: {
            controller: 'authorize',
          },
        },
        connectAuMyPostBusinessCode: {
          method: 'POST',
          params: {
            controller: 'mypost-business',
          },
        },
      }
    );
  }

  getListing(params) {
    return this.resource.get(params).$promise;
  }

  getDetails(params) {
    return this.resource.get(params).$promise;
  }

  connectAccount(params, payload) {
    return this.resource.save(params, payload).$promise;
  }

  update(params, payload) {
    return this.resource.update(params, payload).$promise;
  }

  connectAuMyPostBusinessCode(params, payload) {
    return this.resource.connectAuMyPostBusinessCode(params, payload).$promise;
  }

  updateAccount(_params_, payload) {
    const params = { ..._params_, controller: 'account' };

    return this.resource.update(params, payload).$promise;
  }

  updateSettings(params, payload) {
    return this.resource.update(params, payload).$promise;
  }

  deactivateAccount(params) {
    return this.resource.delete(params).$promise;
  }

  sendNextStepsEmail(params, payload) {
    return this.resource.sendNextStepsEmail(params, payload).$promise;
  }

  getAccessLicenseAgreement(params) {
    return this.resource.getAccessLicenseAgreement(params).$promise;
  }

  getReconnectUrl(params) {
    return this.resource.getReconnectUrl(params).$promise;
  }

  printAccessLicenseAgreement(params) {
    return this.resource.printAccessLicenseAgreement(params).$promise;
  }
}

export { CourierAccountsResource };
