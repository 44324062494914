import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API } from '@/constants/api';
import { Alert } from 'easyship-components';
import { PdfEmbedComponent } from '@/components/PdfEmbedComponent';
import { CourierLogo } from '@/constants/courier';
import { useUserSession } from '@/contexts/providers/UserSessionProvider';
import useValidReceiverCountries from '@/hooks/queries/useValidReceiverCountries';
import useDebounce from '@/hooks/general/useDebounce';
import InvoiceForm from './InvoiceForm';
import {
  CourierDetails,
  FedexFormValues,
  FedexStep,
  FedexStepEnum,
  FedexVerificationMethodFormValues,
  InvoiceFormValues,
  ResendPinPayload,
} from './types';
import FedexFormInputFileds from './FedexFormInputFields';
import ChangeStepComponent from './ChangeStepComponent';
import PinCodeForm from './PinCodeForm';
import SelectVerificationMethodForm from './SelectVerificationMethodForm';

export interface ConnectCourierFormFedexProps {
  courierId: string;
  companyId: string;
  currentStep: FedexStep;
  umbrellaName: string;
  error: string;
  onChangeCourierDetails: (details: CourierDetails) => void;
  onChangeStep: (step: FedexStep) => void;
  onResendPin: ({ payload }: { payload: ResendPinPayload }) => Promise<void>;
}

export function ConnectCourierFormFedex({
  courierId,
  companyId,
  currentStep,
  umbrellaName,
  error,
  onChangeCourierDetails,
  onChangeStep,
  onResendPin,
}: ConnectCourierFormFedexProps) {
  const { control, trigger } = useFormContext<
    FedexFormValues | InvoiceFormValues | FedexVerificationMethodFormValues
  >();
  const formValues = useWatch({ control });
  const debouncedFormValues = useDebounce(formValues, 500);

  const { data: countries = [] } = useValidReceiverCountries();
  const watchCountryId = useWatch({
    name: `billingAddress.countryId`,
  });

  const country = countries.find((country) => country.id === watchCountryId);

  const {
    user: { isNewFedexLyocEnabled },
  } = useUserSession();

  const [courierDetails, setCourierDetails] = useState<CourierDetails>({});
  const [pinCodeError, setPinCodeError] = useState('');

  function getFedexEulaPdf(): string {
    let fileName = 'fedex-eula-distribution-agreement';

    if (umbrellaName === CourierLogo.FedexCrossBorder) {
      fileName = 'fedex-cross-border-eula';
    } else if (isNewFedexLyocEnabled()) {
      fileName = 'fedex-eula-v2';
    }

    return `${API.easyship_storage}/courier-docs/${fileName}.pdf`;
  }

  function handleInputChange(option: string | null, key: string) {
    const newCourierDetails = { ...courierDetails };
    newCourierDetails[key] = option;
    setCourierDetails(newCourierDetails);
  }

  useEffect(() => {
    const {
      accountNickname,
      accountNumber,
      line1,
      line2,
      billingAddress,
      contactName,
      contactPhone,
      contactEmail,
      companyName,
    } = debouncedFormValues as FedexFormValues;

    const { amount, currency, number, date } = debouncedFormValues as InvoiceFormValues;

    const newCourierDetails = {
      nickname: accountNickname,
      account_number: accountNumber,
      contact: {
        name: contactName,
        phone: contactPhone,
        email: contactEmail,
      },
      company_name: companyName,
      state: billingAddress?.state,
      postal_code: billingAddress?.postalCode,
      city: billingAddress?.city,
      country_code: country?.alpha2,
      line_1: line1,
      line_2: line2,
      invoice_details: {
        number,
        amount,
        currency,
        date,
      },
    };

    onChangeCourierDetails(newCourierDetails);
  }, [debouncedFormValues, onChangeCourierDetails, country?.alpha2]);

  useEffect(() => {
    if (error) {
      trigger();
    }
  }, [error, trigger]);

  return (
    <div className="pt-4 px-12 pb-5">
      {currentStep === FedexStepEnum.Eula && (
        <div>
          <span className="!mb-[10px] text-ink-900 text-base">
            In order to continue, you will need to agree to the FedEx EULA.
          </span>
          <div className="h-[380px]">
            <PdfEmbedComponent src={getFedexEulaPdf()} hidePrintButton />
          </div>
        </div>
      )}
      {currentStep === FedexStepEnum.Form && (
        <div>
          <FedexFormInputFileds umbrellaName={umbrellaName} />
          {error && (
            <Alert severity="error" className="mt-4">
              {error}
            </Alert>
          )}
        </div>
      )}
      {currentStep === FedexStepEnum.Invoice && (
        <div>
          <InvoiceForm />
          {error && (
            <Alert severity="error" className="mt-4">
              {error}
            </Alert>
          )}
          <div className="mb-6 mt-6">
            <ChangeStepComponent
              message="courier.connect.form.fedex.invoice.try-another"
              button="courier.connect.form.fedex.invoice.receive-code"
              step={FedexStepEnum.PinChoice}
              onChangeStep={onChangeStep}
            />
          </div>
        </div>
      )}
      {currentStep === FedexStepEnum.PinChoice && (
        <div>
          <p className="text-base mb-2 text-ink-900">
            <FormattedMessage id="courier.connect.form.fedex.pin-choice.pick-method" />
          </p>
          <SelectVerificationMethodForm handleInputChange={handleInputChange} />
          {error && (
            <Alert severity="error" className="mt-4">
              {error}
            </Alert>
          )}
          <div className="mb-6 mt-6">
            <ChangeStepComponent
              message="courier.connect.form.fedex.try-another"
              button="courier.connect.form.fedex.pin-choice.provide-invoice"
              step={FedexStepEnum.Invoice}
              onChangeStep={onChangeStep}
            />
          </div>
        </div>
      )}
      {[FedexStepEnum.PinSMS, FedexStepEnum.PinEmail].includes(currentStep) && (
        <div>
          <PinCodeForm
            courierId={courierId}
            companyId={companyId}
            currentStep={currentStep}
            onResendPin={onResendPin}
            handleInputChange={handleInputChange}
            setError={setPinCodeError}
          />
          <div>
            <ChangeStepComponent
              message="courier.connect.form.fedex.pin-choice.cant-receive"
              button="courier.connect.form.fedex.pin-choice.try-another"
              step={FedexStepEnum.PinChoice}
              onChangeStep={onChangeStep}
            />
          </div>
          {(error || pinCodeError) && (
            <Alert severity="error" className="mt-4">
              {error ?? pinCodeError}
            </Alert>
          )}
        </div>
      )}
      <p className="mt-3 mb-0 text-ink-100 text-xs">
        <FormattedMessage id="courier.fedex-trademark" />
      </p>
    </div>
  );
}
