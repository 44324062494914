// eslint-disable-next-line
import {
  OrderStatusRadioModelValue as ShipmentStatusFilterValue,
} from '@client/src/global/services/shipment-list/data/radio-group';
import { TransformToCamelCase } from '@/types/general';
import { IShipmentListCustomViewsItem } from '@typings/shipment';
import { IRateItem } from '@typings/rates';

export type ShipmentListCustomViewsItem = TransformToCamelCase<IShipmentListCustomViewsItem>;

export type ShipmentsFilterDataCustomView = ShipmentListCustomViewsItem['filters'];

export { ShipmentStatusFilterValue };

export type RateItem = TransformToCamelCase<IRateItem> & {
  shipmentChargeMarkup?: number;
  residentialFeeApplied?: number;
};

export enum RateTypeCost {
  SHIPPING_COST = 'shipping-cost',
  SHIPPING_COST_CHARGES = 'shipping-cost-charges',
  COURIER_SURCHARGES = 'courier-surcharges',
  INSURANCE = 'insurance',
  WAREHOUSE_CHARGES = 'warehouse-charges',
  TAXES_AND_DUTIES = 'tax_and_duties',
}

export type ShipmentFee = {
  name: {
    id: string;
    defaultMessage?: string;
    values?: { code: string };
  };
  value?: number;
};

export type ShipmentFeesGroup = {
  name: string;
  type: RateTypeCost;
  fees: ShipmentFee[];
};
