import { toastError, toastSuccess, toastMessage } from '@client/core/components/react/Toastify';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import { hasUserDirectPrintEnabled } from '@app/utils/general';
import { directPrintPackingSlips } from '@app/utils/printing_actions';

/* eslint-disable no-param-reassign */
(function () {
  angular
    .module('easyshipDashboardApp')
    .controller('DashboardHeaderMultipleCtrl', DashboardHeaderMultipleCtrl);

  DashboardHeaderMultipleCtrl.$inject = [
    '$scope',
    '$translate',
    'UserSession',
    'UserService',
    'deletePromptModal',
    'ShipmentHelpers',
    'ShipmentList',
    'ReportService',
    'MixpanelService',
    'MergeAllModal',
    'MergeSelectedModal',
    'SplitModal',
    'ShipmentListAdvancedService',
    'AppCuesService',
    'PicklistService',
    'PackingSlipService',
    'HelperService',
    'RequestFeatureService',
    'AddressService',
    'SubscriptionService',
    'UserRightsService',
    'ResendEmailsModal',
    'CollectBulkService',
  ];
  function DashboardHeaderMultipleCtrl(
    $scope,
    $translate,
    UserSession,
    UserService,
    deletePromptModal,
    ShipmentHelpers,
    ShipmentList,
    ReportService,
    MixpanelService,
    MergeAllModal,
    MergeSelectedModal,
    SplitModal,
    ShipmentListAdvancedService,
    AppCuesService,
    PicklistService,
    PackingSlipService,
    HelperService,
    RequestFeatureService,
    AddressService,
    SubscriptionService,
    UserRightsService,
    ResendEmailsModal,
    CollectBulkService
  ) {
    this.SubscriptionService = SubscriptionService;
    this.$translate = $translate;
    this.UserSession = UserSession;
    this.UserService = UserService;
    this.ShipmentHelpers = ShipmentHelpers;
    this.ReportService = ReportService;
    this.ShipmentList = ShipmentList;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.RequestFeatureService = RequestFeatureService;
    this.AddressService = AddressService;
    this.UserRightsService = UserRightsService;
    this.busy = {
      shipmentAction: false,
      moreActions: false,
    };
    const $ctrl = this;

    const untranslatedButtons = [
      {
        icon: 'icon-merge-1',
        translationKey: 'header.merge-same-dest',
        action() {
          _openMergeAllModal();
        },
      },
      {
        icon: 'icon-merge-2',
        translationKey: 'header.merge-selected',
        action() {
          _openMergeSelectedModal();
        },
      },
      {
        icon: 'icon-split',
        translationKey: 'header.split-selected',
        action() {
          _openSplitModal();
        },
      },
      {
        icon: 'icon-trash',
        translationKey: 'header.delete-selected',
        action() {
          return _openDeleteModal();
        },
      },
      {
        icon: 'icon-pencil',
        translationKey: 'header.bulk-edit',
        action() {
          return _openBulkEditModal();
        },
      },
      {
        icon: 'icon-picklist',
        translationKey: 'header.pick-list',
        action() {
          return _generatePicklist();
        },
        isPlanBadgeVisible: $ctrl.SubscriptionService.isPlanBadgeVisible('pick_list'),
        planName: $ctrl.SubscriptionService.getPlanNameByFeatureKey('pick_list'),
        disabled: !this.UserRightsService.canGeneratePickList,
      },
      {
        id: 'packing-slip',
        icon: 'icon-document',
        translationKey: 'header.packing-slip',
        action() {
          return _generatePackingSlip();
        },
      },
      {
        icon: 'icon-export',
        translationKey: 'header.export',
        action() {
          return _export();
        },
        isPlanBadgeVisible: $ctrl.SubscriptionService.isPlanBadgeVisible('export_shipments'),
        planName: $ctrl.SubscriptionService.getPlanNameByFeatureKey('export_shipments'),
      },
    ];

    const proozyCompanies = ['CUS277455', 'CUS00003'];
    if (proozyCompanies.indexOf($ctrl.UserSession.company.easyship_company_id) >= 0) {
      untranslatedButtons.push({
        icon: 'icon-export',
        translationKey: 'header.export-proozy',
        action() {
          return _export('client_proozy');
        },
      });
    }

    const sendAFriendCompanies = ['CUS199515', 'CUS00003'];
    if (sendAFriendCompanies.indexOf($ctrl.UserSession.company.easyship_company_id) >= 0) {
      untranslatedButtons.push({
        icon: 'icon-export',
        translationKey: 'header.export-note-cards',
        action() {
          return _export('client_send_a_friend');
        },
      });
    }

    const dashboardSettings = this.UserSession.getCompanyDashboardSettings();

    if (dashboardSettings.beta_feature_igg_export) {
      untranslatedButtons.push({
        icon: 'icon-export',
        translationKey: 'header.export-igg',
        action() {
          return _export('client_indiegogo');
        },
      });
    }

    if (dashboardSettings.beta_feature_mirakl_export) {
      untranslatedButtons.push({
        icon: 'icon-export',
        translationKey: 'header.export-mirakl',
        action: function () {
          return _export('client_mirakl');
        },
      });
    }

    if (dashboardSettings.show_tax_collect_dashboard_export) {
      untranslatedButtons.push({
        icon: 'icon-export',
        translationKey: 'header.export-es-collect',
        action() {
          return _export('client_tax_collect');
        },
      });
    }

    const isCollectActive = this.UserSession.company.dashboard_settings.show_tax_collect_filter;

    if (isCollectActive) {
      untranslatedButtons.push({
        icon: 'icon-resend-email',
        translationKey: 'header.resend-emails',
        action() {
          return _openResendEmailsModal();
        },
      });
    }

    this.moreActions = untranslatedButtons.map(function (button) {
      button.displayText = $translate.instant(button.translationKey);
      return button;
    });

    this.actionsDisabled = function () {
      return ReportService.busy || ShipmentListAdvancedService.shipmentBusy;
    };

    this.refreshShipments = function () {
      ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter('orders_all');
    };

    this.prepareCheckout = function () {
      if (ShipmentListAdvancedService.shipmentBusy) {
        return;
      }

      if (
        !ShipmentListAdvancedService.isSelectedAll &&
        ShipmentListAdvancedService.validToShipSelectedCount <= 0
      ) {
        toastError($translate.instant('checkout.notifications.select-shipment'));
        return;
      }

      $ctrl.busy.shipmentAction = true;

      $scope.onPrepareMultipleShipments().finally(function () {
        $ctrl.busy.shipmentAction = false;
      });
    };

    this.validToShipTotal = function () {
      if (ShipmentListAdvancedService.isSelectedAll && ShipmentListAdvancedService.totals) {
        const validExcludedCount = ShipmentListAdvancedService.currentShipments.reduce(function (
          sum,
          shipment
        ) {
          return ShipmentListAdvancedService.excludedIds.includes(shipment.id) &&
            shipment.is_valid_to_ship
            ? sum + 1
            : sum;
        },
        0);
        return ShipmentListAdvancedService.totals.valid_to_ship_count - validExcludedCount;
      }

      return ShipmentListAdvancedService.validToShipSelectedCount;
    };

    this.isRetainingClassicView = function () {
      return this.UserSession.user.dashboard_settings.multiple.retain_classic_view;
    };

    this.togglePanelView = function () {
      const isChangingToClassicView =
        !this.UserSession.user.dashboard_settings.multiple.retain_classic_view;

      MixpanelService.track('Create Shipment - Advanced - Switch views', {
        view: isChangingToClassicView ? 'classic' : 'panel',
      });
      toastSuccess(
        $translate.instant('dashboard-header.view-toggle-notification', {
          isChangingToClassicView,
        })
      );

      const updatedSettings = {
        dashboard_settings: this.UserService.updateAndGetUserDashboardSettings(
          'multiple',
          'retain_classic_view',
          isChangingToClassicView
        ),
      };
      this.UserService.update(updatedSettings);
    }.bind(this);

    this.submitPanelFeedback = function (comment) {
      if (!comment) return;

      this.RequestFeatureService.submitRequest(
        { userId: this.UserSession.user.id },
        {
          page: 'Shipment Editor Panel',
          comment,
        }
      );
    }.bind(this);

    function _openMergeAllModal() {
      $ctrl.busy.moreActions = true;
      MixpanelService.track('Multiple - Merge All');

      ShipmentListAdvancedService.mergeAllPrepare()
        .then(function (response) {
          MergeAllModal.open(response);
        })
        .catch(function (error) {
          MixpanelService.track('Multiple - Merge All - Not Valid', {
            error: error.data && error.data.status,
          });
          _displayErrorNotification(error);
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });
    }

    function _openMergeSelectedModal() {
      const selectedShipmentsCount = _getSelectedShipmentsCount();

      MixpanelService.track('Multiple - Select Merge', { shipments_count: selectedShipmentsCount });

      if (selectedShipmentsCount < 2) {
        toastError($translate.instant('header.selected-single'));

        MixpanelService.track('Multiple - Select Merge - Not Valid', {
          error: 'Please select at least 2 shipments.',
        });
        return;
      }

      $ctrl.busy.moreActions = true;

      ShipmentListAdvancedService.mergeSelectedPrepare()
        .then(function (response) {
          MergeSelectedModal.open(response);
        })
        .catch(function (error) {
          MixpanelService.track('Multiple - Select Merge - Not Valid', {
            error: error.data && error.data.status,
          });
          _displayErrorNotification(error);
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });
    }

    function _openSplitModal() {
      const selectedShipmentsCount = _getSelectedShipmentsCount();

      MixpanelService.track('Multiple - Select Split');

      if (selectedShipmentsCount !== 1) {
        const message = `header.selected-${selectedShipmentsCount === 0 ? 'none' : 'multiple'}`;
        toastError($translate.instant(message));
        MixpanelService.track('Multiple - Select Split - Not Valid', {
          error:
            selectedShipmentsCount === 0
              ? 'Please select a shipment'
              : 'Please select 1 shipment only.',
        });
        return;
      }

      $ctrl.busy.moreActions = true;

      ShipmentListAdvancedService.splitPrepare()
        .then(function (response) {
          SplitModal.open(response);
        })
        .catch(function (error) {
          MixpanelService.track('Multiple - Select Split - Not Valid', {
            error: error.data && error.data.status,
          });
          _displayErrorNotification(error);
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });
    }

    function _openDeleteModal() {
      if (
        !ShipmentListAdvancedService.isSelectedAll &&
        ShipmentListAdvancedService.selectedIds.length === 0
      ) {
        MixpanelService.track('Multiple - Delete - Invalid');

        toastError(
          $translate.instant('toast.select-error', {
            noun: $translate
              .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
              .toLowerCase(),
          })
        );
        return;
      }

      deletePromptModal.open();
    }

    function _openBulkEditModal() {
      if (
        !ShipmentListAdvancedService.isSelectedAll &&
        ShipmentListAdvancedService.selectedIds.length === 0
      ) {
        toastError(
          $translate.instant('toast.select-error', {
            noun: $translate
              .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
              .toLowerCase(),
          })
        );
        return;
      }

      $scope.openDashboardHeaderModal('bulk-edit');
      MixpanelService.track('Bulk update - Click Bulk update action');
    }

    function _generatePicklist() {
      const isUpgradeModalVisible =
        $ctrl.SubscriptionService.openUpgradeModalIfFeatureNotAccessible('pick_list', 'Pick List');
      if (isUpgradeModalVisible) {
        return;
      }

      if (!_areAnyShipmentsSelected()) {
        _showNoShipmentsSelectedNotification();
        return;
      }

      $ctrl.busy.moreActions = true;

      toastMessage($translate.instant('header.generating-pick-list'));

      const payload = _getSelectedShipmentsPayload();
      PicklistService.getPicklist(payload)
        .then(function (response) {
          HelperService.openNewTab(response.file);
        })
        .catch(function () {
          toastError($translate.instant('toast.default-error'));
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });

      MixpanelService.track('Pick List - Generate', {
        page: 'Advanced',
      });
    }

    function _generatePackingSlip() {
      if (!_areAnyShipmentsSelected()) {
        _showNoShipmentsSelectedNotification();
        return;
      }

      $ctrl.busy.moreActions = true;

      const selectedShipmentsCount = _getSelectedShipmentsCount();

      const maxNumberOfShipmentsForSynchronousGenerationOfPackingSlips = 300;
      const arePackingSlipsGeneratedAsynchronously =
        selectedShipmentsCount > maxNumberOfShipmentsForSynchronousGenerationOfPackingSlips;

      if (!arePackingSlipsGeneratedAsynchronously) {
        _showGeneratingPackingSlipsSynchronouslyNotification(selectedShipmentsCount);
      }

      const printBrowserFn = () => {
        const payload = _getSelectedShipmentsPayload();
        PackingSlipService.getPackingSlips(payload)
          .then(function (response) {
            _processPackingSlipGenerationSuccess(
              response,
              arePackingSlipsGeneratedAsynchronously,
              selectedShipmentsCount
            );
          })
          .catch(function () {
            toastError($translate.instant('toast.default-error'));
          })
          .finally(function () {
            $ctrl.busy.moreActions = false;
          });
      };

      if (hasUserDirectPrintEnabled()) {
        const payload = _getSelectedShipmentsPayload();
        directPrintPackingSlips(payload.shipment_ids, payload.context, printBrowserFn).then();
        $ctrl.busy.moreActions = false;
      } else printBrowserFn();

      MixpanelService.track('Packing Slip - Generate from Create Shipments', {
        page: 'Advanced',
      });
    }

    function _export(exportType) {
      if (
        $ctrl.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
          'export_shipments',
          'Export Shipments'
        )
      ) {
        return;
      }

      AppCuesService.track('Advanced | Export');
      MixpanelService.track('Advanced - Click Export');

      ShipmentListAdvancedService.exportShipments(exportType)
        .then(function () {
          // Need to retrigger the page() event on appcues to update the event instantly on appcues
          AppCuesService.page();
        })
        .catch(function (error) {
          _displayErrorNotification(error);
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });
    }

    function _openResendEmailsModal() {
      if (!_areAnyShipmentsSelected()) {
        _showNoShipmentsSelectedNotification();
        return;
      }
      $ctrl.busy.moreActions = true;

      MixpanelService.track('Collect - Click Resend Multiple', {
        shipments_count: ShipmentListAdvancedService.selectedShipmentsCount,
      });

      CollectBulkService.resendEmailsPrepare(ShipmentListAdvancedService.selectedIds)
        .then(function (response) {
          response.shipments = ShipmentListAdvancedService.selectedIds;
          ResendEmailsModal.open(response);
        })
        .catch(function (error) {
          MixpanelService.track('Collect - Resend Multiple - Not Valid', {
            error: error.data && error.data.status,
          });
          _displayErrorNotification(error);
        })
        .finally(function () {
          $ctrl.busy.moreActions = false;
        });
    }

    function _showGeneratingPackingSlipsSynchronouslyNotification(selectedShipmentsCount) {
      toastMessage(
        $translate.instant('header.generating-packing-slip', {
          quantity: selectedShipmentsCount,
          noun: $translate
            .instant(
              'global.pluralize.shipment',
              { COUNT: selectedShipmentsCount },
              'messageformat'
            )
            .toLowerCase(),
        }),
        {
          autoClose: 3000,
        }
      );
    }

    function _areAnyShipmentsSelected() {
      return _getSelectedShipmentsCount() > 0;
    }

    function _showNoShipmentsSelectedNotification() {
      toastError(
        $translate.instant('toast.select-error', {
          noun: $translate
            .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
    }

    function _getSelectedShipmentsPayload() {
      if (ShipmentListAdvancedService.isSelectedAll) {
        return angular.merge(ShipmentListAdvancedService.filterPayload, {
          by_search: true,
          exclude_shipment_ids: ShipmentListAdvancedService.excludedIds,
          scope: 'orders_all',
          context: 'Advanced Shipments',
        });
      }

      return {
        shipment_ids: ShipmentListAdvancedService.selectedIds,
        context: 'Advanced Shipments',
      };
    }

    function _processPackingSlipGenerationSuccess(
      response,
      arePackingSlipsGeneratedAsynchronously,
      selectedShipmentsCount
    ) {
      if (!arePackingSlipsGeneratedAsynchronously) {
        HelperService.openNewTab(response.file);
        return;
      }

      showToastWithAction(
        this.$translate.instant('header.generating-packing-slip-async', {
          email: response.email,
          quantity: selectedShipmentsCount,
          noun: $translate
            .instant(
              'global.pluralize.shipment',
              { COUNT: selectedShipmentsCount },
              'messageformat'
            )
            .toLowerCase(),
        }),
        {
          actionLabel: $translate.instant('global.got-it'),
        }
      );
    }

    function _getSelectedShipmentsCount() {
      const selectedShipmentsCount = ShipmentListAdvancedService.selectedIds.length;
      const selectAllShipmentsCount =
        ShipmentListAdvancedService.totals.orders_count -
        ShipmentListAdvancedService.excludedIds.length;

      return ShipmentListAdvancedService.isSelectedAll
        ? selectAllShipmentsCount
        : selectedShipmentsCount;
    }

    function _displayErrorNotification(error) {
      toastError(error.data && error.data.status);
    }
  }
})();
