import { useUserSession } from '@/providers/UserSessionProvider';

import { CourierLogo } from '@/constants/courier';

import { Alert, Input } from 'easyship-components';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useDefaultAddressQuery from '@/hooks/queries/useDefaultAddressQuery';
import ReceiverCountryFields from '@/components/ReceiverCountryFields';
import { FedexFormValues } from '../types';

interface FedexFormInputFieldsProps {
  umbrellaName: string;
}

function FedexFormInputFileds({ umbrellaName }: FedexFormInputFieldsProps) {
  const { reset } = useFormContext<FedexFormValues>();
  const { data: address } = useDefaultAddressQuery('pickup');
  const {
    company: { name, phoneNumber },
    user: { firstName, lastName, email, isNewFedexLyocEnabled },
  } = useUserSession();

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (address) {
      reset({
        contactName: `${firstName} ${lastName}`,
        companyName: name,
        contactEmail: email,
        contactPhone: phoneNumber,
        billingAddress: {
          city: address.city ?? '',
          postalCode: address.postal_code ?? '',
          state: address.state ?? '',
          countryId: address.country_id ?? 0,
        },
        line1: address.line_1 ?? '',
        line2: address.line_2 ?? '',
      });
    }
  }, [reset, address, firstName, lastName, name, email, phoneNumber]);

  // Rules
  const accountNicknameRules = {
    required: true,
    minLength: 3,
    maxLength: 15,
  };

  const accountNumberRules = {
    required: true,
    minLength: 9,
    maxLength: 9,
  };

  return (
    <div>
      <div className="mb-2 flex flex-col">
        <Controller
          name="accountNickname"
          rules={accountNicknameRules}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              value={field.value || ''}
              label={<FormattedMessage id="courier.connect.field.account-name.label" />}
              placeholder={formatMessage({
                id: 'courier.connect.field.account-name.placeholder',
              })}
              statusText={formatMessage({ id: 'courier.connect.field.account-name.help' })}
              status={(!!fieldState.error && 'error') || 'default'}
              maxLength={accountNicknameRules.maxLength}
              minLength={accountNicknameRules.minLength}
            />
          )}
        />
      </div>

      {/* Default fedex form start */}
      {umbrellaName === CourierLogo.Fedex && (
        <div className="flex flex-col">
          <Controller
            name="accountNumber"
            rules={accountNumberRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                value={field.value || ''}
                label={<FormattedMessage id="courier.connect.field.fedex.account-number.label" />}
                placeholder={formatMessage({
                  id: 'courier.connect.field.fedex.account-number.placeholder',
                })}
                status={(!!fieldState.error && 'error') || 'default'}
                maxLength={accountNumberRules.maxLength}
                minLength={accountNumberRules.minLength}
              />
            )}
          />
          {!isNewFedexLyocEnabled() && (
            <div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <Controller
                  name="companyName"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      label={<FormattedMessage id="global.company-name" />}
                      status={(!!fieldState.error && 'error') || 'default'}
                    />
                  )}
                />
                <Controller
                  name="contactName"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      label={<FormattedMessage id="global.name" />}
                      status={(!!fieldState.error && 'error') || 'default'}
                    />
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <Controller
                  name="contactPhone"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      label={<FormattedMessage id="global.phone-number" />}
                      status={(!!fieldState.error && 'error') || 'default'}
                    />
                  )}
                />
                <Controller
                  name="contactEmail"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      label={<FormattedMessage id="global.email" />}
                      status={(!!fieldState.error && 'error') || 'default'}
                    />
                  )}
                />
              </div>
            </div>
          )}

          <Alert className="mt-8">
            <FormattedMessage id="fedex-compatible-connect.address-note" />
          </Alert>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <ReceiverCountryFields name="billingAddress" strictFieldsValidation={false} />
          </div>

          <div className="mt-4">
            <Controller
              name="line1"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="global.address-line-1" />}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
              defaultValue={address?.line_1 ?? ''}
            />
          </div>
          <div className="mt-4">
            <Controller
              name="line2"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="global.address-line-2" />}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
              defaultValue={address?.line_2 ?? ''}
            />
          </div>
        </div>
      )}
      {/* Default fedex form end */}
      {/* Cross border fedex form start */}
      {umbrellaName === CourierLogo.FedexCrossBorder && (
        <div className="flex flex-col">
          <div className="mt-4">
            <Controller
              name="apiKey"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="global.api-key" />}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
            />
          </div>
          <div className="mt-4">
            <Controller
              name="username"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="global.username" />}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
            />
          </div>
          <div className="mt-4">
            <Controller
              name="password"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  label={<FormattedMessage id="global.password" />}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
            />
          </div>
        </div>
      )}
      {/* Cross border fedex form end */}
    </div>
  );
}
export default FedexFormInputFileds;
