import { FeatureKey } from '@client/data/subscription';
import { IFeature } from 'typings/subscription';

const ORDER_FEATURES: FeatureKey[] = [
  'shipments_per_month',
  'team_members',
  'lyoc',
  'lyoc_labelling_fee',
  'ecommerce_integrations',
  'crowdfunding_integration',
  'global_fulfilment_network',
  'analytics',
  'tax_and_duty',
  'rates_at_checkout',
  'tax_and_duty_at_checkout',
  'onboarding_session',
  'api_access',
  'shipping_solutions',
  'usps',
  'insurance',
  'returns',
  'pickups',
  'printing-options',
  'catalog',
  'boxes',
  'mobile_app_access',
  'customize_shipment_table',
  'direct_print',
  'pick_and_pack',
  'user_access_management',
  'shipping_rules',
  'branded_tracking_experience',
  'auto_sync',
  'export_shipments',
  'printing_format_4x6',
  'label_customization',
  'automated_return',
  'multi_box_shipments',
  'pick_list',
  'email_support',
  'chat_support',
  'phone_support',
  'dedicated_account_manager',
];

export default class FeatureList {
  constructor(public features: IFeature[]) {}

  getForUserCountry(): IFeature[] {
    // Remove all data that are not truthy
    const availableFeatures = this.features.filter(
      (feature) => !Object.values(feature.data).every((value) => !value)
    );

    // Sort the feature
    return ORDER_FEATURES.reduce<IFeature[]>((reordered, key) => {
      const feature = availableFeatures.find((item) => item.key === key);

      if (feature) reordered.push(feature);

      return reordered;
    }, []);
  }
}
