import { useGetUpdateStatusQuery } from '@app/hooks/queries/company/useGetUpdateStatusQuery';
import { CompanyGatewayProvider } from '@app/pages/company/contexts/CompanyGatewayProvider';
import { PromoCodeCard } from '@app/pages/payment-method/components/cards/PromoCodeCard';
import { PromoCodeForm } from '@app/pages/payment-method/components/forms/PromoCodeForm';
import { Card } from 'easyship-components';
import React from 'react';

export function PromoCodeSection() {
  const { data, refetch: fetchUpdateStatus } = useGetUpdateStatusQuery();

  return (
    <>
      <Card className="w-full py-5 mb-4 px-7">
        <CompanyGatewayProvider>
          <PromoCodeForm onSubmitSuccess={fetchUpdateStatus} />
        </CompanyGatewayProvider>
      </Card>
      {data?.voucher && <PromoCodeCard voucher={data.voucher} />}
    </>
  );
}
