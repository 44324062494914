import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { Input } from 'easyship-components';
import { courierFieldsConfig } from './config';

export function CourierFormFieldsComponent({ logoUrl }: { logoUrl: string }) {
  const { formatMessage } = useIntl();
  const config = courierFieldsConfig.find((config) => config.courier === logoUrl);

  return (
    <>
      {config &&
        config.fields.map((i) => (
          <div className="mt-4" key={i.name}>
            <Controller
              name={i.name}
              rules={i.rules}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type={i.type}
                  autoComplete={i.autocomplete}
                  label={
                    <FormattedMessage id={i.id} values={{ courierName: i.values?.courierName }} />
                  }
                  {...(i.helperText && { statusText: formatMessage({ id: i.helperText }) })}
                  {...(i.placeholder && { placeholder: formatMessage({ id: i.placeholder }) })}
                  {...(i.rules.maxLength && { maxLength: i.rules.maxLength })}
                  {...(i.rules.minLength && { minLength: i.rules.minLength })}
                  status={(!!fieldState.error && 'error') || 'default'}
                />
              )}
            />
          </div>
        ))}
    </>
  );
}
