import angular from 'angular';

import { CourierCreate } from './courier-create.service';
import { CourierCreateMainComponent } from './courier-create-main/courier-create-main.component';
import { AngularChooseCourierComponent } from '../components/ChooseCourierComponent';
import { AngularConnectCourierComponent } from '../../../core/components/wrappers/components/AngularConnectCourierComponent/AngularConnectCourierComponent';

const MODULE_NAME = 'app.couriers.courier-create';

angular
  .module(MODULE_NAME, [])
  .service('CourierCreate', CourierCreate)
  .component('chooseCourier', AngularChooseCourierComponent)
  .component('courierCreateMain', CourierCreateMainComponent)
  .component('connectCourier', AngularConnectCourierComponent);

export default MODULE_NAME;
