import { useIntl } from 'react-intl';

type MessageTransformer = {
  angularToReactMessage: (id: string) => string;
};

/** Transform Angular message syntax to React message syntax for localisation files */

export const useMessageTransformer = (): MessageTransformer => {
  const intl = useIntl();

  const angularToReactMessage = (id: string): string => {
    const message = intl.messages[id] as string;
    return message.replace(/\{\{\s*([^}]+)\s*\}\}/g, '{$1}');
  };

  return {
    angularToReactMessage,
  };
};
