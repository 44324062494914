import React, { ReactElement } from 'react';
import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { react2angular } from 'react2angular';
import { PromoCodeSection } from '@app/pages/payment-method/components/sections/PromoCodeSection';

export function WrappedPromoCodeSection(): ReactElement {
  return (
    <ReactRootProviders>
      <PromoCodeSection />
    </ReactRootProviders>
  );
}

export const AngularPromoCodeSection = react2angular(WrappedPromoCodeSection);
