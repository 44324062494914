import { Alert, Card, CardContent, CardHeader, Radio, RadioGroup } from 'easyship-components';
import React, { memo, useEffect, useState } from 'react';
import { Insured, NotInsured, ShieldTealLarge } from 'easyship-components/icons';
import { FormattedMessage } from 'react-intl';
import { API } from '@app/constants/api';
import { useGetCheckoutPrepareQuery } from '@app/hooks/queries/useGetCheckoutPrepareQuery';
import { InsuranceCardProps } from '@/components/InsuranceCard/types';
import { useUserSession } from '@app/contexts/providers/UserSessionProvider';
import { useSimplifiedDomestic } from '@app/hooks/general/useSimplifiedDomestic';
import FormattedFee from '../FormattedFee';
import { ShipmentTotalValueAndInsuranceFee } from '../ShipmentTotalValueAndInsuranceFee';

function InsuranceCardTitle() {
  return (
    <div className="flex items-center gap-3">
      <ShieldTealLarge className="bg-teal w-6 h-6" />
      <span className="text-lg">
        <FormattedMessage id="insurance-card.title" />
      </span>
    </div>
  );
}

export const InsuranceCard = memo(
  ({
    handleInputChange,
    valueSelected,
    className,
    shipmentId,
    showRadioError,
    isInputDomesticValidationError,
  }: InsuranceCardProps) => {
    const { company } = useUserSession();
    const { showSimplifiedDomesticFlow } = useSimplifiedDomestic(shipmentId);

    const hideInsuranceAddOn = company.dashboardSettings.hide_insurance_add_on;
    const [isInsuranceSelected, setIsInsuranceSelected] = useState<string | undefined>(
      valueSelected === null ? undefined : valueSelected.toString()
    );

    useEffect(() => {
      setIsInsuranceSelected(valueSelected === null ? undefined : valueSelected.toString());
    }, [valueSelected]);

    const hasRadioError = !!showRadioError;

    const { data } = useGetCheckoutPrepareQuery();
    const shipmentsCountInsuredNotApplied = data?.totals?.shipmentsCountInsuredNotApplied;
    const fee = Number(data?.totals?.totalInsuranceFeeNotApplied);
    const value = Number(data?.totals?.totalValueNotInsured);
    const total =
      Number(shipmentsCountInsuredNotApplied) + Number(data?.totals?.shipmentsCountInsured);

    const showInsuranceAddOn = !hideInsuranceAddOn && !!shipmentsCountInsuredNotApplied;

    if (!showInsuranceAddOn) {
      return null;
    }
    return (
      <Card className={`${className} max-w-[600px]`} variant="normal">
        <CardHeader title={<InsuranceCardTitle />} />
        <CardContent className="w-[600px] flex flex-col  border-t border-sky-300">
          <div className="flex flex-col gap-3">
            <div>
              <FormattedMessage
                id="insurance-card.content"
                values={{
                  total,
                  uninsured: shipmentsCountInsuredNotApplied,
                  // eslint-disable-next-line react/no-unstable-nested-components
                  red: (chunks) => <span className="text-red-900">{chunks}</span>,
                }}
              />
            </div>
            <Alert className="w-[560px]">
              <>
                <FormattedMessage id="insurance-card.limited-coverage-disclaimer" />{' '}
                <a
                  href="https://www.easyship.com/legal/insurance-terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Insurance terms and conditions"
                >
                  <FormattedMessage id="global.see-terms-and-conditions" />
                </a>
              </>
            </Alert>
            <div>
              <RadioGroup
                labelPlacement="right"
                onChange={(event) => {
                  const value = event.target?.value;
                  handleInputChange(value === 'true');
                  setIsInsuranceSelected(value);
                }}
                value={isInsuranceSelected === null ? undefined : isInsuranceSelected?.toString()}
                className="flex items-start"
              >
                <Radio
                  className="min-w-8 !ml-[-8px] !outline-none"
                  value="true"
                  error={hasRadioError}
                  label={
                    <div className="flex flex-row items-center justify-start gap-2">
                      <Insured className="ml-2 min-w-8 min-h-8" />
                      <div>
                        {showSimplifiedDomesticFlow ? (
                          <FormattedMessage
                            id="insurance-card.insure-my-good"
                            values={{
                              // eslint-disable-next-line react/no-unstable-nested-components
                              green: (chunks) => (
                                <span className="text-green-700 font-bold">{chunks}</span>
                              ),
                              // eslint-disable-next-line react/no-unstable-nested-components
                              a: (chunks) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${API.help}/hc/en-us/articles/19258361027218-Add-Insurance-to-Shipments`}
                                >
                                  {chunks}
                                </a>
                              ),
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            id="insurance-card.choice-yes-content"
                            values={{
                              // eslint-disable-next-line react/no-unstable-nested-components
                              green: (chunks) => (
                                <span className="text-green-700 font-bold">{chunks}</span>
                              ),
                              fee: (
                                <FormattedFee
                                  fee={fee}
                                  className="inline-block font-bold min-w-0"
                                />
                              ),
                              value: <FormattedFee fee={value} className="inline-block min-w-0" />,
                              // eslint-disable-next-line react/no-unstable-nested-components
                              dark: (chunks) => <span className="font-bold">{chunks}</span>,
                              // eslint-disable-next-line react/no-unstable-nested-components
                              a: (chunks) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${API.help}/hc/en-us/articles/19258361027218-Add-Insurance-to-Shipments`}
                                >
                                  {chunks}
                                </a>
                              ),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
                <ShipmentTotalValueAndInsuranceFee
                  shipmentId={shipmentId || ''}
                  className="ml-8 mb-6"
                  isAllInsured={isInsuranceSelected === 'true'}
                  isValidationError={isInputDomesticValidationError}
                />
                <Radio
                  className="min-w-8 !ml-[-8px] !outline-none"
                  value="false"
                  error={hasRadioError}
                  label={
                    <div className="flex flex-row items-center justify-start gap-2">
                      <NotInsured className="ml-2 min-w-8 min-h-8" />
                      <div>
                        <FormattedMessage
                          id="insurance-card.choice-no-content"
                          values={{
                            // eslint-disable-next-line react/no-unstable-nested-components
                            redstrong: (chunks) => (
                              <span className="text-red-900 font-bold">{chunks}</span>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  }
                />
              </RadioGroup>
            </div>
            <div />
          </div>
        </CardContent>
      </Card>
    );
  }
);

export default InsuranceCard;
