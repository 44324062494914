import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import angular from 'angular';
import { ICompanyService, IPromoCodeResponse } from '@typings/company';
import { GET_UPDATE_STATUS } from '@app/hooks/queries/company/useGetUpdateStatusQuery/types';

export function useGetUpdateStatusQuery(params?: UseQueryOptions<IPromoCodeResponse>) {
  return useQuery({
    queryKey: [GET_UPDATE_STATUS],
    queryFn: async () => {
      const service: ICompanyService = angular
        .element(document.body)
        .injector()
        .get('CompanyService');
      return service.updateStatus();
    },
    ...params,
  });
}
