import React from 'react';
import { useIntl } from 'react-intl';
import { Radio, RadioGroup } from 'easyship-components';
import { Controller } from 'react-hook-form';

interface SelectVerificationMethodFormProps {
  handleInputChange: (option: string | null, key: string) => void;
}

function SelectVerificationMethodForm({ handleInputChange }: SelectVerificationMethodFormProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="inline-flex flex-col">
      <Controller
        name="validationOption"
        rules={{ required: true }}
        defaultValue="email"
        render={({ field }) => (
          <RadioGroup
            {...field}
            className="space-y-2"
            onChange={(e) => {
              field.onChange(e);
              handleInputChange(e.target.value, 'validation_option');
            }}
          >
            <Radio
              label={formatMessage({ id: 'courier.connect.form.fedex.pin-choice.email' })}
              value="email"
              checked={field.value === 'email'}
            />
            <Radio
              label={formatMessage({ id: 'courier.connect.form.fedex.pin-choice.text-message' })}
              value="sms"
              checked={field.value === 'sms'}
            />
          </RadioGroup>
        )}
      />
    </div>
  );
}

export default SelectVerificationMethodForm;
