import ReactRootProviders from '@/providers/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { ShipmentGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentGatewayProvider';
import React, { lazy, Suspense } from 'react';
import { ShipmentsTableProps } from '@/pages/advanced/components/ShipmentTable/types';
import { react2angular } from 'react2angular';

const TableComponent = lazy(() => import('@/pages/advanced/components/ShipmentTable'));

function WrappedAdvanceShipmentsTable(props: ShipmentsTableProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentGatewayProvider>
          <Suspense fallback={null}>
            <TableComponent {...props} />
          </Suspense>
        </ShipmentGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularShipmentsTable = react2angular(
  WrappedAdvanceShipmentsTable,
  [
    'shipments',
    'isShipmentsLoading',
    'totalShipmentsCount',
    'openShipmentIndex',
    'selectedShipmentsCount',
    'onHeaderCheckboxChanged',
    'onAllShipmentsSelected',
    'onAllShipmentsDeselected',
    'onShipmentCheckboxChanged',
    'onOrderCellClick',
    'onReceiverCellClick',
    'onCourierCellClick',
    'onRefreshCellClick',
  ],
  []
);
